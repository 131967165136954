import React, {PropsWithChildren} from 'react';

type SimpleNotificationProps = {
    message?: string;
    status?: 'success' | 'warning' | 'error' | 'hint';
};

const SimpleNotification = ({
    children,
    message,
    status = 'hint',
}: PropsWithChildren<SimpleNotificationProps>) => (
    <div className={`notification-simple notification-simple--${status} flex flex--align-center`}>
        {children || message}
    </div>
);

export default SimpleNotification;
