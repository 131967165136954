import React, {useMemo} from 'react';
import {Button} from '@crossbuilders/form-library/components';
import {Tooltip} from 'react-tippy';
import {Auszahlungsvoraussetzung} from '../../../@types/data/Darlehensdaten';
import useStore from '../../../hooks/useStore';
import {Status} from '../../../services/getStatusLabel';
import {useModal} from '../../Modal/Modal';

import {ReactComponent as IconUpload} from '../../../assets/images/icon-upload.svg';
import ViewDocumentsAction from '../partials/ViewDocumentsAction';

interface TableActionCellProps {
    row: Auszahlungsvoraussetzung;
    status: Status;
}

export default function TableOfRequirementsActionCell({row, status}: TableActionCellProps) {
    const {setModalOpen, setData, setModalType} = useModal();
    const {activeDarlehen} = useStore();

    const allowUpload = useMemo<boolean>(() => {
        return row.uploadErlaubt
            && activeDarlehen.frontendFunktionserlaubnisKundeUpload
            && !['erfüllt', 'verzicht'].includes(status);
    }, [row, activeDarlehen]);

    return (
        <div className="table__cell-with-icons">
            <div className="two-column-grid__item">
                {allowUpload ? (
                    <Tooltip title="Jetzt hochladen" position="top" theme="light">
                        <Button
                            type="button"
                            onClick={() => {
                                setModalOpen(true);
                                setData({...row, preselectAzv: true});
                                setModalType('uploadDocument');
                            }}
                        >
                            <IconUpload width="20" height="20" />
                        </Button>
                    </Tooltip>
                ) : null}
            </div>

            <ViewDocumentsAction row={row} />
        </div>
    );
}
