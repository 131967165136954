import {Auszahlungsvoraussetzung, DarlehenPerson, Dokument, Gewerk} from '../@types/data/Darlehensdaten';

type MapFunctionType = (azv: Auszahlungsvoraussetzung) => Auszahlungsvoraussetzung;
type MapFunctionReturn = DarlehenPerson | {};
type MapFunctionDarlehenInput = DarlehenPerson | {} | null;

const isValidDarlehen = (darlehen: MapFunctionDarlehenInput): darlehen is DarlehenPerson => {
    return darlehen !== null && (darlehen as DarlehenPerson).id !== undefined;
};

export function mapDarlehenVoraussetzungen(darlehen: MapFunctionDarlehenInput) {
    return (mapFunction: MapFunctionType): MapFunctionReturn => {
        if (!isValidDarlehen(darlehen)) return {};

        return {
            ...darlehen,
            auszahlungsvoraussetzungen: darlehen.auszahlungsvoraussetzungen.map(mapFunction) ?? [],
        };
    };
}

export function mapDarlehenDokumente(darlehen: MapFunctionDarlehenInput) {
    return (mapFunction: (azvDokument: Dokument) => Dokument): MapFunctionReturn => {
        return mapDarlehenVoraussetzungen(darlehen)((azv: Auszahlungsvoraussetzung) => ({
            ...azv,
            dokumente: azv.dokumente.map(mapFunction),
        }));
    };
}

export function updateStatusInDarlehen(
    darlehen: MapFunctionDarlehenInput,
    azvId: string,
    status: string,
): MapFunctionReturn {
    return mapDarlehenVoraussetzungen(darlehen)(
        (azv: Auszahlungsvoraussetzung) => (azv.id === azvId) ? {...azv, status} : azv,
    );
}

export function addOrUpdateDocumentInDarlehen(
    darlehen: MapFunctionDarlehenInput,
    azvId: string,
    dokument: Dokument,
): MapFunctionReturn {
    return mapDarlehenVoraussetzungen(darlehen)((azv: Auszahlungsvoraussetzung) => {
        if (azv.id !== azvId) return azv;

        let dokumente: Array<Dokument> = azv.dokumente;
        const replaceIndex: number = dokumente.findIndex(
            (azvDokument: Dokument) => azvDokument.id === dokument.id,
        );

        if (replaceIndex !== -1) {
            dokumente[replaceIndex] = dokument;
        } else {
            dokumente = [...dokumente, dokument];
        }

        return {...azv, dokumente};
    });
}

export function replaceDocumentInDarlehen(
    darlehen: MapFunctionDarlehenInput,
    dokument: Dokument,
): MapFunctionReturn {
    return mapDarlehenDokumente(darlehen)(
        (azvDokument: Dokument) => (azvDokument.id === dokument.id) ? dokument : azvDokument,
    );
}

export function replaceMultipleDocumentsInDarlehen(
    darlehen: MapFunctionDarlehenInput,
    dokuments: Array<Dokument>,
): MapFunctionReturn {
    return mapDarlehenDokumente(darlehen)((azvDokument: Dokument) => {
        const foundDokument = dokuments.find(({id}: Dokument) => id === azvDokument.id);
        return foundDokument ?? azvDokument;
    });
}

export function removeStillLoadingDocuments(
    darlehen: MapFunctionDarlehenInput,
): MapFunctionReturn {
    return mapDarlehenDokumente(darlehen)((azvDokument: Dokument): Dokument => {
        if (azvDokument.status === 'ocr_start') {
            return {...azvDokument, status: 'ocr_failed'};
        }

        return azvDokument;
    });
}

export function updateBaufortschritte(
    darlehen: MapFunctionDarlehenInput,
    gewerke: Array<Gewerk>,
): MapFunctionReturn {
    if (!isValidDarlehen(darlehen)) return {};

    return {
        ...darlehen,
        baufortschritte: darlehen.baufortschritte?.map((item) => ({
            ...item,
            gewerke,
        })) ?? [],
    };
}
