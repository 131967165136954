import React, {useCallback} from 'react';
import {Button} from '@crossbuilders/form-library/components';
import {useNavigate} from 'react-router-dom';

import '../../assets/scss/6_components/general/_key-value-box.scss';

import LoanDiagram from './LoanDiagram';
import ROUTES from '../../enum/Routes';
import {DarlehenPerson} from '../../@types/data/Darlehensdaten';
import {Logger} from '../../services/Logger';
import {useModal} from '../Modal/Modal';

const {REACT_APP_DEMO_MODE} = process.env;
const demoMode = REACT_APP_DEMO_MODE === 'true';

export interface DashboardHeaderProps {
    activeDarlehen: DarlehenPerson;
    disablePayout?: boolean;
}

const DashboardHeader = ({activeDarlehen, disablePayout = false}: DashboardHeaderProps) => {
    const {setModalOpen, setModalType} = useModal();
    const navigate = useNavigate();

    const {
        darlehensbetrag,
        auszahlungsbetrag,
        maximalerAuszahlungsbetrag,
        frontendFunktionserlaubnisKundeZahlungsauftrag,
        frontendFunktionserlaubnisKundeUpload,
        darlehensnehmer,
    } = activeDarlehen;

    const handleClickZahlungsauftrag = useCallback(() => {
        Logger.debug('[DashboardHeader] > handleClickZahlungsauftrag -> darlehensnehmer', darlehensnehmer);

        if (demoMode || (darlehensnehmer && darlehensnehmer[0].frontendLegitimationStatus === 'erfolgreich')) {
            navigate(`${ROUTES.DASHBOARD}/${activeDarlehen.id}/${ROUTES.PAYOUT_ORDER}`, {
                state: {preventRedirect: true},
            });
            return;
        }

        setModalType('hintUserLegitimation');
        setModalOpen(true);
    }, [navigate, darlehensnehmer]);

    return (
        <div className="two-column-grid">
            <LoanDiagram
                darlehensbetrag={darlehensbetrag || 0}
                auszahlungsbetrag={auszahlungsbetrag || 0}
                maximalerAuszahlungsbetrag={maximalerAuszahlungsbetrag || 0}
            />

            <div className="button-group button-group--column button-group--align-end button-group--spacing-y">
                {frontendFunktionserlaubnisKundeZahlungsauftrag ? (
                    <Button
                        onClick={() => handleClickZahlungsauftrag()}
                        type="button"
                        className="button button--primary"
                        disabled={disablePayout}
                    >
                        Auszahlung beauftragen
                    </Button>
                ) : null}

                {frontendFunktionserlaubnisKundeUpload ? (
                    <Button
                        onClick={() => {
                            setModalType('uploadDocument');
                            setModalOpen(true);
                        }}
                        type="button"
                        className="button button--ghost"
                    >
                        Dokumente hochladen
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default DashboardHeader;
