import React, {useEffect, useState, useMemo} from 'react';
import useBus, {dispatch} from 'use-bus';

import {UploadFileResponse, UploadFileResult} from '../../../@types/services/apiService';
import useFileUpload from '../../../hooks/useFileUpload';
import {addOrUpdateDocumentInDarlehen, updateStatusInDarlehen} from '../../../services/dokumentHelpers';
import FileUploadForm from '../../Forms/FileUploadForm';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import useStore from '../../../hooks/useStore';
import {Logger} from '../../../services/Logger';
import useApiService from '../../../hooks/useApiService';
import Events from '../../../enum/Events';
import {useModal} from '../Modal';
import {Auszahlungsvoraussetzung, Dokument, FileQueue} from '../../../@types/data/Darlehensdaten';
import LoadingContainer from '../../../containers/LoadingContainer';

const MAX_FILE_SIZE_IN_BYTES = 7e+6;

export default function UploadDocument() {
    const {data, setModalOpen} = useModal();
    const {activeDarlehen, activeDarlehenId, updateActiveDarlehen} = useStore();
    const {uploadFiles: apiUploadFiles} = useApiService();

    const [error, setError] = useState<string>('');
    const [fileQueue, setFileQueue] = useState<FileQueue>({});
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {frontendFunktionserlaubnisKundeUpload, auszahlungsvoraussetzungen} = activeDarlehen;

    const uploadFiles = (files: Array<File | Dokument>, darlehenId: string, categoryId: string): UploadFileResponse => {
        setError('');
        setIsLoading(true);

        return new Promise((resolve) => {
            apiUploadFiles(files, darlehenId, categoryId)
                .then((response: UploadFileResult) => resolve(response))
                .catch((errorMessage) => setError(errorMessage))
                .finally(() => setIsLoading(false));
        });
    };

    const onUploadSuccess = async (dokument: Dokument, azv?: Auszahlungsvoraussetzung) => {
        const rowId = data?.id ?? selectedCategoryId;

        Logger.info('[UploadDocument] dokument', dokument);
        Logger.info('[UploadDocument] selected', rowId);

        if (dokument.ocrPolling) {
            dispatch({
                type: Events.INIT_OCR_POLLING_BILLS,
                dokumentId: dokument.id.toString(),
            });
        }

        if (azv) {
            updateActiveDarlehen(addOrUpdateDocumentInDarlehen(
                updateStatusInDarlehen(activeDarlehen, azv.id, azv.status),
                azv.id,
                dokument,
            ));
        }
    };

    const {submitFiles} = useFileUpload({uploadFiles, onUploadSuccess});

    useEffect(() => {
        if (data?.preselectAzv && data.id) {
            setSelectedCategoryId(data.id);
        }
    }, [data]);

    useEffect(() => {
        Logger.debug('[UploadDocument] auszahlungsvoraussetzungen', auszahlungsvoraussetzungen);
    }, [auszahlungsvoraussetzungen]);

    useEffect(() => {
        Logger.debug('[UploadDocument] fileQueue', fileQueue);
    }, [fileQueue]);

    useEffect(() => {
        Logger.debug('[UploadDocument] selectedValue', selectedCategoryId, typeof selectedCategoryId);
    }, [selectedCategoryId]);

    useBus(
        Events.SUBMIT_FILE_UPLOAD,
        () => submitFiles(activeDarlehenId, selectedCategoryId, fileQueue),
        [activeDarlehenId, selectedCategoryId, fileQueue],
    );

    useBus(
        Events.UPLOAD_FINISHED,
        () => setModalOpen(false),
        [setModalOpen],
    );

    const maxFileSizeReached = useMemo(() => {
        const fileSize: number = Object.values(fileQueue)
            .reduce((combinedSize: number, file: File | Dokument) => {
                return file instanceof File ? combinedSize + file.size : combinedSize;
            }, 0);

        return fileSize > MAX_FILE_SIZE_IN_BYTES;
    }, [fileQueue]);

    const uploadDisabled: boolean = isLoading
        || selectedCategoryId === ''
        || !Object.values(fileQueue).length
        || maxFileSizeReached;

    const AzvOption = ({azv}: {azv: Auszahlungsvoraussetzung}) => {
        if (!azv.uploadErlaubt || ['erledigt', 'nichtErforderlich'].includes(azv.status)) return null;

        const disabled: boolean = !!data?.preselectAzv && data.id.toString() !== azv.id.toString();

        return <option value={azv.id} disabled={disabled}>{azv.anzeigetext}</option>;
    };

    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <h2 className="headline headline--level-2">Dokumente hochladen</h2>

                {!isLoading ? (
                    <>
                        <select
                            className="form-element form-element--dropdown"
                            onChange={({target: {value}}) => setSelectedCategoryId(value)}
                            value={selectedCategoryId}
                        >
                            <option value="" disabled={!!data?.preselectAzv}>
                                Bitte auswählen
                            </option>

                            {frontendFunktionserlaubnisKundeUpload && auszahlungsvoraussetzungen.map(
                                (azv: Auszahlungsvoraussetzung) => <AzvOption key={azv.id} azv={azv} />,
                            )}
                        </select>

                        <p className="text">
                            Sie können mehrere Seiten zu einem Dokument hochladen. Falls Sie mehrere Seiten eines
                            Dokuments
                            hochladen, bringen Sie diese bitte per Drag&Drop in die korrekte Reihenfolge.
                        </p>

                        <FileUploadForm exposeFileQueue={(files: FileQueue) => setFileQueue(files)} error={error} />
                    </>
                ) : (
                    <LoadingContainer
                        isLoading
                        className="loading-container--standalone"
                        contentClassName="loading-container__content--text-only"
                    >
                        Dokumente werden hochgeladen...
                    </LoadingContainer>
                )}
            </div>

            <ModalFooter buttons={[{
                type: 'upload',
                disabled: uploadDisabled,
                onClick: () => dispatch(Events.SUBMIT_FILE_UPLOAD),
            }]} />
        </>
    );
}
