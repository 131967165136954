import React from 'react';

import useApiService from '../../../hooks/useApiService';
import ModalFooter from '../ModalFooter';
import {ReactComponent as IconInfo} from '../../../assets/images/icon-info.svg';
import useStore from '../../../hooks/useStore';

export default function HintUserLegitimation() {
    const {activeDarlehenId} = useStore();
    const {requestNectLegitimation} = useApiService();

    const handleUserRedirect = () => {
        requestNectLegitimation(activeDarlehenId)
            .then(({URL}) => {
                window.location.href = URL;
            });
    };

    return (
        <>
            <div className="modal__content">
                <IconInfo className="modal__headline-icon modal__headline-icon--centered" />

                <p className="text text--m text--centered">
                    Zu Ihrer eigenen Sicherheit müssen wir vor der ersten
                    Auszahlung einmalig Ihre Identität prüfen
                </p>
            </div>

            <ModalFooter buttons={[{type: 'abort'}, {type: 'forward', onClick: handleUserRedirect}]} spread />
        </>
    );
}
