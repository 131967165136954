import React from 'react';
import {useFormContext} from 'react-hook-form';
import {Input, NumberInput, Dropdown} from '@crossbuilders/form-library/components';
import {transformToNumberFormat, tryConvertFloat} from '../../../services/numberConverterService';

interface EditableTableCellProps {
    name: string;
    value: any;
    isEditingMode: boolean;
    onChange: (value: string | number) => void;
    choices?: {};
    type?: string;
    maxLength?: string;
    disabled?: boolean;
}

export default function EditableTableCell(props: EditableTableCellProps) {
    const {name, value, isEditingMode, onChange, type, choices, maxLength, disabled} = props;

    const defaultValue = type === 'number' ? 0 : '';

    const formContext = useFormContext();
    const {control, setValue, getValues} = formContext;

    if (type === 'dropdown') {
        return (
            <Dropdown
                id={name}
                name={name}
                control={control}
                rowClass="table__cell-dropdown"
                choices={choices ?? {}}
                disabled={disabled || !isEditingMode}
                onChange={() => {
                    onChange(getValues(name) ?? defaultValue);
                }}
            />
        );
    }

    if (isEditingMode) {
        if (type === 'number') {
            return (
                <NumberInput
                    rowClass=""
                    unitLabel="€"
                    id={name}
                    name={name}
                    control={control}
                    disabled={disabled}
                    decimalScale={2}
                    fixedDecimalScale
                    onBlur={(event: Event) => {
                        const target: HTMLInputElement = event.target as unknown as HTMLInputElement;
                        if (target.value === '') {
                            setValue(name, 0);
                        }

                        if (tryConvertFloat(target.value) > 999999) {
                            setValue(name, 999999);
                        }

                        onChange(getValues(name) ?? defaultValue);
                    }}
                />
            );
        }

        return <Input
            rowClass=""
            type="text"
            id={name}
            name={name}
            control={control}
            maxLength={maxLength}
            disabled={disabled}
            onBlur={() => {
                onChange(getValues(name) ?? defaultValue);
            }}
        />;
    }

    if (type === 'number') {
        return <span>{`${transformToNumberFormat({number: value ?? 0, digits: 2})} €`}</span>;
    }

    return <span>{value}</span>;
}
