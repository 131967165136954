import React, {MutableRefObject, useMemo, useRef} from 'react';
import {Tooltip} from 'react-tippy';
import {Dokument} from '../../../@types/data/Darlehensdaten';
import {ReactComponent as IconSee} from '../../../assets/images/eye-light.svg';
import useStore from '../../../hooks/useStore';
import useDocumentDownload from '../../../services/downloadDokument';
import {useModal} from '../../Modal/Modal';

interface ViewDocumentsActionProps {
    row: {
        id: string;
        dokumente: Array<Dokument>;
    };
}

export default function ViewDocumentsAction(props: ViewDocumentsActionProps) {
    const {row} = props;

    const {setModalOpen, setData, setModalType} = useModal();
    const {activeDarlehenId} = useStore();

    const rowDocuments = useMemo<Array<Dokument>>(() => {
        return row.dokumente.filter((dokument) => dokument.id !== null);
    }, [row]);

    const {downloadDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    return (
        <div className="two-column-grid__item">
            {rowDocuments.length > 0 ? (
                <Tooltip
                    title={rowDocuments.length > 1 ? 'Dokumente anzeigen' : 'Dokument anzeigen'}
                    position="top"
                    theme="light"
                >
                    <a
                        href={`#${row.id}`}
                        ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                        onClick={(event) => {
                            event.preventDefault();

                            if (rowDocuments.length > 1) {
                                setData(row);
                                setModalType('viewDocumentsList');
                                setModalOpen(true);
                            } else if (downloadButton.current) {
                                downloadDokument(downloadButton.current, activeDarlehenId, rowDocuments[0].id.toString());
                            }
                        }}
                    >
                        <IconSee width="20" height="20" />
                    </a>
                </Tooltip>
            ) : null}
        </div>
    );
}
