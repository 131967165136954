import useApiService from '../hooks/useApiService';

export default function useDocumentDownload() {
    const {getDokument, getZahlungsDokument} = useApiService();

    const downloadFileBlob = (fileBlob: Blob, newWindow: Window | null): void => {
        const blobUrl = window.URL.createObjectURL(fileBlob);

        if (newWindow) {
            newWindow.location = blobUrl;
            newWindow.focus();
        }
    };

    return {
        downloadDokument(currentTarget: HTMLAnchorElement, darlehenId: string, dokumentId: string) {
            const newWindow = window.open(undefined, '_blank');
            getDokument(darlehenId, dokumentId)
                .then((fileBlob: Blob) => downloadFileBlob(fileBlob, newWindow));
        },
        downloadZahlungsDokument(currentTarget: HTMLAnchorElement, darlehenId: string, zahlungId: string) {
            const newWindow = window.open(undefined, '_blank');
            getZahlungsDokument(darlehenId, zahlungId)
                .then((fileBlob: Blob) => downloadFileBlob(fileBlob, newWindow));
        },
    };
}
