interface StorageObject {
    PREV_ROUTE: string;
    XB_LOGIN: string;
    CSRF: string;
    EXPIRES_IN: string;
}

const STORAGE: StorageObject = Object.freeze({
    PREV_ROUTE: 'dik-service-prevRoute',
    XB_LOGIN: 'XB_LOGIN',
    CSRF: '_csrf-token',
    EXPIRES_IN: '_expires-in',
});

export default STORAGE;
