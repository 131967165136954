import React from 'react';
import toast, {Toaster as HotToaster, ToastOptions} from 'react-hot-toast';
import HtmlParser from "react-html-parser";

import "../assets/scss/6_components/layout/_toast-alert.scss";

import {ReactComponent as IconClose} from "../assets/images/icon-close-fa-light-white.svg";

export function getToastMarkup(content: string) {
    return (toastObject: any): JSX.Element => ((
        <span className="toast-alert__content">
            <span>{HtmlParser(content)}</span>

            <button type="button" className="toast-alert__close" onClick={() => toast.dismiss(toastObject.id)}>
                <IconClose width={20} height={20} title="Schließen" />
            </button>
        </span>
    ));
}

interface ToasterProps {
    duration?: number,
}

export const Toaster: React.FC<ToasterProps> = (props) => {
    const {duration} = props;
    return (
        <HotToaster
            position="bottom-center"
            reverseOrder
            toastOptions={{
                className: 'toast-alert',
                duration: duration ?? 4000,
                success: {
                    style: {
                        background: '#6dcc7a',
                    },
                },
                error: {
                    style: {
                        background: '#ff003e',
                        color: '#fff',
                    },
                },
            }}
        />
    );
}

export function dismissAllToasts() {
    toast.dismiss();
}

export function postError(errorMessage: string, options?: ToastOptions) {
    toast.error(getToastMarkup(errorMessage), options || undefined);
}

export function postSuccess(successMessage: string, options?: ToastOptions) {
    toast.success(getToastMarkup(successMessage), options || undefined);
}
