import React from 'react';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';

const SuccessfulUpload = () => {
    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <h2 className="headline headline--level-2">Die Dokumente wurden erfolgreich hochgeladen</h2>
                <p className="text text--s ">Die Dokumente wurden erfolgreich hochgeladen</p>
            </div>

            <ModalFooter buttons={[{type: 'finish'}]} />
        </>
    );
};

export default SuccessfulUpload;
