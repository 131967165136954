import React, {useEffect} from 'react';
import {dispatch} from 'use-bus';
import {Outlet, useParams} from 'react-router-dom';

import useStore from '../hooks/useStore';
import Events from '../enum/Events';
import {Logger} from '../services/Logger';
import LoadingContainer from './LoadingContainer';

export default function DarlehenContainer() {
    const {darlehenId} = useParams();
    const {activeDarlehenId, activeDarlehen} = useStore();

    useEffect(() => {
        Logger.debug('[DarlehenContainer] activeDarlehenId', activeDarlehenId);
    }, [darlehenId]);

    useEffect(() => {
        if (!activeDarlehenId && darlehenId?.length) {
            dispatch({type: Events.PREPARE_DARLEHENSDATEN, value: {darlehenId}});
        }
    }, []);

    return !activeDarlehenId || !activeDarlehen ? (
        <LoadingContainer
            isLoading
            className="loading-container--standalone"
            contentClassName="loading-container__content--text-only"
        >
            Darlehensdaten werden geladen...
        </LoadingContainer>
    ) : (
        <Outlet />
    );
}
