import React from 'react';
import {Link, LinkProps} from 'react-router-dom';

interface InternalLinkProps extends LinkProps {
    onClick?: (event: any) => void;
}

const InternalLink: React.FC<InternalLinkProps> = (props) => {
    const {to, children, className, onClick} = props;

    return (
        <Link
            to={to}
            state={{preventRedirect: true}}
            className={className}
            onClick={(event: any) => onClick?.(event)}
        >
            {children}
        </Link>
    );
};

export default InternalLink;
