import {useMemo} from 'react';
import {darlehenIsLegitimated} from '../services/legitimateDarlehen';
import {useStore as useStoreContext} from '../store';
import {Store} from '../@types/store';
import {DarlehenPerson, DarlehensdatenPerson} from '../@types/data/Darlehensdaten';
import {Tab} from '../@types/dashboard';

export default function useStore(): Store {
    const {state, actions} = useStoreContext();

    const {
        tabs,
        currentTabIndex,
        darlehensdaten,
        activeDarlehenId,
        darlehensnehmer,
    } = state;

    const activeDarlehen: DarlehenPerson | null = useMemo(() => {
        if (activeDarlehenId && darlehensdaten.darlehen?.length) {
            return darlehensdaten.darlehen.find(
                (darlehen: any) => darlehen.id === activeDarlehenId
            );
        }

        return null;
    }, [activeDarlehenId, darlehensdaten]);

    const activeDarlehensnehmer: DarlehensdatenPerson | null = useMemo(() => {
        return activeDarlehen?.darlehensnehmer?.[0] ?? null;
    }, [activeDarlehen]);

    const currentTab: Tab = useMemo(() => {
        if (!tabs) return null;

        return tabs[currentTabIndex];
    }, [tabs, currentTabIndex]);

    const isLegitimated = useMemo<boolean>(() => darlehenIsLegitimated(activeDarlehen), [activeDarlehen]);
    const isAgbAccepted = useMemo<boolean>(() => darlehensnehmer?.agbAccepted ?? false, [darlehensnehmer]);

    return {
        ...state,
        ...actions,
        activeDarlehen,
        currentTab,
        isLegitimated,
        isAgbAccepted,
        activeDarlehensnehmer,
    };
}
