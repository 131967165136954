import React, {useMemo, useEffect} from 'react';
import classNames from 'classnames';
import {FileDropUpload} from '@crossbuilders/form-library/components';

import '../../assets/scss/6_components/forms/_file-drop-upload.scss';

import {ReactComponent as IconUpload} from '../../assets/images/icon-upload.svg';

import uniqueId from '../../services/uniqueIdService';
import {
    DROPZONE_MIME_TYPES,
    FILE_UPLOAD_SIZE_HINT,
    fileSizeForHumans,
    MAX_UPLOAD_SIZE,
} from '../../services/validatorService';
import {Dokument, FileQueue, PureFileQueue} from '../../@types/data/Darlehensdaten';
import useIsMounted from '../../hooks/useIsMounted';
import useFileQueue from '../../hooks/useFileQueue';
import DraggableFileList from '../DraggableFileList/DraggableFileList';
import {postError} from '../../services/toastService';

interface FileUploadProps {
    existingFiles?: Array<File | Dokument>;
    exposeFileQueue?: (fileQueue: FileQueue) => void;
    maxFileCount?: number;
    maxFileBytes?: number;
    multiple?: boolean;
    error?: string;
}

const FileUploadForm = (props: FileUploadProps) => {
    const {
        existingFiles,
        exposeFileQueue,
        maxFileCount,
        maxFileBytes = MAX_UPLOAD_SIZE,
        multiple = true,
        error,
    } = props;

    const isMounted = useIsMounted();

    const {fileQueue, setFileQueue, handleOnDrop, hasFileSizeError} = useFileQueue();

    useEffect(() => {
        if (existingFiles) {
            setFileQueue(existingFiles.reduce(
                (existingFiles: PureFileQueue, file: File | Dokument): PureFileQueue => {
                    return {...existingFiles, [uniqueId()]: file as File};
                },
                {},
            ));
        }
    }, [existingFiles]);

    const totalFileBytes: number = useMemo<number>(() => {
        let totalFileBytes: number = 0;

        Object.values(fileQueue).forEach((file: File) => {
            totalFileBytes += file.size;
        });

        return totalFileBytes;
    }, [fileQueue]);

    const availableFileSize: number = useMemo(() => maxFileBytes - totalFileBytes, [maxFileBytes, totalFileBytes]);
    const formattedTotalFileSize: string = useMemo(() => fileSizeForHumans(totalFileBytes), [totalFileBytes]);
    const formattedMaxFileSize: string = useMemo(() => fileSizeForHumans(maxFileBytes), [maxFileBytes]);

    const checkFileSizeAndHandleOnDrop = (files: Array<File>): void => {
        let stillAvailableFileSize: number = availableFileSize;

        const allowedFiles: Array<File> = [];
        const forbiddenFiles: Array<File> = [];

        files.forEach((file: File) => {
            if (file.size <= availableFileSize) {
                allowedFiles.push(file);
                stillAvailableFileSize -= file.size;
            } else {
                forbiddenFiles.push(file);
            }
        });

        const formattedStillAvailableFileSize: string = fileSizeForHumans(stillAvailableFileSize);

        if (forbiddenFiles.length === 1) {
            postError(
                `Die Datei ${files[0].name} ist zu groß (${fileSizeForHumans(files[0].size)}). 
                Sie können maximal ${formattedStillAvailableFileSize} hochladen.`,
            );
        } else if (forbiddenFiles.length > 1 && allowedFiles.length === 0) {
            postError(
                `Die Dateien sind zu groß. 
                Sie können maximal ${formattedStillAvailableFileSize} hochladen.`,
            );
        } else if (forbiddenFiles.length > 1 && allowedFiles.length > 0) {
            postError(
                `Einige Dateien sind zu groß. 
                Sie können maximal ${formattedStillAvailableFileSize} hochladen.`,
            );
        }

        if (maxFileCount && Object.values(fileQueue).length + files.length > maxFileCount) {
            postError(`Es können maximal ${maxFileCount} Datei(en) ausgewählt werden.`);
            return;
        }

        handleOnDrop(allowedFiles);
    };

    const disableFileInput: boolean = useMemo<boolean>(() => {
        if (maxFileCount) {
            return Object.keys(fileQueue).length === maxFileCount;
        }

        if (maxFileBytes) {
            return totalFileBytes >= maxFileBytes;
        }

        return false;
    }, [fileQueue, maxFileCount, maxFileBytes]);

    useEffect(() => {
        if (isMounted()) {
            exposeFileQueue?.(fileQueue);
        }
    }, [isMounted, fileQueue]);

    return (
        <div
            className={classNames({
                'document-upload-form': true,
                'document-upload-form--disabled': disableFileInput,
            })}
        >
            <FileDropUpload
                onDrop={checkFileSizeAndHandleOnDrop}
                name="dropUpload"
                multiple={multiple}
                disabled={disableFileInput}
                accept={DROPZONE_MIME_TYPES}
                noButton
            >
                <IconUpload width={20} height={20} />

                <span className="file-drop-upload__label">Dokument hierher ziehen oder</span>

                <div className="file-drop-upload__button button button--primary-invers button--no-margin">
                    Datei auswählen
                </div>

                <div className="file-drop-upload__reject-warning">
                    <span>Die von Ihnen ausgewählte Datei kann nicht hochgeladen werden. Bitte laden Sie eine PDF, PNG oder JPEG-Datei hoch.</span>
                </div>
            </FileDropUpload>

            {error ? (
                <div className="flex flex--col document-upload-form__hint form-hint form-hint--error">
                    <span>{error}</span>
                </div>
            ) : null}

            {multiple ? (
                <>
                    <div
                        className={`flex flex--col document-upload-form__hint form-hint ${
                            hasFileSizeError ? 'form-hint--error' : ''
                        }`}
                    >
                        <b>Hinweis:</b>
                        <span>{FILE_UPLOAD_SIZE_HINT}</span>
                        <span>{formattedTotalFileSize} von {formattedMaxFileSize} ausgewählt</span>
                    </div>

                    {Object.keys(fileQueue).length > 0 ? (
                        <div className="document-upload-form__queue">
                            <DraggableFileList fileQueue={fileQueue} setFileQueue={setFileQueue} />
                        </div>
                    ) : null}
                </>
            ) : null}
        </div>
    );
};

export default FileUploadForm;
