import React, {useMemo} from 'react';
import {Button} from '@crossbuilders/form-library/components';
import {Tooltip} from 'react-tippy';

import {ReactComponent as IconUpload} from '../../../assets/images/icon-upload.svg';

import {Gewerk} from '../../../@types/data/Darlehensdaten';
import {GewerkeStatusKey} from '../../../enum/Gewerke';
import {useModal} from '../../Modal/Modal';
import ViewDocumentsAction from '../partials/ViewDocumentsAction';

interface ActionCellProps {
    row: Gewerk;
    isEditingMode: boolean;
}

export default function ActionCell({row, isEditingMode}: ActionCellProps) {
    const {setModalOpen, setData, setModalType} = useModal();

    const allowUpload = useMemo<boolean>(() => {
        return isEditingMode && row.baustatus !== GewerkeStatusKey.OFFEN;
    }, [isEditingMode, row.baustatus]);

    return (
        <div className="table__cell-with-icons two-column-grid two-column-grid--small-gap">
            <div className="two-column-grid__item">
                {allowUpload ? (
                    <Tooltip title="Jetzt hochladen" position="top" theme="light">
                        <Button
                            type="button"
                            onClick={() => {
                                setData(row);
                                setModalType('uploadProofOfUse');
                                setModalOpen(true);
                            }}
                        >
                            <IconUpload width="20" height="20" />
                        </Button>
                    </Tooltip>
                ) : null}
            </div>

            <ViewDocumentsAction row={row} />
        </div>
    );
}
