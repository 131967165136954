import React, {ReactElement, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {format, parseISO} from 'date-fns';

import '../../assets/scss/6_components/notifications/_notification-item.scss';

import {Benachrichtigung} from '../../@types/data/Darlehensdaten';
import useStore from '../../hooks/useStore';
import LoadingWheel from '../Layout/LoadingWheel';
import ROUTES from '../../enum/Routes';
import InternalLink from '../Routing/InternalLink';
import uniqueId from '../../services/uniqueIdService';

interface NotificationProps {
    benachrichtigung: Benachrichtigung;
    isLoading: boolean;
    onClick: () => void;
}

const Notification = (props: NotificationProps): ReactElement => {
    const {benachrichtigung, isLoading, onClick} = props;
    const {activeDarlehen} = useStore();
    const {darlehenId} = useParams();

    const linkType = useMemo(() => {
        if (benachrichtigung.azvId?.toString().length) {
            return 'azvId';
        } else if (benachrichtigung.zahlungId?.toString().length) {
            return 'zahlungId';
        }

        return null;
    }, [benachrichtigung]);

    const linkHref = useMemo<string>(() => {
        switch (linkType) {
            case 'azvId':
                return `${ROUTES.DASHBOARD}/${darlehenId}/?tab=azv`;
            case 'zahlungId':
                return `${ROUTES.DASHBOARD}/${darlehenId}/?tab=auszahlungen`;
            default:
                return `${ROUTES.DASHBOARD}/${darlehenId}`;
        }
    }, [linkType, darlehenId]);

    const linkTitle = useMemo<string | null>(() => {
        switch (linkType) {
            case 'azvId':
                return 'Zur Auszahlungsvoraussetzung';
            case 'zahlungId':
                return 'Zur Auszahlung';
            default:
                return null;
        }
    }, [linkType]);

    const aenderungenList = useMemo<Array<string>>(
        () => benachrichtigung.aenderungen.filter((aenderung: string) => aenderung.length),
        [benachrichtigung],
    );

    const azvName = useMemo<string>(() => {
        if (benachrichtigung.azvId) {
            const azv = activeDarlehen.auszahlungsvoraussetzungen.find(({id, anzeigetext}) => {
                return id.toString() === benachrichtigung.azvId.toString() && anzeigetext?.length;
            });

            if (azv) {
                return ` | ${azv.anzeigetext}`;
            }
        }

        return '';
    }, [benachrichtigung]);

    return (
        <div
            className={`notification-item notification-item--${benachrichtigung.status}${
                isLoading ? ' notification-item--is-loading' : ''
            }`}
            onClick={onClick}
        >
            <LoadingWheel isLoading={isLoading} />

            <div className="notification-item__header">
                <div className="notification-item__title">{benachrichtigung.titel}</div>

                <div className="notification-item__date">
                    {format(parseISO(benachrichtigung.datum), 'dd.MM.y')}
                </div>
            </div>

            <div className="notification-item__content">
                <div className="notification-item__text">
                    {benachrichtigung.text}{azvName}

                    {aenderungenList.length ? (
                        <ul className="notification-item__changes-list">
                            {aenderungenList.map((aenderung) => (
                                <li key={uniqueId()} className="notification-item__changes-list-item">
                                    {aenderung}
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </div>

                {linkType ? (
                    <InternalLink className="notification-item__button link" to={linkHref}>
                        {linkTitle}
                    </InternalLink>
                ) : null}
            </div>
        </div>
    );
};

export default Notification;
