import {useCallback} from 'react';
import {Gewerk} from '../../../@types/data/Darlehensdaten';

interface UseOriginalData {
    originalData: Array<Gewerk>;
}

export default function useOriginalData({originalData}: UseOriginalData) {
    const findOriginalRow = useCallback(
        (rowId: string): Gewerk | null => originalData.find(({id}: Gewerk) => id === rowId) ?? null,
        [originalData],
    );

    return {findOriginalRow};
}
