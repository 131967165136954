import React from 'react';
import {ReactComponent as Logo} from '../../assets/images/WW_logo-wuestenrot-2022.svg';
import {Button} from '@crossbuilders/form-library/components';
import {ReactComponent as IconClose} from '../../assets/images/xmark-large-light.svg';
import {useModal} from './Modal';

interface ModalHeaderProps {
    withLogo?: boolean;
}

export default function ModalHeader(props: ModalHeaderProps) {
    const {withLogo} = props;
    const {setModalOpen, setData, isCloseable} = useModal();

    return (
        <div className="modal__header">
            {withLogo ? (
                <div className="modal__header-logo">
                    <Logo />
                </div>
            ) : null}

            {isCloseable ? (
                <Button
                    onClick={() => {
                        setModalOpen(false);
                        setData({});
                    }}
                    type="button"
                    className="modal__close"
                >
                    <IconClose />
                </Button>
            ) : null}
        </div>
    );
}
