import React, {MutableRefObject, useRef} from 'react';
import {Tooltip} from 'react-tippy';

import {Zahlung} from '../../../@types/data/Darlehensdaten';
import {ReactComponent as IconSee} from '../../../assets/images/eye-light.svg';
import useStore from '../../../hooks/useStore';
import useDocumentDownload from '../../../services/downloadDokument';

interface TableOfPayoutsActionCellProps {
    row: Zahlung;
}

export default function ActionCell({row}: TableOfPayoutsActionCellProps) {
    const {activeDarlehenId} = useStore();

    const {downloadZahlungsDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    return (
        <div className="table__cell-with-icons">
            <Tooltip title="Auszahlung anzeigen" position="top-end" theme="light">
                <a
                    href={`#${row.id}`}
                    ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                    onClick={(event) => {
                        event.preventDefault();

                        if (downloadButton.current) {
                            downloadZahlungsDokument(
                                downloadButton.current,
                                activeDarlehenId,
                                row.id,
                            );
                        }
                    }}
                >
                    <IconSee width="20" height="20" />
                </a>
            </Tooltip>
        </div>
    );
}
