import React, {useMemo, useState, useCallback} from 'react';

import useStore from '../../hooks/useStore';
import Notification from './Notification';
import {Benachrichtigung} from '../../@types/data/Darlehensdaten';

import '../../assets/scss/6_components/notifications/_notification-list.scss';
import classNames from 'classnames';

interface NotificationListProps {
    setStatusRead: (ids: Array<string>) => void;
    isRequesting: boolean;
}

const NotificationList = (props: NotificationListProps) => {
    const {setStatusRead, isRequesting} = props;
    const {benachrichtigungen} = useStore();

    const benachrichtigungenAscByDate = useMemo(() => {
        return benachrichtigungen.sort((a: Benachrichtigung, b: Benachrichtigung) => {
            const timeA: number = new Date(a.datum).getTime();
            const timeB: number = new Date(b.datum).getTime();

            return timeB - timeA;
        });
    }, [benachrichtigungen]);

    const [selection, setSelection] = useState<Array<string>>([]);

    const allIDs = useMemo(
        () => benachrichtigungen.map((benachrichtigung) => benachrichtigung.id),
        [benachrichtigungen],
    );

    const allLinesAreSelected = useMemo(
        () => selection.sort().join('') === allIDs.sort().join(''),
        [selection, allIDs],
    );

    const selectLineItem = (id: string): void => {
        let newSelection: Array<string> = selection;

        if (selection.includes(id)) {
            newSelection = newSelection.filter((value: string) => value !== id);
        } else {
            newSelection = [...newSelection, id];
        }

        setSelection(newSelection);
    };

    const isIndeterminateState = useMemo(
        () => selection.length >= 1 && selection.length < benachrichtigungen.length,
        [selection, benachrichtigungen],
    );

    const selectAll = (): void =>
        setSelection(benachrichtigungen.map((benachrichtigung) => benachrichtigung.id));
    const unselectAll = (): void => setSelection([]);

    const isSelected = useCallback((id: string): boolean => selection.includes(id), [selection]);

    return (
        <div className="notification-list">
            <div className="notification-list__header">
                <div
                    className={classNames({
                        'form-checkbox': true,
                        'form-checkbox--minus': isIndeterminateState,
                        'notification-list__header-checkbox': true,
                    })}
                >
                    <input
                        type="checkbox"
                        checked={allLinesAreSelected || isIndeterminateState}
                        onChange={() => (allLinesAreSelected ? unselectAll() : selectAll())}
                        disabled={isRequesting}
                        id="selectAll"
                    />
                    <label htmlFor="selectAll" />
                </div>

                <label className="notification-list__header-label text--grey" htmlFor="selectAll">
                    {!allLinesAreSelected ? 'Alle auswählen' : 'Alle abwählen'}
                </label>

                {selection.length > 0 ? (
                    <button
                        type="button"
                        className="button button--primary button--small notification-list__header-submit"
                        onClick={async () => {
                            await setStatusRead(selection);
                            unselectAll();
                        }}
                    >
                        Als gelesen markieren
                    </button>
                ) : null}
            </div>

            {benachrichtigungenAscByDate.map((benachrichtigung: Benachrichtigung) => {
                const {id} = benachrichtigung;
                return (
                    <div className="notification-list__item-wrapper" key={id}>
                        <div className="form-checkbox">
                            <input
                                type="checkbox"
                                checked={selection.includes(id)}
                                onChange={() => selectLineItem(id)}
                                disabled={isRequesting}
                                id={`checkbox-${id}`}
                            />
                            <label htmlFor={`checkbox-${id}`} />
                        </div>

                        <Notification
                            benachrichtigung={benachrichtigung}
                            onClick={() => selectLineItem(id)}
                            isLoading={isRequesting && isSelected(id)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default NotificationList;
