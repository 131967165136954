import React, {useEffect} from 'react';
import LoadingContainer from '../containers/LoadingContainer';
import useAuthenticate from '../hooks/useAuthenticate';
import refreshAccessToken from '../services/refreshAccessToken';

export default function RefreshPage() {
    const {userType} = useAuthenticate();

    useEffect(() => {
        refreshAccessToken(userType ?? '');
    }, []);

    return (
        <div
            style={{
                textAlign: 'center',
                margin: '0 auto',
            }}
        >
            <LoadingContainer
                className="loading-container--standalone"
                contentClassName="loading-container__content--text-only"
                isLoading
            >
                Ihr Zugang wird erneuert...
            </LoadingContainer>
        </div>
    );
}
