import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import '../../assets/scss/6_components/layout/_page-header.scss';

import {ReactComponent as Logo} from '../../assets/images/WW_logo-wuestenrot-2022.svg';
import {ReactComponent as IconBell} from '../../assets/images/icon-bell.svg';
import {ReactComponent as IconHelp} from '../../assets/images/icon-help.svg';
import {ReactComponent as IconLogout} from '../../assets/images/icon-logout.svg';

import InternalLink from '../Routing/InternalLink';
import ROUTES from '../../enum/Routes';
import useStore from '../../hooks/useStore';
import useAuthenticate from '../../hooks/useAuthenticate';
import {transformToDarlehensvertragNumber} from '../../services/numberConverterService';

const PageHeader = () => {
    const {
        darlehensnehmer,
        isLegitimated,
        isAgbAccepted,
        activeDarlehen,
        activeDarlehenId,
        benachrichtigungen,
    } = useStore();
    const location = useLocation();
    const {isAuthenticated} = useAuthenticate();

    const numberOfUnreadNotifications = useMemo<number>(() => {
        return benachrichtigungen.filter(({status}) => status === 'offen').length ?? 0;
    }, [benachrichtigungen]);

    return (
        <header className="page-header">
            <nav className="page-header__nav">
                <div className="page-header__logo">
                    <Logo />
                </div>

                {![ROUTES.AUTHORIZE].includes(location.pathname) ? (
                    <>
                        <div className="page-header__link">
                            <a
                                className="link link--with-chevron"
                                href="https://www.wuestenrot.de/kundenportal"
                                rel="noopener"
                            >
                                Zum Kundenportal
                            </a>
                        </div>

                        <div className="page-header__details">
                            {darlehensnehmer.darlehen && darlehensnehmer.darlehen.length ? (
                                <>
                                    <div className="text text--xs page-header__details-name">
                                        {`${darlehensnehmer.vorname} ${darlehensnehmer.nachname}`}
                                    </div>

                                    {activeDarlehen ? (
                                        <div className="text text--grey text--xs page-header__details-contract-number">
                                            Darlehensvertrag:&nbsp;{transformToDarlehensvertragNumber(activeDarlehen.externeId)}
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                        </div>

                        <ul className="page-header__icon-list">
                            <li>
                                <InternalLink to={ROUTES.FAQ}>
                                    <IconHelp />
                                </InternalLink>
                            </li>
                            {isAuthenticated ? (
                                <>
                                    {activeDarlehen && isLegitimated && isAgbAccepted ? (
                                        <li>
                                            <InternalLink
                                                className="page-header__icon-link"
                                                to={`${ROUTES.DASHBOARD}/${activeDarlehenId}/${ROUTES.NOTIFICATIONS}`}
                                            >
                                                <IconBell />

                                                {numberOfUnreadNotifications > 0 ? (
                                                    <span className="page-header__icon-bubble">
                                                        {numberOfUnreadNotifications}
                                                    </span>
                                                ) : null}
                                            </InternalLink>
                                        </li>
                                    ) : null}

                                    <li>
                                        <InternalLink className="page-header__icon-link" to={ROUTES.LOGOUT}>
                                            <IconLogout />
                                        </InternalLink>
                                    </li>
                                </>
                            ) : null}
                        </ul>
                    </>
                ) : null}
            </nav>
        </header>
    );
};

export default PageHeader;
