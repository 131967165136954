import React, {createContext, ReactNode, useContext, useState} from 'react';
import ROUTES from '../enum/Routes';

export type Session = {
    isAuthenticated?: boolean;
    redirectPath: string;
};

export const initialSession: Session = {
    redirectPath: ROUTES.DASHBOARD,
};

const SessionContext = createContext<[Session, (session: Session) => void]>([initialSession, () => {}]);

export const SessionContextProvider = (props: {children?: ReactNode}) => {
    const {children} = props;
    const [sessionState, setSessionState] = useState(initialSession);
    const defaultSessionContext: [Session, typeof setSessionState] = [sessionState, setSessionState];

    return <SessionContext.Provider value={defaultSessionContext}>{children}</SessionContext.Provider>;
};

export const useSessionContext = () => useContext(SessionContext);
