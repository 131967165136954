import {useEffect, useState} from 'react';
import {FormSchema} from '../@types/forms';

export default function useFormState(
    formMethods: any,
    schema: FormSchema,
    updateState?: (arg0: any) => any,
    mutateFormValues?: (arg0: any) => any
): {isValid: boolean} {
    const [isValid, setIsValid] = useState(false);
    const {formState, getValues, clearErrors} = formMethods;

    useEffect((): void => {
        const hasDirtyFields = !!Object.keys(formState.dirtyFields).length;

        if (!formState.isDirty) {
            return;
        }

        let formValues = getValues();

        if (typeof schema.isValidSync !== 'undefined') {
            const schemaIsValid = schema.isValidSync(formValues);
            if (schemaIsValid !== isValid) {
                setIsValid(schemaIsValid);
            }
        }

        if (!hasDirtyFields) {
            return;
        }

        if (typeof mutateFormValues !== 'undefined') {
            formValues = mutateFormValues?.(formValues);
        }

        const formData = Object.keys(formValues).reduce((accumulator, key) => {
            let value = formValues[key];

            if (typeof formValues[key] === 'undefined') {
                value = '';
            }

            return {
                ...accumulator,
                [key]: value,
            };
        }, {});

        updateState?.(formData);

        // Damit Fehlermeldungen beim Revalidieren auch verschwinden
        clearErrors(Object.keys(formState.dirtyFields));
    }, [formState]);

    return {isValid};
}
