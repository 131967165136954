import React, {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {useValidationResolver} from '@crossbuilders/form-library/hooks';

import DashboardHeader from '../components/DashboardHeader/DashboardHeader';
import TabsContainer from '../components/Tabs/TabsContainer';
import useFormState from '../hooks/useFormState';
import useStore from '../hooks/useStore';
import {DarlehenPerson} from '../@types/data/Darlehensdaten';
import {Logger} from '../services/Logger';
import {Tab} from '../@types/dashboard';
import tabsConfig from '../config/tabs';
import ROUTES from '../enum/Routes';
import {ReactComponent as IconInfo} from '../assets/images/icon-info.svg';
import SimpleNotification from '../components/Notifications/SimpleNotification';
import {getZklUrl} from '../services/zkl';

const formSchema = yup.object({});

export default function DarlehenPage() {
    const {activeDarlehen, activeDarlehenId, darlehensnehmer, updateStore, activeDarlehensnehmer} = useStore();
    const zklUrl = getZklUrl();
    const missingPhoneNumber = !activeDarlehensnehmer?.mobilnummer;
    const navigate = useNavigate();

    const tabs = useMemo(
        (): Array<Tab> => tabsConfig.tabs.filter(
            (tab) => activeDarlehen?.[tab.showTab as keyof DarlehenPerson] || !tab?.showTab,
        ),
        [tabsConfig, activeDarlehen],
    );

    const formMethods = useForm({
        mode: 'all',
        resolver: useValidationResolver(formSchema),
    });

    const {reset} = formMethods;

    useFormState(formMethods, formSchema, updateStore);

    useEffect(() => {
        reset({}, {keepErrors: true});
    }, []);

    useEffect(() => {
        Logger.info('[DarlehenPage]');
    }, [Logger]);

    const navigateToDashboard = async () => {
        await updateStore({activeDarlehenId: ''});
        navigate(ROUTES.DASHBOARD, {state: {preventRedirect: true}});
    };

    if (!activeDarlehenId || !activeDarlehen) {
        return null;
    }

    return (
        <>
            {darlehensnehmer.darlehen?.length > 1 ? (
                <div className="page-breadcrumb">
                    <button className="link link--with-chevron-reverse" type="button" onClick={navigateToDashboard}>
                        Zur Vertragsübersicht
                    </button>
                </div>
            ) : null}

            {missingPhoneNumber && (
                <SimpleNotification status="warning">
                    <IconInfo width="40px" height="40px" />
                    <span>
                        Damit Sie Auszahlungen beauftragen können, benötigen wir Ihre Mobilfunknummer. Bitte
                        hinterlegen Sie diese <a href={zklUrl}>hier</a>.
                    </span>
                </SimpleNotification>
            )}

            <h1 className="headline page-content__headline">Ihr Auszahlungsportal</h1>

            <DashboardHeader disablePayout={missingPhoneNumber} activeDarlehen={activeDarlehen} />

            <TabsContainer tabs={tabs} />
        </>
    );
}
