import React, {MutableRefObject, useMemo, useRef} from 'react';
import {Tooltip} from 'react-tippy';
import {format, getTime, parseISO} from 'date-fns';
import DataTable, {TableColumn} from 'react-data-table-component';

import {ReactComponent as IconView} from '../../../assets/images/eye-light.svg';
import {ReactComponent as SortIcon} from '../../../assets/images/icon-arrow-down-red.svg';

import '../../../assets/scss/6_components/layout/_table.scss';

import {Dokument} from '../../../@types/data/Darlehensdaten';
import useStore from '../../../hooks/useStore';
import useDocumentDownload from '../../../services/downloadDokument';
import LoadingWheel from '../../Layout/LoadingWheel';
import EditableTableCell from '../partials/EditableTableCell';
import NoDataComponent from '../partials/NoDataComponent';
import sortDocumentsFunction from '../partials/sortDocumentsFunction';


interface TableComponentProps {
    data: Array<Dokument>;
    isEditingMode: boolean;
    isLoading: boolean;
    onChange: (rowId: string, column: string) => (value: string | number) => void;
}

export default function TableOfBillsTable(props: TableComponentProps) {
    const {data, isEditingMode, isLoading, onChange} = props;
    const {activeDarlehenId} = useStore();

    const {downloadDokument} = useDocumentDownload();
    const downloadButton = useRef<HTMLAnchorElement>();

    const RechnungsstellerCell = ({row}: {row: Dokument}) => (
        <div className="table__cell-with-icons">
            {row.status === 'ocr_start' ? (
                <Tooltip title="Rechnungsdaten werden ausgelesen…" position="top" theme="light">
                    <div className="table__cell-spinner spinner spinner--white-bg spinner--red">
                        <div className="spinner__element" />
                    </div>
                </Tooltip>
            ) : null}

            <EditableTableCell
                maxLength="100"
                name={`${row.id}.rechnungsstellerName`}
                value={row.rechnungsstellerName ?? ''}
                onChange={onChange(row.id, 'rechnungsstellerName')}
                isEditingMode={isEditingMode}
                disabled={row.status === 'ocr_start'}
            />
        </div>
    );

    const DownloadCell = ({rowId}: {rowId: string}) => (
        <div className="table__cell-with-icons">
            <Tooltip title="Rechnung anzeigen" position="top" theme="light">
                <a
                    href={`#${rowId}`}
                    ref={downloadButton as MutableRefObject<HTMLAnchorElement>}
                    onClick={(event) => {
                        event.preventDefault();

                        if (downloadButton.current) {
                            downloadDokument(downloadButton.current, activeDarlehenId, rowId);
                        }
                    }}
                    type="button"
                >
                    <IconView width="20" height="20" />
                </a>
            </Tooltip>
        </div>
    );

    const columns = useMemo(
        (): Array<TableColumn<Dokument>> => [
            {
                id: 'date',
                name: 'Einreichungsdatum',
                selector: (row) => row?.hochgeladenAm ? getTime(parseISO(row.hochgeladenAm)) : '',
                format: (row) => row?.hochgeladenAm ? format(parseISO(row.hochgeladenAm), 'dd.MM.y') : '',
                sortable: true,
                wrap: true,
                grow: 2,
            },
            {
                name: 'Rechnungssteller',
                selector: (row) => row.rechnungsstellerName ?? '',
                cell: (row) => <RechnungsstellerCell row={row} />,
                sortable: true,
                wrap: true,
                grow: 2,
            },
            {
                name: 'Betrag',
                selector: (row) => row.rechnungGesamtBrutto ?? 0,
                cell: (row) => (
                    <EditableTableCell
                        type="number"
                        name={`${row.id}.rechnungGesamtBrutto`}
                        value={row.rechnungGesamtBrutto ?? 0}
                        onChange={onChange(row.id, 'rechnungGesamtBrutto')}
                        isEditingMode={isEditingMode}
                        disabled={row.status === 'ocr_start'}
                    />
                ),
                sortable: true,
                wrap: true,
            },
            {
                name: '',
                cell: (row) => <DownloadCell rowId={row.id.toString()} />,
                right: true,
                width: '80px',
                allowOverflow: true,
            },
        ],
        [data, isEditingMode],
    );

    return (
        <div className="table">
            <DataTable
                className="table"
                columns={columns}
                data={data}
                responsive
                defaultSortFieldId="date"
                defaultSortAsc={false}
                noDataComponent={<NoDataComponent />}
                sortIcon={<SortIcon />}
                progressPending={isLoading}
                progressComponent={<LoadingWheel isLoading={isLoading} text="Bitte warten…" />}
                sortFunction={sortDocumentsFunction}
            />
        </div>
    );
};
