import React, {useEffect} from 'react';
import {useLocalStorage} from 'react-use';
import LoadingContainer from '../containers/LoadingContainer';
import STORAGE from '../enum/Storage';
import useAuthenticate from '../hooks/useAuthenticate';
import useInitialState from '../hooks/useInitialState';
import useStore from '../hooks/useStore';

export default function LogoutPage() {
    const {REACT_APP_TOKENHANDLER_LOGOUT} = process.env;

    const {userType} = useAuthenticate();
    const {updateStore} = useStore();
    const [, , removePrevRouteFromLocalStorage] = useLocalStorage(STORAGE.PREV_ROUTE);

    const initialState = useInitialState();

    useEffect(() => {
        updateStore(initialState);
        removePrevRouteFromLocalStorage();

        const logoutRedirect = setTimeout(() => {
            window.location.href = `${REACT_APP_TOKENHANDLER_LOGOUT}${userType}`;
        }, 1000);

        return () => {
            clearTimeout(logoutRedirect);
        };
    }, []);

    return (
        <div style={{
            textAlign: 'center',
            margin: '0 auto',
        }}>
            <LoadingContainer
                contentClassName="loading-container__content--text-only"
                className="loading-container--standalone"
                isLoading
            >
                Sie werden abgemeldet...
            </LoadingContainer>
        </div>
    );
}
