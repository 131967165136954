interface BvMessagesKeys {
    [key: string]: string;
}

export interface BvMessagesEnum extends BvMessagesKeys {
    HTAN_TAN_FALSCH: string;
    HTAN_TAN_ZU_OFT_FALSCH: string;
    HTAN_RUFNUMMER_GESPERRT: string;
    HTAN_PRUEFUNG_ZEITSPEMPEL_FALSCH: string;
    HTAN_SMS_SEND_ERROR: string;
    HTAN_TAN_ERFOLGREICH_GEPRUEFT: string;
    HTAN_PRUEFUNG_TAN_ABGELAUFEN: string;
    HTAN_RUFNUMMER_ZEITWEISE_GESPERRT: string;
    HTAN_GESPERRT: string;
    HTAN_TELENO_MUST_BE_IN_DIGITS: string;
}

const Messages: BvMessagesEnum = {
    HTAN_TAN_FALSCH: 'Die erfasste TAN ist nicht korrekt. Bitte prüfen Sie Ihre Eingabe und versuchen Sie es erneut.',
    HTAN_TAN_ZU_OFT_FALSCH: 'Die TAN wurde zu oft falsch eingegeben.',
    HTAN_RUFNUMMER_GESPERRT: 'Aufgrund zu häufiger Fehlversuche bei der TAN-Eingabe haben wir Ihre Mobilfunknummer aus Sicherheitsgründen gesperrt. Bitte wenden Sie sich an unsere Servicehotline 07141 / 16-755900. Vielen Dank.',
    HTAN_PRUEFUNG_ZEITSPEMPEL_FALSCH: 'Die TAN ist abgelaufen. Bitte fordern Sie eine neue TAN an.',
    HTAN_SMS_SEND_ERROR: 'Die TAN konnte nicht gesendet werden.',
    HTAN_TAN_ERFOLGREICH_GEPRUEFT: 'Die TAN wurde bereits erfolgreich überprüft.',
    HTAN_PRUEFUNG_TAN_ABGELAUFEN: 'Die TAN ist abgelaufen. Bitte fordern Sie eine neue TAN an.',
    HTAN_RUFNUMMER_ZEITWEISE_GESPERRT: 'Der TAN-Versand ist nicht möglich. Die Mobilfunknummer ist gesperrt.',
    HTAN_GESPERRT: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal oder kontaktieren sie den Support.',
    HTAN_TELENO_MUST_BE_IN_DIGITS: 'Bitte überprüfen sie die Mobilfunknummer.',
};

export default Messages;
