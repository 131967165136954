import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';

interface LoadingContainerProps {
    isLoading?: boolean;
    className?: string;
    contentClassName?: string;
    small?: boolean;
    button?: boolean;
}

export default function LoadingContainer(props: PropsWithChildren<LoadingContainerProps>): JSX.Element | null {
    const {
        isLoading = false,
        className = '',
        contentClassName = '',
        small = false,
        button = false,
        children,
    } = props;

    if (!isLoading) {
        return null;
    }

    return (
        <div
            className={classNames({
                'loading-container': true,
                'is-loading': isLoading && !button,
                [className]: !!className,
            })}
        >
            {isLoading ? (
                <div
                    className={classNames({
                        'loading-container__spinner spinner': true,
                        'loading-container__spinner--button': button,
                        'spinner--large': !small,
                    })}
                >
                    <div className="spinner__element" />
                </div>
            ) : null}

            <div
                className={classNames({
                    'loading-container__content': true,
                    [contentClassName]: !!contentClassName,
                })}
            >
                {children}
            </div>
        </div>
    );
}
