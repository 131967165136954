import {useState} from 'react';
import useBus, {dispatch} from 'use-bus';
import {FetchOcrByDocumentResponse} from '../@types/services/apiService';
import Events from '../enum/Events';
import useApiService from './useApiService';
import useStore from './useStore';

interface useOCRParams {
    onOcrDone: (response: FetchOcrByDocumentResponse) => void;
    onCancel?: () => void;
}

const DEFAULT_POLLING_INTERVAL = 2000;
const MAX_GATEWAY_TIMEOUT = 60000;

export default function useOCR({onOcrDone, onCancel}: useOCRParams) {
    const {activeDarlehenId} = useStore();
    const [abortController, setAbortController] = useState<AbortController>();
    const [documentId, setDocumentId] = useState<string>('');
    const {fetchOcrByDocument} = useApiService({}, abortController);

    const cancelOcr = () => dispatch(Events.CANCEL_OCR_POLLING);

    const recursivelyPollOcrResult = (abortSignal: AbortSignal) => {
        if (!documentId && !abortSignal.aborted) return;

        fetchOcrByDocument(activeDarlehenId, documentId)
            .then((response: FetchOcrByDocumentResponse) => {
                if (response?.status === 'ocr_done') {
                    onOcrDone(response as FetchOcrByDocumentResponse);
                    setDocumentId('');
                    return;
                }

                setTimeout(() => recursivelyPollOcrResult(abortSignal), DEFAULT_POLLING_INTERVAL);
            })
            .catch(() => cancelOcr());
    };

    const pollOcrResults = (id: string): void => {
        setDocumentId(id);
        setAbortController(new AbortController());

        setTimeout(() => dispatch(Events.START_OCR_POLLING));
    };

    useBus(
        Events.START_OCR_POLLING,
        () => {
            if (documentId && abortController instanceof AbortController) {
                recursivelyPollOcrResult(abortController.signal);

                setTimeout(() => dispatch({
                    type: Events.CANCEL_OCR_POLLING,
                }), MAX_GATEWAY_TIMEOUT);
            }
        },
        [documentId, abortController],
    );

    useBus(
        Events.CANCEL_OCR_POLLING,
        () => {
            if (abortController instanceof AbortController && !abortController.signal.aborted) {
                abortController.abort();
                setDocumentId('');
                onCancel?.();
            }
        },
        [abortController],
    );

    return {
        cancelOcr,
        pollOcrResults,
    };
}
