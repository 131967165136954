import {useCallback, useEffect, useMemo, useState} from 'react';
import {useCookie} from 'react-use';
import {dispatch} from 'use-bus';
import axios from 'axios';

import {Authenticate} from '../@types/authentication';
import Events from '../enum/Events';
import STORAGE from '../enum/Storage';
import {Logger} from '../services/Logger';
import {useTokenHandlerCookies} from './useTokenHandlerCookies';

export default function useAuthenticate(): Authenticate {
    const {
        REACT_APP_TOKENHANDLER_DOMAIN,
        REACT_APP_TOKENHANDLER_CSRF,
        REACT_APP_AUTH_TYPE_AUTH0,
        REACT_APP_AUTH_TYPE_ZKL,
        REACT_APP_TOKENHANDLER_USER_STATE,
    } = process.env;

    const [xbLogin, updateSwitchAuthCookie, deleteSwitchAuthCookie] = useCookie(STORAGE.XB_LOGIN);
    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {csrfToken, expiresIn} = useTokenHandlerCookies();

    const isAuthenticated = useMemo(
        () => Boolean(csrfToken?.length && expiresIn?.length && expiresIn !== '0'),
        [csrfToken, expiresIn],
    );

    const tokenHandler = axios.create({
        baseURL: REACT_APP_TOKENHANDLER_DOMAIN,
        withCredentials: true,
        xsrfCookieName: STORAGE.CSRF,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    tokenHandler.interceptors.response.use(
        (response) => {
            setIsLoading(false);
            Logger.debug('[TokenHandler Interceptor] Response', response);

            return Promise.resolve(response);
        },
        ({response}) => {
            setIsLoading(false);
            Logger.debug('[TokenHandler Interceptor] Error', response);

            return Promise.reject(response);
        },
    );

    const userType = useMemo(
        () => (xbLogin ? REACT_APP_AUTH_TYPE_AUTH0 : REACT_APP_AUTH_TYPE_ZKL),
        [xbLogin],
    );

    const getCsrfToken = () => {
        return new Promise<void>((resolve, reject) => {
            setIsLoading(true);

            if (!userType) {
                reject(new Error('[getCsrfToken] AuthType is undefined'));
                return;
            }

            tokenHandler
                .post(`${REACT_APP_TOKENHANDLER_CSRF}`)
                .then(() => {
                    Logger.debug('[getCsrfToken] Success');
                    resolve();
                })
                .catch((error) => {
                    Logger.error('[getCsrfToken] Error', error);
                    reject(error);
                });
        });
    };

    const getUserLoginState = () => {
        return new Promise<void>((resolve, reject) => {
            setIsLoading(true);

            tokenHandler
                .get(`${REACT_APP_TOKENHANDLER_USER_STATE}`)
                .then(() => {
                    Logger.debug('[getUserLoginState] Success');
                    resolve();
                })
                .catch((error) => {
                    Logger.error('[getUserLoginState] Error', error);
                    reject(error);
                });
        });
    };

    const handleAuthCookieChange = useCallback((): void => {
        if (xbLogin) {
            deleteSwitchAuthCookie();
        } else {
            updateSwitchAuthCookie('oauth', {secure: process.env.NODE_ENV === 'production', sameSite: 'Strict'});
        }
    }, [xbLogin, deleteSwitchAuthCookie, updateSwitchAuthCookie]);

    useEffect(() => {
        if (isMounted) {
            dispatch({type: Events.SWITCH_AUTHENTICATION, value: {xbLogin}});
        }
    }, [xbLogin]);

    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    return {
        userType,
        isLoading,
        setIsLoading,
        isAuthenticated,
        getCsrfToken,
        xbLogin,
        handleAuthCookieChange,
        getUserLoginState,
    };
}
