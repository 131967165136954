import {DarlehenPerson} from '../@types/data/Darlehensdaten';

export function darlehenIsLegitimated(darlehen: DarlehenPerson | null): boolean {
    if (!darlehen || darlehen.darlehensnehmer?.[0]?.letztesLegiErgebnisgelesen) return true;
    if (typeof darlehen.darlehensnehmer?.[0].frontendLegitimationStatus !== 'string') return true;

    return !['erfolgreich', 'nichtErfolgreich'].includes(darlehen.darlehensnehmer[0].frontendLegitimationStatus);
}

export function darlehenIsAgbAccepted(darlehen: DarlehenPerson | null): boolean {
    if (!darlehen || !darlehen.darlehensnehmer?.[0].id) return false;

    return darlehen.darlehensnehmer[0]?.agbAccepted ?? false;
}
