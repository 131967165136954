import React, {useEffect} from 'react';
import {Button} from '@crossbuilders/form-library/components';
import {useNavigate, useLocation} from 'react-router-dom';
import LoadingContainer from '../containers/LoadingContainer';
import useApiService from '../hooks/useApiService';
import ROUTES from '../enum/Routes';
import useAuthenticate from '../hooks/useAuthenticate';

export default function LandingPage() {
    const navigate = useNavigate();
    const {isAuthenticated, isLoading} = useAuthenticate();
    const {isRequesting} = useApiService();
    const location = useLocation();

    useEffect(() => {
        // Callback URL is pointing to / after Authorization
        if (isAuthenticated && location.pathname === '/') {
            navigate(ROUTES.AUTHORIZE, {replace: true});
        }
    }, [isAuthenticated]);

    return (
        <div className="page-content--centered">
            {isAuthenticated || isLoading || isRequesting ? (
                <LoadingContainer
                    isLoading
                    className="loading-container--standalone"
                    contentClassName="loading-container__content--text-only"
                />
            ) : (
                <>
                    <h1 className="headline headline--level-1">Einfache Auszahlung - in Ihren Händen!</h1>
                    <h2 className="headline headline--level-2 headline--red">Schneller zu Ihrem Geld!</h2>

                    <p className="text text--spacing-xl">
                        Vom Auslesen Ihrer Rechnung bis zum Beauftragen der Auszahlung - der neue einfache Weg
                        um Ihre Kreditauszahlungen im Blick zu behalten.
                    </p>

                    <Button
                        className="button button--primary"
                        type="button"
                        onClick={() => navigate(ROUTES.AUTHORIZE, {replace: true})}
                    >
                        Zur Anmeldung
                    </Button>
                </>
            )}
        </div>
    );
}
