import React from 'react';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import {useModal} from '../Modal';

const MissingDocuments = () => {
    const {data} = useModal();

    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <h2 className="headline headline--level-2">Fehlende Bilder</h2>
                <p className="text text--m">
                    Sie haben den Status folgender Gewerke angepasst. Bitte laden Sie über die Upload-Funktion je Gewerk
                    zwischen 1 und 5 Bildern hoch:
                </p>
                <p className="text text--m">{data.join(', ')}</p>
            </div>

            <ModalFooter buttons={[{type: 'ok'}]} />
        </>
    );
};

export default MissingDocuments;
