import {useEffect, useState} from 'react';
import {PureFileQueue} from '../@types/data/Darlehensdaten';
import {postError} from '../services/toastService';
import {MAX_UPLOAD_SIZE, validateDocumentUpload} from '../services/validatorService';
import uniqueId from '../services/uniqueIdService';
import {getOverallFileSize} from '../services/files';

export default function useFileQueue(maxFileCount?: number) {
    const [fileQueue, setFileQueue] = useState<PureFileQueue>({});
    const [hasFileSizeError, setHasFileSizeError] = useState(false);

    const handleOnDrop = (files: Array<File>) => {
        const currentFileCount = fileQueue ? Object.keys(fileQueue).length + files.length : 0;

        if (maxFileCount && (files.length > maxFileCount || currentFileCount > maxFileCount)) {
            postError(`Es können maximal ${maxFileCount} Datei(en) ausgewählt werden.`);
            return;
        }

        const validFiles: {[key: string]: File} = {};

        files.forEach((file: File) => {
            try {
                if (validateDocumentUpload(file)) {
                    const id = uniqueId();
                    validFiles[id] = file;
                }
            } catch (errorResponse: any) {
                postError(errorResponse.message);
            }
        });

        setFileQueue({...fileQueue, ...validFiles});
    };

    useEffect(() => {
        const fileSizeTooBig = getOverallFileSize(Object.values(fileQueue)) > MAX_UPLOAD_SIZE;
        setHasFileSizeError(fileSizeTooBig);
    }, [fileQueue, setHasFileSizeError]);

    return {
        handleOnDrop,
        fileQueue,
        setFileQueue,
        hasFileSizeError,
    };
}
