import React, {useMemo} from 'react';
import {Tooltip} from 'react-tippy';
import DataTable, {TableColumn} from 'react-data-table-component';

import {ReactComponent as IconInfo} from '../../../assets/images/circle-info-light.svg';
import {ReactComponent as SortIcon} from '../../../assets/images/icon-arrow-down-red.svg';

import '../../../assets/scss/6_components/layout/_table.scss';
import '../../../assets/scss/6_components/general/_tag.scss';
import 'react-tippy/dist/tippy.css';

import {Auszahlungsvoraussetzung} from '../../../@types/data/Darlehensdaten';
import useStore from '../../../hooks/useStore';
import getStatusLabel, {Status} from '../../../services/getStatusLabel';
import uniqueId from '../../../services/uniqueIdService';
import NoDataComponent from '../partials/NoDataComponent';
import sortDocumentsFunction from '../partials/sortDocumentsFunction';
import ActionCell from './ActionCell';

const requirementsStatusDictionary: {[key: string]: Status} = {
    erledigt: 'erfüllt',
    nichtErforderlich: 'verzicht',
    eingereicht: 'eingereicht',
    angelegt: 'eingereicht',
    offen: 'offen',
};

export default function TableOfRequirements({introText}: {introText: string}) {
    const {activeDarlehen} = useStore();

    const rowData: Array<Auszahlungsvoraussetzung> = activeDarlehen.auszahlungsvoraussetzungen;

    const columns = useMemo(
        (): TableColumn<Auszahlungsvoraussetzung>[] => [
            {
                name: 'Voraussetzungen',
                cell: (row: Auszahlungsvoraussetzung) => (
                    <div className="table__cell-with-icon">
                        <span>{row.anzeigetext}</span>

                        {row.kundeninstruktion.length ? (
                            <Tooltip
                                title={row.kundeninstruktion}
                                position="top"
                                theme="light"
                                className="table__cell-with-icon-icon-container"
                            >
                                <IconInfo width="20" height="20" />
                            </Tooltip>
                        ) : null}
                    </div>
                ),
                selector: (row: Auszahlungsvoraussetzung) => row.anzeigetext,
                sortable: true,
                grow: 2,
            },
            {
                name: 'Upload online möglich',
                cell: (row: Auszahlungsvoraussetzung) => <span>{row.uploadErlaubt ? 'ja' : 'nein'}</span>,
                selector: (row: Auszahlungsvoraussetzung) => (row.uploadErlaubt ? 'ja' : 'nein'),
                sortable: true,
                grow: 2,
            },
            {
                name: 'Status',
                cell: (row: Auszahlungsvoraussetzung) => {
                    const status: Status = requirementsStatusDictionary[row.status];
                    return <span className={`tag tag--${getStatusLabel(status)}`}>{status}</span>;
                },
                selector: (row: Auszahlungsvoraussetzung) => row.status ?? '-',
                sortable: true,
                width: '150px',
            },
            {
                name: '',
                cell: (row: Auszahlungsvoraussetzung) => {
                    const status: Status = requirementsStatusDictionary[row.status];
                    return <ActionCell row={row} status={status} />;
                },
                right: true,
                width: '50px',
            },
        ],
        [rowData],
    );

    return (
        <>
            <p className="text text--s tabs-content__intro">{introText}</p>
            <DataTable
                className="table"
                data={rowData}
                columns={columns}
                noDataComponent={<NoDataComponent />}
                responsive
                sortIcon={<SortIcon />}
                key={uniqueId()}
                sortFunction={sortDocumentsFunction}
            />
        </>
    );
};
