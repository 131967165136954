export enum NumberFormat {DOT = 'decimal_dot', COMMA = 'decimal_comma'}

export function isNumber(n: any): boolean {
    const number: any = typeof n === 'number' ? n : parseFloat(n);
    return number !== undefined && !Number.isNaN(number) && Number.isFinite(number);
}

export function isNumeric(value: any, numberFormat: NumberFormat): boolean {
    let regex: RegExp;

    switch (numberFormat) {
        case NumberFormat.DOT:
            regex = new RegExp('^(-?[0-9]{1,3}(?:,?[0-9]{3})*(?:.[0-9]+)?)$');
            return regex.test(String(value));
        case NumberFormat.COMMA:
            regex = new RegExp('^(-?[0-9]{1,3}(?:.?[0-9]{3})*(?:,[0-9]+)?)$');
            return regex.test(String(value));
        default:
            return isNumber(value);
    }
}

const isNumericCharacter = (character: string): boolean => {
    const digit: number = parseInt(character, 10);

    return ['-', '.'].includes(character) || (digit >= 0 && digit <= 9);
};

/**
 * Gets a formatted number and returns a clean float number.
 *
 * Note: Input must be formatted according to the specified parameters
 * (symbol_right, symbol_left, decimal_separator).
 * @example input -> $1.20, output -> 1.2
 *
 * @param input            string The currency-formatted number.
 * @param symbolRight      string The symbol used on the right.
 * @param symbolLeft       string The symbol used on the left.
 * @param numberFormat     string The decimal separator being used.
 *
 * @return float The unformatted numerical value.
 */
export function cleanNumber(input: string, symbolRight: string, symbolLeft: string, numberFormat: NumberFormat): number {
    let formattedNumber: string = input;
    let floatNumber: string = '';

    if (numberFormat === NumberFormat.DOT) {
        formattedNumber = formattedNumber
            .replaceAll(',', '');
    } else {
        formattedNumber = formattedNumber
            .replaceAll('.', '')
            .replaceAll(',', '.');
    }

    formattedNumber = formattedNumber
        .replace(symbolLeft, '')
        .replace(symbolRight, '')
        .replaceAll(/&.*?;/g, '');

    floatNumber = formattedNumber
        .split('')
        .filter((char: string) => isNumericCharacter(char))
        .join('');

    return parseFloat(floatNumber);
}

export function tryConvertFloat(input: string, numberFormat: NumberFormat = NumberFormat.COMMA): any {
    if (isNumeric(input, numberFormat)) {
        return cleanNumber(input, '', '', numberFormat);
    }

    return input;
}

interface FormatNumberOptions {
    number: string | number | null;
    digits?: number;
    placeholder?: string;
}

const getParsingOptions = (digits?: number): Intl.NumberFormatOptions | {} => {
    if (!digits) return {};

    return {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    };
};

function formatNumber(value: number, placeholder?: string, digits?: number): string {
    if (Number.isNaN(value)) {
        return placeholder ?? '-';
    }

    return value.toLocaleString('de', getParsingOptions(digits));
}

function formatNumberWithOptions(options: FormatNumberOptions): string {
    const {number, placeholder = '-', digits = undefined} = options;
    const parsedNumber: number = number !== null && isNumber(number) ? parseFloat(number.toString()) : NaN;

    return formatNumber(parsedNumber, placeholder, digits);
}

export function transformToNumberFormat(options?: FormatNumberOptions | number): string {
    if (!options) return '';

    if (typeof options === 'number') {
        return formatNumber(options);
    }

    return formatNumberWithOptions(options);
}

export function transformToDarlehensvertragNumber(number: string): string {
    const first2Digits: string = number.substring(0, 2);
    const middle3Digits: string = number.substring(2, 5);
    const lastDigits: string = number.substring(5);

    return `${first2Digits} ${middle3Digits} ${lastDigits}`;
}
