import React from 'react';
import {Button} from '@crossbuilders/form-library/components';
import uniqueId from '../../services/uniqueIdService';
import classNames from 'classnames';
import {useModal} from './Modal';

interface ButtonConfig {
    type: 'confirm' | 'ok' | 'abort' | 'close' | 'save' | 'finish' | 'upload' | 'forward' | 'submitTan';
    disabled?: boolean;
    onClick?: (event: MouseEvent | TouchEvent) => void;
}

interface ModalFooterProps {
    buttons: ButtonConfig[];
    spread?: boolean;
}

const ModalFooter = ({buttons, spread}: ModalFooterProps) => {
    const {setModalOpen, setIsCloseable} = useModal();

    const closeModal = () => {
        setModalOpen(false);
        setIsCloseable(true);
    };

    const BUTTON_CONFIG = {
        confirm: {
            buttonText: 'Bestätigen',
            primary: true,
        },
        ok: {
            buttonText: 'OK',
            primary: true,
        },
        close: {
            buttonText: 'Schließen',
            primary: true,
        },
        abort: {
            buttonText: 'Abbrechen',
            primary: false,
        },
        save: {
            buttonText: 'Speichern',
            primary: true,
        },
        finish: {
            buttonText: 'Beenden',
            primary: true,
        },
        upload: {
            buttonText: 'Hochladen',
            primary: true,
        },
        forward: {
            buttonText: 'Weiter',
            primary: true,
        },
        submitTan: {
            buttonText: 'Auszahlung freigeben',
            primary: true,
        }
    };

    return (
        <div
            className={classNames({
                modal__footer: true,
                'modal__footer--spread': spread,
            })}
        >
            {buttons.map((button) => (
                <Button
                    key={uniqueId()}
                    disabled={button.disabled}
                    onClick={(event: MouseEvent | TouchEvent): void => {
                        if (typeof button.onClick === 'undefined') {
                            return closeModal();
                        }

                        button.onClick(event);
                    }}
                    type="button"
                    className={classNames({
                        button: true,
                        'button--primary': BUTTON_CONFIG[button.type].primary,
                    })}
                >
                    {BUTTON_CONFIG[button.type].buttonText}
                </Button>
            ))}
        </div>
    );
};

export default ModalFooter;
