import React, {createContext, useContext, useReducer} from 'react';
import {StoreProviderContext, StoreProviderProps} from '../@types/store';

const defaultContextProps: StoreProviderContext = {
    state: {},
    actions() {},
    dispatch() {},
};

const StoreContext = createContext<StoreProviderContext>(defaultContextProps);

export const StoreProvider: React.FC<StoreProviderProps> = (props) => {
    const {children, actions, reducers, initialState, initializer} = props;
    const [state, dispatch] = useReducer(reducers, initialState, initializer);

    return (
        <StoreContext.Provider
            value={{
                state,
                dispatch,
                actions: actions({dispatch, state}),
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = (): StoreProviderContext => useContext(StoreContext);
