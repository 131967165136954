interface RoutesObject {
    INDEX: string;
    DASHBOARD: string;
    PAYOUT_ORDER: string;
    LOGOUT: string;
    AUTHORIZE: string;
    ERROR: string;
    NOTIFICATIONS: string;
    FAQ: string;
    ACCEPT_AGB: string;
    LEGITIMATE: string;
    REFRESH: string;
}

const ROUTES: RoutesObject = Object.freeze({
    INDEX: '/',
    DASHBOARD: '/dashboard',
    PAYOUT_ORDER: 'auszahlungsauftrag',
    LOGOUT: '/logout',
    AUTHORIZE: '/authorize',
    ERROR: '/error',
    NOTIFICATIONS: 'benachrichtigungen',
    FAQ: 'faq',
    ACCEPT_AGB: '/willkommen',
    LEGITIMATE: '/legitimierung',
    REFRESH: '/refresh',
});

export default ROUTES;
