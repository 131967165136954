interface EventsObject {
    PREPARE_DARLEHENSDATEN: string;
    SET_DARLEHENSDATEN: string;
    SWITCH_AUTHENTICATION: string;
    SUBMIT_FILE_UPLOAD: string;
    FILES_LIST_CHANGED: string;
    UPLOAD_FINISHED: string;
    OCR_DONE: string;
    INIT_OCR_POLLING_BILLS: string;
    START_OCR_POLLING: string;
    CANCEL_OCR_POLLING: string;
}

const Events: EventsObject = {
    PREPARE_DARLEHENSDATEN: '@dik/PREPARE_DARLEHENSDATEN',
    SET_DARLEHENSDATEN: '@dik/SET_VERTRAGSDATEN',
    SWITCH_AUTHENTICATION: '@dik/SWITCH_AUTHENTICATION',
    SUBMIT_FILE_UPLOAD: '@dik/SUBMIT_FILE_UPLOAD',
    FILES_LIST_CHANGED: '@dik/FILES_LIST_CHANGED',
    UPLOAD_FINISHED: '@dik/UPLOAD_FINISHED',
    OCR_DONE: '@dik/OCR_DONE',
    INIT_OCR_POLLING_BILLS: '@dik/INIT_OCR_POLLING_BILLS',
    START_OCR_POLLING: '@dik/START_OCR_POLLING',
    CANCEL_OCR_POLLING: '@dik/CANCEL_OCR_POLLING',
};

export default Events;
