import {InitialStoreParams} from '../@types/store';

export default function useInitialState(): InitialStoreParams {
    return {
        isAuthLoading: false,
        currentTabIndex: 0,
        tabs: [],
        activeDarlehenId: '',
        darlehensnehmer: {
            id: null,
            externeId: '',
            vorname: '',
            nachname: '',
            darlehen: [],
            agbAccepted: false,
        },
        darlehensdaten: {
            darlehensnehmer: {
                id: null,
                externeId: '',
                anrede: '',
                titel: '',
                name: '',
            },
            darlehen: [],
        },
        benachrichtigungen: [],
    };
}
