import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocalStorage} from 'react-use';
import useBus from 'use-bus';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {DarlehenPerson} from '../@types/data/Darlehensdaten';
import {FetchOcrByDocumentResponse} from '../@types/services/apiService';
import STORAGE from '../enum/Storage';
import useOCR from '../hooks/useOCR';
import {removeStillLoadingDocuments, replaceDocumentInDarlehen} from '../services/dokumentHelpers';
import {darlehenIsAgbAccepted, darlehenIsLegitimated} from '../services/legitimateDarlehen';
import LoadingContainer from './LoadingContainer';
import useApiService from '../hooks/useApiService';
import useStore from '../hooks/useStore';
import {Logger} from '../services/Logger';
import Events from '../enum/Events';
import ROUTES from '../enum/Routes';
import useInitialState from '../hooks/useInitialState';
import {postSuccess, Toaster} from '../services/toastService';

export default function DashboardContainer() {
    const {
        activeDarlehenId,
        darlehensnehmer,
        isAuthLoading,
        isLegitimated,
        isAgbAccepted,
        activeDarlehen,
        updateStore,
        updateActiveDarlehen,
    } = useStore();

    const {darlehensdaten} = useInitialState();
    const navigate = useNavigate();
    const location = useLocation();
    const {isRequesting, getDarlehensnehmer, getDarlehensdaten, getBenachrichtigungen} = useApiService();
    const [, , removePrevRouteFromLocalStorage] = useLocalStorage(STORAGE.PREV_ROUTE);
    const [loadingText, setLoadingText] = useState<string>('');

    const updateRechnungen = async (response?: FetchOcrByDocumentResponse) => {
        if (!response) return;

        let newDarlehen: DarlehenPerson | {} = replaceDocumentInDarlehen(activeDarlehen, response) ?? {};

        if (response.status === 'ocr_done') {
            newDarlehen = {
                ...newDarlehen,
                rechnungssumme: response.rechnungssumme ?? 0,
            };
        }

        updateActiveDarlehen(newDarlehen);
    };

    const clearTempRechnungen = () => {
        updateActiveDarlehen(removeStillLoadingDocuments(activeDarlehen));
    };

    const {pollOcrResults} = useOCR({onCancel: clearTempRechnungen, onOcrDone: updateRechnungen});

    const isDashboardIndex = useMemo((): boolean => {
        const pathname = location.pathname.replace(/\/$/, '');
        return pathname === ROUTES.DASHBOARD.replace(/\/$/, '');
    }, [location]);

    const setDarlehensdaten = useCallback((darlehenId: string) => {
        if (!isRequesting && darlehenId.length > 0) {
            setLoadingText('Darlehensdaten werden geladen...');
            getDarlehensdaten(darlehenId)
                .then(async (response: DarlehenPerson): Promise<void> => {
                    Logger.info('[DashboardContainer] Current Darlehensdaten', response);

                    await removePrevRouteFromLocalStorage();

                    if (!darlehenIsAgbAccepted(response)) {
                        navigate(ROUTES.ACCEPT_AGB, {state: {preventRedirect: true}});
                        return;
                    }

                    if (!darlehenIsLegitimated(response)) {
                        navigate(ROUTES.LEGITIMATE, {state: {preventRedirect: true}});
                        return;
                    }

                    await getBenachrichtigungen(darlehenId)
                        .then(({benachrichtigungen}): void => {
                            Logger.debug('[DashboardContainer] Get Benachrichtigungen', benachrichtigungen);
                            updateStore({benachrichtigungen});
                        });

                    if (isDashboardIndex) {
                        navigate(`${ROUTES.DASHBOARD}/${response.id}`, {
                            state: {preventRedirect: true},
                        });
                    }
                })
                .catch((error: string): void => {
                    navigate(ROUTES.ERROR, {state: {message: error}});
                });

            if (darlehensnehmer.darlehen.length === 1) {
                navigate(`${ROUTES.DASHBOARD}/${darlehenId}`, {
                    state: {preventRedirect: true},
                });
            }
        }
    }, [isRequesting, darlehensnehmer, isAgbAccepted, isLegitimated]);

    useEffect((): void => {
        Logger.info('[DashboardContainer] Providing Data for SubRoutes');
    }, [Logger]);

    useEffect(() => {
        if (!isAuthLoading && !darlehensnehmer.darlehen?.length) {
            setLoadingText('Kundendaten werden geladen...');
            getDarlehensnehmer().then((data) => {
                Logger.debug('[DashboardContainer] Get Kundendaten Response', data);
                updateStore({darlehensnehmer: data});

                if (!data?.agbAccepted) {
                    navigate(ROUTES.ACCEPT_AGB, {state: {preventRedirect: true}});
                }
            });
        }
    }, [darlehensnehmer]);

    useEffect(() => {
        if (activeDarlehenId?.length && isDashboardIndex) {
            updateStore({
                activeDarlehenId: '',
                darlehensdaten,
            });
        }
    }, [isDashboardIndex]);

    useBus(
        Events.SET_DARLEHENSDATEN,
        () => setDarlehensdaten(activeDarlehenId),
        [activeDarlehenId],
    );

    useBus(
        Events.PREPARE_DARLEHENSDATEN,
        ({value}) => {
            Logger.debug('[DashboardContainer] darlehenId', value.darlehenId);
            updateStore({activeDarlehenId: value.darlehenId});
            setDarlehensdaten(value.darlehenId);
        },
        [],
    );

    useBus(
        Events.INIT_OCR_POLLING_BILLS,
        ({dokumentId}) => {
            Logger.debug('[DashboardContainer] Start OCR Polling for Bills');
            pollOcrResults(dokumentId);
        },
        [pollOcrResults],
    );

    useEffect(() => {
        if (location.state && (location?.state as Record<string, any>)?.message) {
            postSuccess((location.state as Record<string, any>).message as string);
            window.history.replaceState({}, 'message', `${ROUTES.DASHBOARD}/${activeDarlehenId}`);
        }
    }, [location]);

    return (
        <>
            {isRequesting ? (
                <LoadingContainer
                    isLoading
                    className="loading-container--standalone"
                    contentClassName="loading-container__content--text-only"
                >
                    {loadingText}
                </LoadingContainer>
            ) : <Outlet />}

            <Toaster />
        </>
    );
}
