import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import useToaster from 'react-hot-toast';
import {dispatch} from 'use-bus';
import * as yup from 'yup';
import IBAN from 'iban';

import Events from '../enum/Events';
import {transformToNumberFormat, tryConvertFloat} from '../services/numberConverterService';
import schemaRepository from '../services/schemaRepository';
import useOCR from '../hooks/useOCR';
import useApiService from '../hooks/useApiService';
import useValidationResolver from '../hooks/useValidationResolver';
import AuszahlungsauftragPage from '../pages/AuszahlungsauftragPage';
import {AuszahlungsauftragType} from '../@types/forms/Auszahlungsauftrag';
import {FetchOcrByDocumentResponse} from '../@types/services/apiService';
import {containsForbiddenCharacters} from '../services/textFormattingService';
import {postSuccess, postError} from '../services/toastService';
import LoadingContainer from './LoadingContainer';
import {Button} from '@crossbuilders/form-library/components';
import {useModal} from '../components/Modal/Modal';
import {getZklUrl} from '../services/zkl';
import useStore from '../hooks/useStore';

const schema = schemaRepository();

const DEFAULT_MAXIMALER_AUSZAHLUNGSBETRAG = 999999;

export default function AuszahlungsauftragContainer() {
    const {activeDarlehenId, activeDarlehensnehmer, activeDarlehen} = useStore();
    const zklUrl = getZklUrl();
    const modal = useModal();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {validateIban, uploadFileToOcr, submitAuszahlungsauftrag, generateTan} = useApiService();

    const maximalerAuszahlungsbetrag = activeDarlehen?.maximalerAuszahlungsbetrag ?? DEFAULT_MAXIMALER_AUSZAHLUNGSBETRAG;

    const formSchema = yup.object({
        payouts_recipient: schema.string.sanitizeRequired,
        payouts_financial_institution: schema.string.nullable,
        payouts_bic: schema.string.nullable,
        payouts_max_amount: schema.number.nullableRequired,
        payouts_purpose: schema.string.sanitize,
        payouts_iban: schema.string.iban,
        date: schema.date.cashOutDays,
    });

    const formMethods = useForm<AuszahlungsauftragType>({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        resolver: useValidationResolver(formSchema),
        shouldUnregister: true,
    });

    const {watch, setError, setValue, reset} = formMethods;
    const {payouts_file, payouts_purpose, payouts_iban} = watch();

    const triggerIbanValidation = async (iban: string) => {
        if (IBAN.isValid(payouts_iban)) {
            const {ibanValid, bic, bankname} = await validateIban(iban);

            if (ibanValid) {
                setValue('payouts_bic', bic);
                setValue('payouts_financial_institution', bankname);
                return;
            }
        }

        return setError('payouts_iban', {
            message: 'Die von Ihnen angegebene IBAN ist nicht korrekt. Bitte prüfen Sie Ihre Eingabe.',
        });
    };

    const uploadFiles = async (files: File[]) => {
        setIsLoading(true);

        uploadFileToOcr(activeDarlehenId, files)
            .then(({id}) => {
                if (id) {
                    pollOcrResults(id);
                }
            })
            .catch((errorMessage) => {
                postError(errorMessage);
                setIsLoading(false);
            });
    };

    const updateFormWithOcrResponse = (response: FetchOcrByDocumentResponse) => {
        const {
            zahlungsempfaengerName,
            zahlungsdatenVerwendungszweck,
            rechnungGesamtBrutto,
            zahlungsdatenIban,
        } = response;

        const schema: {[field: string]: {value: string | number, isValid: boolean}} = {
            'payouts_recipient': {
                value: zahlungsempfaengerName ?? '',
                isValid: !!zahlungsempfaengerName
                    && !containsForbiddenCharacters(zahlungsempfaengerName)
                    && zahlungsempfaengerName.length <= 27,
            },
            'payouts_purpose': {
                value: zahlungsdatenVerwendungszweck ?? '',
                isValid: !!zahlungsdatenVerwendungszweck
                    && !containsForbiddenCharacters(zahlungsdatenVerwendungszweck)
                    && zahlungsdatenVerwendungszweck.length <= 50,
            },
            'payouts_max_amount': {
                value: transformToNumberFormat({number: Number(rechnungGesamtBrutto) ?? 0}) ?? '-',
                isValid: !!rechnungGesamtBrutto && Number(rechnungGesamtBrutto) <= maximalerAuszahlungsbetrag,
            },
            'payouts_iban': {
                value: zahlungsdatenIban ?? '',
                isValid: !!zahlungsdatenIban && IBAN.isValid(zahlungsdatenIban),
            },
        };

        setIsLoading(false);
        window.scrollTo(0, 0);

        let containsValidData: boolean = Object.values(schema).some(({isValid}) => isValid);
        if (containsValidData) {
            reset();

            Object.keys(schema).forEach((field) => {
                if (schema[field].isValid) {
                    setValue(field as keyof AuszahlungsauftragType, schema[field].value as never);
                }
            });

            postSuccess('Daten wurden erfolgreich befüllt.');
        } else {
            postError('In der hochgeladenen Datei wurden keine Zahlungsdaten gefunden.');
        }

        dispatch(Events.OCR_DONE);
    };

    const {
        cancelOcr,
        pollOcrResults,
    } = useOCR({
        onOcrDone: updateFormWithOcrResponse,
        onCancel: () => setIsLoading(false),
    });

    const onSubmit = async (payload: AuszahlungsauftragType) => {
        const {
            payouts_recipient,
            payouts_max_amount,
            payouts_purpose,
            payouts_iban,
            date,
        } = payload;
        let auftragsId = '';

        try {
            const betrag = tryConvertFloat(payouts_max_amount);

            const submitResponse = await submitAuszahlungsauftrag(
                activeDarlehenId,
                {
                    iban: payouts_iban,
                    ausfuehrungsdatum: date as string,
                    empfaengerName: payouts_recipient,
                    verwendung: payouts_purpose,
                    betrag: typeof betrag === 'number' ? betrag : 0,
                },
            );

            auftragsId = submitResponse.id;
        } catch (error: any) {
            if (Array.isArray(error?.messages)) {
                error.messages.forEach(({text}: any) => useToaster.error(text));
            }

            return;
        }

        generateTan(activeDarlehenId, auftragsId)
            .then(() => {
                modal.setModalType('tanVerification');
                modal.setData({
                    darlehenId: activeDarlehenId,
                    auftragsId: auftragsId as string,
                    generateTan,
                    zklUrl,
                    currentPhoneNumber: activeDarlehensnehmer.mobilnummer,
                });
                modal.setModalOpen(true);
            })
            .catch((errorMessage?: string) => {
                postError(errorMessage ?? 'Es konnte keine TAN versendet werden');
            });
    };

    useEffect(() => {
        if (payouts_iban) {
            triggerIbanValidation(payouts_iban);
        }
    }, [payouts_iban]);

    return (
        <>
            {isLoading ? (
                <LoadingContainer
                    className="loading-container--standalone loading-container--cancelable"
                    contentClassName="loading-container__content loading-container__content--text-only"
                    isLoading
                >
                    <div>
                        <p>Die Dateien werden verarbeitet</p>

                        <div className="button-group button-group--column">
                            <Button
                                className="button"
                                type="button"
                                onClick={() => {
                                    setIsLoading(false);
                                    cancelOcr();
                                }}
                            >
                                Abbrechen
                            </Button>
                        </div>
                    </div>
                </LoadingContainer>
            ) : null}

            <AuszahlungsauftragPage
                darlehenId={activeDarlehenId ?? ''}
                onSubmit={onSubmit}
                formMethods={formMethods}
                payoutsPurpose={payouts_purpose}
                payoutsFile={payouts_file}
                ocrLoading={isLoading}
                cancelOcr={cancelOcr}
                uploadFiles={uploadFiles}
                maximalerAuszahlungsbetrag={maximalerAuszahlungsbetrag}
            />
        </>
    );
}
