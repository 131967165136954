import React from 'react';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';

const OpenTextarea = () => {
    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <h2>Fügen Sie Kundenhinweise hinzu.</h2>
            </div>

            <textarea></textarea>

            <ModalFooter buttons={[{type: 'abort'}, {type: 'confirm'}]} spread={true} />
        </>
    );
};

export default OpenTextarea;
