import {postError} from '../../../services/toastService';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import {useModal} from '../Modal';
import {Button} from '@crossbuilders/form-library/components';
import {useRef, useState} from 'react';
import useApiService from '../../../hooks/useApiService';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../../../enum/Routes';
import {AuthenticateTanForAuszahlungResponse} from '../../../@types/services/apiService';
import useStore from '../../../hooks/useStore';
import {Zahlung} from '../../../@types/data/Darlehensdaten';

const TanVerification = () => {
    const {data, setModalOpen} = useModal();
    const {darlehenId, auftragsId, zklUrl, currentPhoneNumber} = data;
    const {generateTan, authorizeTanForAuszahlung} = useApiService();
    const navigate = useNavigate();

    const tanInputRef = useRef<HTMLInputElement>(null);
    const [tan, setTan] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const {activeDarlehen, updateActiveDarlehen} = useStore();

    const handleTanInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTan(event.target.value);
    };

    const triggerTanGeneration = () => {
        if (typeof generateTan === 'function') {
            setLoading(true);
            generateTan(darlehenId as string, auftragsId as string)
                .then(() => setMessage('Eine neue TAN wurde per SMS versandt'))
                .catch((errorMessage?: string) => {
                    postError(errorMessage ?? 'Es konnte keine TAN versendet werden');
                })
                .finally(() => setLoading(false));
        }
    };

    const submitTan = async () => {
        const tan = tanInputRef?.current?.value || '';

        if (tan.length === 6) {
            setLoading(true);
            authorizeTanForAuszahlung(darlehenId, auftragsId, tan)
                .then(async (response: AuthenticateTanForAuszahlungResponse) => {
                    const {zahlung, maximalerAuszahlungsbetrag} = response;

                    const updatedZahlungen: Array<Zahlung> = [...activeDarlehen.zahlungen, zahlung];

                    await updateActiveDarlehen({
                        zahlungen: updatedZahlungen,
                        maximalerAuszahlungsbetrag: maximalerAuszahlungsbetrag,
                    });

                    setModalOpen(false);

                    navigate(`${ROUTES.DASHBOARD}/${darlehenId}/?tab=auszahlungen`, {
                        state: {message: 'Auszahlungsauftrag erfolgreich angelegt.', preventRedirect: true},
                    });
                })
                .catch((errorMessage) => postError(errorMessage, {duration: 20000}))
                .finally(() => setLoading(false));
        }
    };

    return (
        <>
            <ModalHeader withLogo />

            <div className="modal__content">
                <h2 className="headline headline--level-1 text--centered">Auszahlung freigeben</h2>

                <div className="flex flex--margin-y flex--col">
                    <p className="text">
                        Wir haben Ihnen eine SMS an folgende Mobilfunknummer gesendet: {currentPhoneNumber}
                    </p>
                    <p className="text">
                        Ist das nicht Ihre Mobilfunknummer?&nbsp;
                        <a className="link" href={zklUrl}>
                            Hier
                        </a>
                        &nbsp; können Sie Ihre Mobilfunknummer aktualisieren.
                    </p>
                </div>
                <div className="flex flex--margin-y">
                    <p className="flex flex--margin-y text">
                        Bitte bestätigen Sie die Freigabe mit einer gültige SMS-TAN.
                    </p>
                </div>

                <div className="flex flex--grow flex--justify-center">
                    <div className="flex flex--grow">
                        <input
                            type="number"
                            className="input input--bg-grey flex--grow"
                            ref={tanInputRef}
                            value={tan}
                            onChange={handleTanInputChange}
                            autoComplete="one-time-code"
                            minLength={4}
                            maxLength={4}
                        />
                    </div>
                    <div>
                        <Button
                            type="button"
                            onClick={triggerTanGeneration}
                            className="button button--small button--ghost flex--grow"
                            disabled={loading}
                        >
                            TAN erneut senden
                        </Button>
                    </div>
                </div>
                <div className="flex flex--margin-y">
                    <p className="text text--success">{message ?? ''}</p>
                </div>
            </div>

            <ModalFooter
                buttons={[
                    {
                        type: 'submitTan',
                        onClick: submitTan,
                        disabled: tan.length !== 6 || loading
                    },
                ]}
            />
        </>
    );
};

export default TanVerification;
