import {AccordionItemConfig} from '../components/Layout/Accordion';

export const documentUploadsConfig: Array<AccordionItemConfig> = [
    {
        title: 'Welche Vorteile hat es, Dokumente im Auszahlungsportal hochzuladen?',
        content:
            '<p>Im Auszahlungsportal hochgeladene Dokumente kommen direkt und ohne Zeitverzug durch z.B. den Postweg bei Wüstenrot an. Dadurch beschleunigt sich auch die Auszahlung aus Ihrem Darlehen. Zudem leisten Sie einen Beitrag zu einer nachhaltigen Zukunft.</p>',
    },
    {
        title: 'Wie kann ich Unterlagen zu einer Auszahlungsvoraussetzung hochladen?',
        content:
            '<p>Wenn eine Voraussetzung in der Spalte Upload online möglich mit einem Ja gekennzeichnet ist, können Sie diese über den Button Dokumente hochladen oder über das Hochladen-Icon in der jeweiligen Zeilen digital einreichen. Dort können Sie auswählen, zu welcher Voraussetzung Sie Unterlagen hochladen möchten.</p>',
    },
    {
        title: 'Warum kann mein Dokument nicht hochgeladen werden?',
        content:
            '<p>Dass Ihr Dokument nicht hochgeladen werden kann, kann die folgenden Gründe haben:</p> <ul><li>Die Datei ist zu groß.</li><li>Die Datei hat kein zulässiges Format (mögliche Formate sind jpeg, png und pdf).</li><li>Es kommt zu einem technischen Fehler.</li><li>Die maximale Anzahl an Dateien, die hochgeladen werden können, wird überschritten.</li></ul>',
    },
    {
        title: 'Ich habe ein falsches Dokument hochgeladen. Wie kann ich das richtige Dokument hochladen?',
        content:
            '<p>Die Dokumente werden nach dem Hochladen direkt an Wüstenrot übermittelt und können nicht mehr zurückgezogen werden.</p><p>Solange eine Voraussetzung noch nicht mit dem Status <i>erfüllt</i> oder <i>Verzicht</i> gekennzeichnet ist, können Sie das entsprechende Dokument dazu jederzeit hochladen.</p>',
    },
];

export const miscConfig: Array<AccordionItemConfig> = [
    {
        title: 'Wie setzt sich die Summe "davon verfügbar" zusammen?',
        content:
            '<p>Der Betrag <i>davon verfügbar</i> setzt sich aus der Darlehenssumme abzüglich der bereits erfolgten und der geplanten, aber noch nicht ausgeführten Auszahlungen zusammen (s. auch <strong>Wie setzt sich die Summe in Verarbeitung zusammen?</strong>).</p>',
    },
    {
        title: 'Ich möchte eine Auszahlung beauftragen. Warum werde ich aufgefordert, meine Identität nachzuweisen?',
        content:
            '<p>Bevor Sie Ihre erste Auszahlung beauftragen, möchten wir zu Ihrer eigenen Sicherheit prüfen, dass die Auszahlung tatsächlich von Ihnen erfasst wird. Wie diese Prüfung erfolgt, erläutern wir unter <strong>Wie läuft der Identitätsnachweis ab?</strong></p>',
    },
    {
        title: 'Wie läuft der Identitätsnachweis ab?',
        content:
            '<p>Zum Nachweis Ihrer Identität benötigen Sie lediglich ein gültiges Ausweisdokument und Ihr Smartphone.</p><p>Die Online-Identifizierung nimmt unser Partner Nect vor. Dieser prüft die Echtheit Ihres Ausweisdokuments und gleicht dieses mit Ihren biometrischen Daten ab. Folgen Sie dazu einfach den Anweisungen auf Ihrem Bildschirm.</p><p>Die gesamte Prüfung nimmt nur wenige Minuten in Anspruch.</p>',
    },
    {
        title: 'Wie setzt sich die Summe "in Verarbeitung" zusammen?',
        content:
            '<p>Der Betrag der Auszahlungen <i>in Verarbeitung</i> beinhaltet alle geplanten, aber noch nicht ausgeführten Auszahlungen.</p>',
    },
];

export const payoutRequirementsConfig: Array<AccordionItemConfig> = [
    {
        title: 'Was bedeuten die Status hinter den Auszahlungsvoraussetzungen?',
        content:
            '<p>Die Auszahlungsvoraussetzungen können folgende Status aufweisen:</p> <ul><li><i>offen</i>: Zu dieser Auszahlungsvoraussetzung wurden noch keine Unterlagen eingereicht.</li><li><i>eingereicht</i>: Zu dieser Auszahlungsvoraussetzung wurden Dokumente hochgeladen. Diese werden nun von uns geprüft.</li><li><i>erfüllt</i>: Die erforderlichen Unterlagen liegen vor und wurden geprüft.</li><li><i>Verzicht</i>: Auf die Einreichung von Unterlagen zu dieser Auszahlungsvoraussetzung verzichten wir.</li></ul>',
    },
    {
        title: 'Wie kann ich Auszahlungsvoraussetzungen einreichen?',
        content:
            '<p>Der schnellste Weg ist das Hochladen der Unterlagen, die uns nicht im Original vorliegen müssen, über das Auszahlungsportal. Natürlich können Sie die Unterlagen auch wie gewohnt per E-Mail, per Post oder über Ihre/n Berater/in vor Ort einreichen.</p><p>Es reicht aus, wenn Sie die Unterlagen über einen dieser Wege einreichen. Wenn Sie uns z.B. ein Dokument bereits per Post zugesendet haben, müssen Sie dieses nicht noch über das Auszahlungsportal hochladen.</p>',
    },
];

export const proofOfUseConfig: Array<AccordionItemConfig> = [
    {
        title: 'Was ist ein Bautenstandsbericht?',
        content:
            '<p>In dem Reiter Bautenstandsbericht können Sie uns über den Stand Ihrer Modernisierungs- bzw. Baumaßnahme informieren. Sie haben dort die Möglichkeit, den Status der Gewerke (Bauarbeiten am Objekt) zu pflegen und die dazu passenden Bilder hochzuladen. Bitte beachten Sie, dass eine Statusänderung nur dann möglich ist, wenn auch ein Bild hochgeladen wird.</p><p>Über <i>Bautenstandsbericht abschicken</i> können Sie Ihre Änderungen speichern und die Bilder an uns weiterleiten.</p>',
    },
    {
        title: 'Wie kann ich meinen Status ändern?',
        content:
            '<p>Um den Status eines Gewerks ändern zu können, klicken Sie auf den Button <i>Bautenstandsbericht</i> bearbeiten. Anschließend können Sie den gewünschten Status per Drop-Down-Menü auswählen. Wird ein Status geändert, erscheint in der entsprechenden Zeile ein Hochladen-Button. Klicken Sie diesen an und laden Sie mindestens ein Bild als Nachweis des Fertigstellungsgrads hoch. Bitte denken Sie daran, die Statusänderung über Bautenstandsbericht abschicken zu speichern.</p>',
    },
    {
        title: 'Was bedeuten die Status hinter den Gewerken?',
        content:
            '<p>Der Bautenstandbericht kann folgende Status aufweisen:</p><ul><li><i>Offen</i>: Die Arbeiten an dem Gewerk wurden noch nicht begonnen.</li><li><i>Teilweise fertiggestellt</i>: Die Arbeiten an dem Gewerk wurden begonnen und sind bereits teilweise abgeschlossen.</li><li><i>Fertiggestellt</i>: Die Arbeiten an dem Gewerk sind abgeschlossen.</li></ul>',
    },
    {
        title: 'Was sind Bauberichte und wann sind diese hochzuladen?',
        content:
            '<p>Ein Baubericht ist eine Auszahlungsvoraussetzung. Architekten bzw. Bauträger bestätigen darin den aktuellen Fertigstellungsgrad bestimmter Baumaßnahmen. Einzureichen ist der Baubericht jeweils nach Fertigstellung des Rohbaus und der Außenanlagen.</p><p>Wenn Sie einen Baubericht hochgeladen haben, finden Sie das entsprechende Dokument dazu im Reiter <i>Rechnungen</i>.</p>',
    },
];

export const billsConfig: Array<AccordionItemConfig> = [
    {
        title: 'Wie lade ich eine Rechnung hoch und kann ich dazu direkt eine Auszahlung beauftragen?',
        content:
            '<p>Rechnungen können Sie uns über die Funktionen <i>Dokumente hochladen</i> oder <i>Auszahlung beauftragen</i> zukommen lassen.</p><p>Wenn Sie eine Rechnung über Dokumente hochladen einreichen, wird diese automatisch ausgelesen und im Bereich Rechnungen abgelegt.</p><p>Wenn Sie eine Auszahlung zu einer Rechnung beauftragen möchten, dann laden Sie Ihre Rechnungen über die Funktion Auszahlung beauftragen hoch. Die erforderlichen Daten werden automatisch ausgelesen und als Vorschlag für die Überweisung übernommen. Die Daten des Vorschlags können Sie ändern, bevor Sie die Auszahlung beauftragen.</p>',
    },
    {
        title: 'Die angezeigten Daten einer von mir hochgeladenen Rechnung sind falsch oder fehlen. Wie kann ich die Daten ändern?',
        content:
            '<p>Wenn Sie eine Rechnung hochladen, wird diese automatisch ausgelesen. In einigen Fällen kann es vorkommen, dass Daten falsch oder gar nicht erkannt werden. Im Bereich Rechnungen haben Sie die Möglichkeit, diese Daten zu korrigieren oder zu ergänzen. Klicken Sie dazu auf den Button Rechnungsdaten bearbeiten. Anschließend können Sie in das falsch ausgelesene bzw. leere Feld klicken und die korrekten Daten eingeben. Mit Klick auf den Button Änderungen speichern werden Ihre Eingaben übernommen.</p>',
    },
    {
        title: 'Ich habe mehrere Kassenbons vorliegen. Wie kann ich mir die Gesamtsumme der Rechnungen auszahlen lassen?',
        content:
            '<p>Über die Funktion Dokumente hochladen können Sie Kassenbons hochladen. Diese werden im Bereich Rechnungen abgelegt und angezeigt. Anschließend können Sie über die Funktion Auszahlung beauftragen einen Zahlungsauftrag über die Gesamtsumme der hochgeladenen Kassenbons erteilen.</p>',
    },
];

export const payoutsConfig: Array<AccordionItemConfig> = [
    {
        title: 'Wie kann ich eine Auszahlung beauftragen?',
        content:
            '<p>Klicken Sie dazu auf den Button Auszahlung beauftragen. Sie können die Überweisungsdaten anschließend manuell eingeben oder eine Rechnung hochladen. Diese wird automatisch ausgelesen und es wird Ihnen ein Überweisungsvorschlag unterbreitet. Bitte prüfen Sie den Überweisungsvorschlag, bevor Sie die Auszahlung beauftragen. Die Daten des Vorschlags können Sie - sofern erforderlich - ändern.</p>',
    },
    {
        title: 'Was bedeuten die Status hinter den Auszahlungen?',
        content:
            '<p>Die Auszahlungen können folgende Status aufweisen:</p><ul><li>ausgeführt: Die von Ihnen beauftragte Auszahlung wurde ausgeführt.</li><li>geplant: Die von Ihnen beauftragte Auszahlung ist zur Ausführung zu einem bestimmten Termin vorgemerkt oder wurde noch nicht ausgeführt.</li><li>storniert: Die von Ihnen beauftragte Auszahlung wurde storniert. Die möglichen Gründe hierfür finden Sie unter <strong>Warum wurde meine Auszahlung storniert?</strong></li></ul>',
    },
    {
        title: 'Wie kann ich meine Handynummer ändern?',
        content:
            '<p>Sollte sich Ihre Handynummer geändert haben, haben Sie zwei Möglichkeiten diese zu aktualisieren:</p><ol><li>Im Reiter Auszahlung befindet sich ein Link, der Sie direkt auf die Seite weiterleitet, auf der Sie Ihre Handynummer ändern können.</li><li>Im Dialog Auszahlungen beauftragen finden Sie bei der Erfassung der SMS-TAN einen Link, der Sie direkt auf die korrekte Seite weiterleitet. Bitte beachten Sie, dass die zuvor erfassten Daten in diesem Fall verloren gehen.</li></ol>',
    },
    {
        title: 'Warum wurde meine Auszahlung storniert?',
        content:
            '<p>Dies kann mehrere Gründe haben: Wenn eine von Ihnen beauftragte Auszahlung vor der Ausführung von uns angepasst wird (z.B. aufgrund eines Fehlers in der IBAN o.ä.), erscheint diese Auszahlung in Ihrer Übersicht mit dem Status storniert. Darüber finden Sie dann eine neue Auszahlung mit den von uns angepassten Daten und dem Status ausgeführt oder geplant.</p><p>Wenn eine Auszahlung aus einem anderen Grund nicht ausgeführt werden kann, werden Sie hierüber gesondert per Post informiert.</p>',
    },
];
