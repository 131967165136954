/**
 * @var {int} MAX_UPLOAD_SIZE Maximale Dateigröße für den Dokumenten-Upload, in Bytes
 */
export const MAX_UPLOAD_SIZE: number = 7e6;
/**
 * @var {object} DOCUMENT_MIME_TYPES Keys: Mime-typ, Values: menschenlesbare Ausgabe
 */
export const DOCUMENT_MIME_TYPES: Record<string, string> = {
    'application/pdf': 'PDF',
    'image/png': 'PNG',
    'image/jpeg': 'JPEG',
    'image/tiff': 'TIFF',
};

export const DROPZONE_MIME_TYPES: Record<string, Array<string>> = {
    'application/pdf': ['.pdf'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'image/tiff': ['.tiff'],
}

export const ACCEPTED_MIME_TYPES: Array<string> = Object.keys(DOCUMENT_MIME_TYPES);

export const commaSeperatedList = (list: Array<string>): string =>
    list.reduce((result, current, index) => (index === 0 ? current : `${result}, ${current}`), '');

/**
 * fileSizeForHumans
 * @param {int} size Dateigröße in Bytes
 * @returns {string} Dateigröße in menschenlesbarer Form
 */
export const fileSizeForHumans = (size: number): string => {
    if (size === 0) return '0 B';

    const i = Math.floor(Math.log(size) / Math.log(1000));
    const value = (size / 1000 ** i).toFixed(2);

    const suffix = ['B', 'kB', 'MB', 'GB'][i];
    return `${value} ${suffix}`;
};

const maxDocumentSize: string = fileSizeForHumans(MAX_UPLOAD_SIZE);
export const FILE_UPLOAD_SIZE_HINT = `Die Dateien für den Upload dürfen insgesamt max. ${maxDocumentSize} betragen.`;

export const validateDocumentUpload = (file: File): File => {
    if (file.size > MAX_UPLOAD_SIZE) {
        throw new Error(
            `Die Datei ${file.name} ist zu groß. Die maximale Dateigröße beträgt: ${maxDocumentSize}.`
        );
    }

    if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
        const allowedMimeTypes = commaSeperatedList(Object.values(DOCUMENT_MIME_TYPES));

        throw new Error(
            `Die Datei ${file.name} hat das falsche Format. Erlaubte Dateiformate sind: ${allowedMimeTypes}.`
        );
    }

    return file;
};
