// An export from: https://get.api-feiertage.de?all_states=true
// It will return the holidays for the upcoming 3 years
// The actual report consists of 2022, 2023, 2024
// Todo get a fresh export latest end of 2023

type FeiertageHolidayType = {
    date: string;
    fname: string;
    all_states: '1';
    bw: string;
    by: string;
    be: string;
    bb: string;
    hb: string;
    hh: string;
    he: string;
    mv: string;
    ni: string;
    nw: string;
    rp: string;
    sl: string;
    sn: string;
    st: string;
    sh: string;
    th: string;
    comment: string;
};

const holidaysDe: FeiertageHolidayType[] = [
    {
        date: '2022-10-03',
        fname: 'Tag der deutschen Einheit',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2022-12-25',
        fname: '1. Weihnachtstag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2022-12-26',
        fname: '2. Weihnachtstag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-01-01',
        fname: 'Neujahr',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-04-07',
        fname: 'Karfreitag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-04-10',
        fname: 'Ostermontag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-05-01',
        fname: 'Tag der Arbeit',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-05-18',
        fname: 'Christi Himmelfahrt',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-05-29',
        fname: 'Pfingstmontag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-10-03',
        fname: 'Tag der deutschen Einheit',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-12-25',
        fname: '1. Weihnachtstag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2023-12-26',
        fname: '2. Weihnachtstag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-01-01',
        fname: 'Neujahr',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-03-29',
        fname: 'Karfreitag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-04-01',
        fname: 'Ostermontag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-05-01',
        fname: 'Tag der Arbeit',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-05-09',
        fname: 'Christi Himmelfahrt',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-05-20',
        fname: 'Pfingstmontag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-10-03',
        fname: 'Tag der deutschen Einheit',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-12-25',
        fname: '1. Weihnachtstag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
    {
        date: '2024-12-26',
        fname: '2. Weihnachtstag',
        all_states: '1',
        bw: '1',
        by: '1',
        be: '1',
        bb: '1',
        hb: '1',
        hh: '1',
        he: '1',
        mv: '1',
        ni: '1',
        nw: '1',
        rp: '1',
        sl: '1',
        sn: '1',
        st: '1',
        sh: '1',
        th: '1',
        comment: '',
    },
];

export default holidaysDe;
