import React, {useEffect, useRef} from 'react';
import TableOfRequirements from '../Tables/TableOfRequirements';
import uniqueId from '../../services/uniqueIdService';
import TableOfPayouts from '../Tables/TableOfPayouts';
import {Logger} from '../../services/Logger';
import useStore from '../../hooks/useStore';
import TableOfBills from '../Tables/TableOfBills';
import TableOfProofOfUse from '../Tables/TableProofOfUse';
import TabsContentInner from './TabsContentInner';

const TabsContent = () => {
    const {activeDarlehen, currentTab} = useStore();
    const element = useRef<HTMLDivElement>(null);

    useEffect(() => {
        Logger.debug('[TabsContent] CurrentTab: ', currentTab);
        Logger.debug('[TabsContent] TableComponent: ', currentTab?.tableComponent);
        Logger.debug('[TabsContent] activeDarlehen: ', activeDarlehen);
    }, [currentTab, activeDarlehen]);

    if (!activeDarlehen) return null;

    const {
        frontendFunktionserlaubnisKundeRechnungBearbeiten: allowRechnungBearbeiten,
        frontendFunktionserlaubnisKundeBautenstandBearbeiten: allowBautenstandBearbeiten,
    } = activeDarlehen;

    const tableMapping: {[key: string]: JSX.Element} = {
        requirements: <TableOfRequirements key={uniqueId()} introText={currentTab?.introText ?? ''} />,
        bills: <TableOfBills
            key={uniqueId()}
            allowEditing={allowRechnungBearbeiten}
            introText={currentTab?.introText ?? ''}
        />,
        proofOfUse: <TableOfProofOfUse
            key={uniqueId()}
            introText={currentTab?.introText ?? ''}
            allowEditing={allowBautenstandBearbeiten}
        />,
        payouts: <TableOfPayouts key={uniqueId()} introText={currentTab?.introText ?? ''} />,
    };

    const setHeight = (value: string): void => {
        if (element.current) {
            element.current.style.height = `${value}px`;
        }
    };

    return (
        <div className="tabs-content" ref={element}>
            {currentTab?.tableComponent ? (
                <TabsContentInner setHeight={setHeight}>
                    {tableMapping[currentTab.tableComponent]}
                </TabsContentInner>
            ) : null}
        </div>
    );
};

export default TabsContent;
