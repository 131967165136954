import {useCookie} from 'react-use';
import {Dispatch} from 'react';
import STORAGE from '../enum/Storage';

export interface TokenHandlerCookies {
    csrfToken: string | null;
    expiresIn: string | null;
    deleteCsrfToken: Dispatch<void>;
    deleteExpiresIn: Dispatch<void>;
}

export function useTokenHandlerCookies(): TokenHandlerCookies {
    const [csrfToken, , deleteCsrfToken] = useCookie(STORAGE.CSRF);
    const [expiresIn, , deleteExpiresIn] = useCookie(STORAGE.EXPIRES_IN);

    return {
        csrfToken,
        expiresIn,
        deleteCsrfToken,
        deleteExpiresIn,
    };
}
