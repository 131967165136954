import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Logger } from "../../services/Logger";

export type ProtectedRouteProps = {
	isAuthenticated: boolean;
	authenticationPath: string;
	redirectPath: string | undefined;
	setRedirectPath: (path: string) => void;
	children?: JSX.Element | null;
};

interface CurrentLocationState {
	preventRedirect?: boolean;
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
	const {
		isAuthenticated,
		authenticationPath,
		redirectPath,
		setRedirectPath,
		children
	} = props;

	const currentLocation = useLocation();
	const { pathname, state } = currentLocation;

	const currentLocationState = state as CurrentLocationState;

	useEffect(() => {
		if (!isAuthenticated || redirectPath !== pathname) {
			Logger.debug("[ProtectedRoute] Set new redirect path", pathname);
			setRedirectPath(pathname);
		}
	}, [isAuthenticated, setRedirectPath, currentLocation]);

	if (
		(isAuthenticated && redirectPath === pathname) ||
		(isAuthenticated && currentLocationState?.preventRedirect)
	) {
		Logger.debug("[ProtectedRoute] Return Children or Outlet");
		return children ?? <Outlet />;
	}

	return (
		<Navigate to={isAuthenticated && redirectPath?.length ? redirectPath : authenticationPath} replace />
	);
}
