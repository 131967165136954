import React, { useCallback, useEffect, useMemo } from "react";

import "../../assets/scss/6_components/general/_loan-details.scss";

import PieChart from "./PieChart";
import { ChartValues, LoanDiagramProps } from "../../@types/dashboard";
import { Logger } from "../../services/Logger";
import { transformToNumberFormat } from "../../services/numberConverterService";

const LoanDiagram = ({
						 darlehensbetrag,
						 maximalerAuszahlungsbetrag,
						 auszahlungsbetrag
					 }: LoanDiagramProps) => {
	const toPercentage = useCallback((amount?: number): number => {
		const maxAmount = darlehensbetrag || 0;
		const percentage = (amount || 0) / maxAmount;

		return parseFloat(percentage.toFixed(3));
	}, [darlehensbetrag]);

	const chartDelta = useMemo(() => darlehensbetrag - (maximalerAuszahlungsbetrag + auszahlungsbetrag), [darlehensbetrag, maximalerAuszahlungsbetrag, auszahlungsbetrag]);

	const chartValues = useMemo(
		(): ChartValues[] => {
			const values = [
				{
				label: "available",
				value: toPercentage(maximalerAuszahlungsbetrag)
                },
				{
                    label: "paid",
                    value: toPercentage(auszahlungsbetrag)
                }
			];

            if (chartDelta && toPercentage(chartDelta) > 0) {
				values.push({
					label: "delta",
					value: toPercentage(chartDelta)
				});
			}

			return values;
		},
		[
			auszahlungsbetrag,
			darlehensbetrag,
			maximalerAuszahlungsbetrag
		]
	);

	const hasChartValuesDelta = useMemo((): boolean => {
		return !!chartValues.find((chartValue) => chartValue.label === "delta" && chartValue.value);
	}, [chartValues]);

	useEffect(() => {
		Logger.debug("[LoanDiagram] ChartValues: ", chartValues);
	}, [chartValues]);

	return (
		<div className="loan-details">
			<PieChart chartValues={chartValues} />

			<ul className="loan-details__list">
				<li className="loan-details__item">
					<span className="text text--m text--bold">Darlehensbetrag</span>
					<span className="text text--m text--bold">
                        {transformToNumberFormat({ number: darlehensbetrag, digits: 2 })} €
                    </span>
				</li>

				<li className="loan-details__item loan-details__item-with-dot loan-details__item-with-dot--available">
					<span className="text text--m">Davon verfügbar</span>

					<span className="text text--m loan-details__number">
                        {transformToNumberFormat({ number: maximalerAuszahlungsbetrag, digits: 2 })} €
                    </span>
				</li>

				<li className="loan-details__item loan-details__item-with-dot loan-details__item-with-dot--paid">
					<span className="text text--m">Davon ausgezahlt</span>
					<span className="text text--m loan-details__number">
                        {transformToNumberFormat({ number: auszahlungsbetrag, digits: 2 })} €
                    </span>
				</li>

				{(hasChartValuesDelta) ? (
					<li className="loan-details__item loan-details__item-with-dot loan-details__item-with-dot--delta">
						<span className="text text--m">In Verarbeitung</span>
						<span className="text text--m loan-details__number">
							{transformToNumberFormat({ number: chartDelta, digits: 2 })} €
						</span>
					</li>
				) : null}
			</ul>
		</div>
	);
};
export default LoanDiagram;
