import React, {useMemo, useCallback} from 'react';
import DataTable, {TableColumn} from 'react-data-table-component';
import {Tooltip} from 'react-tippy';

import {Gewerk} from '../../../@types/data/Darlehensdaten';
import {ReactComponent as IconInfo} from '../../../assets/images/circle-info-light.svg';
import {ReactComponent as SortIcon} from '../../../assets/images/icon-arrow-down-red.svg';
import {ReactComponent as IconImage} from '../../../assets/images/icon-image.svg';
import {ReactComponent as IconInfoSquare} from '../../../assets/images/icon-info.svg';
import {GewerkeStatusKey} from '../../../enum/Gewerke';
import uniqueId from '../../../services/uniqueIdService';
import LoadingWheel from '../../Layout/LoadingWheel';
import EditableTableCell from '../partials/EditableTableCell';
import NoDataComponent from '../partials/NoDataComponent';
import ActionCell from './ActionCell';

interface TableComponentProps {
    data: Array<Gewerk>;
    findOriginalRow: (rowId: string) => Gewerk | null;
    isEditingMode: boolean;
    isLoading: boolean;
    showHintMessage: boolean;
    onStatusChange: (rowId: string, value: any) => void;
}

type StatusChoices = {offen?: string; 'teilweise fertiggestellt'?: string; fertiggestellt?: string};

export default function TableOfProofOfUseTable(props: TableComponentProps) {
    const {
        data,
        findOriginalRow,
        isEditingMode,
        isLoading,
        showHintMessage,
        onStatusChange,
    } = props;

    const getStatusChoices = useCallback((currentStatus: string): StatusChoices => {
        let availableChoices: StatusChoices = {
            [GewerkeStatusKey.OFFEN]: 'Offen',
            [GewerkeStatusKey.TEILWEISE_FERTIGGESTELLT]: 'Teilweise fertiggestellt',
            [GewerkeStatusKey.FERTIGGESTELLT]: 'Fertiggestellt',
        };

        switch (currentStatus) {
            case GewerkeStatusKey.OFFEN:
                break;
            case GewerkeStatusKey.TEILWEISE_FERTIGGESTELLT:
                delete availableChoices.offen;
                break;
            case GewerkeStatusKey.FERTIGGESTELLT:
            default:
                delete availableChoices.offen;
                delete availableChoices['teilweise fertiggestellt'];
        }

        return availableChoices;
    }, []);

    const columns = useMemo(
        (): TableColumn<Gewerk>[] => [
            {
                name: 'Gewerke',
                selector: (row: Gewerk) => row.bezeichnung,
                cell: (row: Gewerk) => (
                    <div className="table__cell-with-icon">
                        <span>{row.bezeichnung}</span>

                        {row.hinweis.length ? (
                            <Tooltip
                                title={row.hinweis}
                                position="top"
                                theme="light"
                                className="table__cell-with-icon-icon-container"
                            >
                                <IconInfo width="20" height="20" />
                            </Tooltip>
                        ) : null}
                    </div>
                ),
                sortable: true,
                wrap: true,
                grow: 2,
            },
            {
                name: 'Status',
                selector: (row: Gewerk) => row.baustatus,
                cell: (row: Gewerk) => (
                    <EditableTableCell
                        value={row.baustatus}
                        name={`${row.id}.baustatus`}
                        onChange={(value) => onStatusChange(row.id, value)}
                        isEditingMode={isEditingMode}
                        type="dropdown"
                        choices={getStatusChoices(findOriginalRow(row.id)?.baustatus ?? GewerkeStatusKey.OFFEN)}
                    />
                ),
                grow: 0.8,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Bilder',
                selector: (row: Gewerk) => row.dokumente.length,
                cell: (row: Gewerk) => (
                    <div className="table__cell-with-icon">
                        {row.dokumente.length ? (
                            <>
                                <IconImage width="18" height="18" />
                                <span className="text">{row.dokumente.length}</span>
                            </>
                        ) : null}
                    </div>
                ),
                grow: 0.4,
                sortable: true,
            },
            {
                name: '',
                cell: (row: Gewerk) => (
                    <ActionCell
                        key={uniqueId()}
                        row={row}
                        isEditingMode={isEditingMode}
                    />
                ),
                right: true,
                width: '40px',
                allowOverflow: true,
            },
        ],
        [data, isEditingMode],
    );

    return (
        <div className="table">
            <DataTable
                className="table"
                columns={columns}
                data={data}
                responsive
                noDataComponent={<NoDataComponent />}
                sortIcon={<SortIcon />}
                progressPending={isLoading}
                progressComponent={<LoadingWheel isLoading={isLoading} text="Bitte warten…" />}
            />

            {showHintMessage && !isLoading ? (
                <div className="hint-text hint-text--highlighted">
                    <IconInfoSquare className="hint-text__icon" />

                    Bitte denken Sie daran, uns einen bestätigten Baubericht zukommen zu lassen. Einreichen
                    können Sie diesen über die Funktion "Dokumente hochladen".
                </div>
            ) : null}
        </div>
    );
};
