import React, {useMemo} from 'react';
import {format, parseISO} from 'date-fns';
import DataTable, {TableColumn} from 'react-data-table-component';

import '../../../assets/scss/6_components/layout/_table.scss';

import {ReactComponent as SortIcon} from '../../../assets/images/icon-arrow-down-red.svg';

import {Zahlung} from '../../../@types/data/Darlehensdaten';
import useStore from '../../../hooks/useStore';
import {useModal} from '../../Modal/Modal';
import NoDataComponent from './../partials/NoDataComponent';
import {transformToNumberFormat} from '../../../services/numberConverterService';
import getStatusLabel, {Status} from '../../../services/getStatusLabel';
import {getZklUrl} from '../../../services/zkl';
import sortDocumentsFunction from './../partials/sortDocumentsFunction';

import ActionCell from './../TableOfPayouts/ActionCell';

const payoutsStatusDictionary: {[key: string]: string} = {
    angelegt: 'angelegt',
    geplant: 'geplant',
    storniert: 'storniert',
    ausgefuehrt: 'ausgeführt',
};

interface TableOfPayoutsProps {
    introText: string;
}

export default function TableOfPayouts(props: TableOfPayoutsProps) {
    const {introText} = props;
    const modalContext = useModal();
    const {activeDarlehen} = useStore();
    const rowData: Zahlung[] = activeDarlehen.zahlungen;

    const zklUrl = getZklUrl();

    const columns = useMemo(
        (): Array<TableColumn<Zahlung>> => [
            {
                id: 'date',
                name: 'Auftragsdatum',
                selector: (row: Zahlung) => row.auftragsdatum,
                format: (row: Zahlung) => format(parseISO(row.auftragsdatum), 'dd.MM.y'),
                sortable: true,
                wrap: true,
                grow: 1.5,
            },
            {
                name: 'Zahlungsempfänger',
                selector: (row: Zahlung) => row.empfaengerName,
                cell: (row) => row.empfaengerName,
                sortable: true,
                wrap: true,
                grow: 2,
            },
            {
                name: 'Betrag',
                selector: (row: Zahlung) => row.betrag,
                cell: (row) => `${transformToNumberFormat({number: Math.abs(row.betrag ?? 0), digits: 2})} €`,
                sortable: true,
                wrap: true,
            },
            {
                name: 'Status',
                cell: (row: Zahlung) => {
                    const status = payoutsStatusDictionary[row.frontendStatus];
                    return <span className={`tag tag--${getStatusLabel(status as Status)}`}>{status}</span>;
                },
                selector: (row: Zahlung) => row.frontendStatus ?? '-',
                sortable: true,
                width: '150px',
            },
            {
                name: '',
                cell: (row: Zahlung) => <ActionCell row={row} />,
                right: true,
                width: '80px',
                allowOverflow: true,
            },
        ],
        [modalContext],
    );

    return (
        <div>
            <p className="text text--s tabs-content__intro">{introText}</p>

            <div className="table">
                <DataTable
                    className="table"
                    columns={columns}
                    data={rowData}
                    responsive
                    defaultSortFieldId="date"
                    defaultSortAsc={false}
                    noDataComponent={<NoDataComponent />}
                    sortIcon={<SortIcon />}
                    sortFunction={sortDocumentsFunction}
                    progressPending={false}
                />
            </div>

            <p className="text">
                <b>Hinweis:</b> Hat sich Ihre Mobilfunknummer geändert?&nbsp;

                <a className="link" href={zklUrl}>Hier</a>
                &nbsp;können Sie diese schnell und einfach ändern.
            </p>
        </div>
    );
}
