import React, {useState, useEffect, ReactElement} from "react";

import { Logger } from "../services/Logger";
import useStore from "../hooks/useStore";
import useApiService from "../hooks/useApiService";
import useIsMounted from "../hooks/useIsMounted";
import NotificationList from "../components/Notifications/NotificationList";
import InternalLink from "../components/Routing/InternalLink";
import ROUTES from "../enum/Routes";
import {useParams} from "react-router-dom";

export default function NotificationsPage(): ReactElement {
    const {updateStore} = useStore();
    const {darlehenId} = useParams();

    const isMounted = useIsMounted();
    const {setStatusGelesen} = useApiService();

    const [isRequesting, setIsRequesting] = useState(false);

    useEffect((): void => {
        Logger.info("[NotificationsPage]");
    }, [Logger]);

    const setStatusRead = (ids: Array<string>): Promise<any> => {
        if (!darlehenId) return Promise.resolve();

        return new Promise((resolve) => {
            setIsRequesting(true);
            setStatusGelesen(darlehenId, ids)
                .then(({benachrichtigungen}): void => {
                    if (isMounted()) {
                        Logger.debug("[NotificationsPage] Get Benachrichtigungen Response", benachrichtigungen);
                        updateStore({benachrichtigungen});
                        setIsRequesting(false);
                        resolve(true);
                    }
                });
        });
    };

    return (
        <>
            <div className="page-breadcrumb">
                <InternalLink to={`${ROUTES.DASHBOARD}/${darlehenId}`} className="link link--with-chevron-reverse">
                    Zur Übersicht
                </InternalLink>
            </div>

            <h1 className="headline page-content__headline">Ihre Benachrichtigungen</h1>

            <NotificationList setStatusRead={setStatusRead} isRequesting={isRequesting} />
        </>
    );
}
