import React, {ReactNode, RefObject, useEffect} from 'react';
import {useMeasure} from 'react-use';

interface TabsContentInnerProps {
    children: ReactNode;
    setHeight: (value: string) => void;
}

export default function TabsContentInner(props: TabsContentInnerProps) {
    const {children, setHeight} = props;
    const [ref, {height}] = useMeasure();

    useEffect(() => {
        if (height > 300) {
            setHeight(height.toString());
        }
    }, [height]);

    return (
        <div className="tabs-content-inner-wrapper" ref={ref as unknown as RefObject<HTMLDivElement>}>
            {children}
        </div>
    );
}
