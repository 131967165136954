import React, {useMemo, useState} from 'react';
import HtmlParser from 'react-html-parser';
import uniqueId from '../../services/uniqueIdService';

export interface AccordionItemConfig {
    title: string;
    content: string;
}

interface AccordionProps {
    accordionConfig: Array<AccordionItemConfig>;
}

export default function Accordion(props: AccordionProps) {
    const {accordionConfig} = props;

    const [currentItemId, setCurrentItemId] = useState<string | null>(null);
    const accordionId = useMemo<string>((): string => uniqueId(), [accordionConfig]);

    return (
        <div className="accordion">
            {accordionConfig.map((item, index) => {
                const itemId = `${accordionId}-${index + 1}`;

                return (
                    <div key={itemId} className="accordion__item">
                        <h3 className="accordion__headline">
                            <button
                                type="button"
                                aria-expanded={itemId === currentItemId}
                                className="accordion__trigger"
                                aria-controls={itemId}
                                id={`accordion-${itemId}`}
                                onClick={() => {
                                    if (itemId === currentItemId) {
                                        setCurrentItemId(null);
                                        return;
                                    }

                                    setCurrentItemId(itemId);
                                }}
                            >
                                <span className="accordion__title">
                                    <span className={"accordion__title-text"}>{item.title}</span>
                                    <span className="accordion__icon"></span>
                                </span>
                            </button>
                        </h3>

                        <div
                            id={itemId}
                            role="region"
                            aria-labelledby={`accordion-${itemId}`}
                            className="accordion__panel"
                            hidden={itemId !== currentItemId}
                        >
                            <fieldset className="accordion__content">{HtmlParser(item.content)}</fieldset>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
