import React, {useEffect} from 'react';
import useCurrentRoute from '../hooks/useCurrentRoute';

export default function NotFoundPage() {
    const {prevRoute, removeRouteFromStore} = useCurrentRoute();

    useEffect(() => {
        if (prevRoute) {
            removeRouteFromStore().catch((error) => new Error(error));
        }
    }, [prevRoute, removeRouteFromStore]);

    return (
        <>
            <h1 className="headline headline--level-1 headline--centered">404</h1>
            <h2 className="headline headline--level-2 headline--centered">Seite wurde nicht gefunden!</h2>
        </>
    );
}
