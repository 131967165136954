import {SortOrder} from 'react-data-table-component';
import {Selector} from 'react-data-table-component/dist/src/DataTable/types';

export default function sortDocumentsFunction(rows: Array<any>, selector: Selector<any>, direction: SortOrder) {
    return rows.sort((rowA: any, rowB: any) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        let aField = selector(rowA);
        let bField = selector(rowB);

        if (typeof aField === 'string' && typeof bField === 'string') {
            aField = aField.toLowerCase();
            bField = bField.toLowerCase();
        }

        let comparison = 0;

        if (aField > bField) {
            comparison = 1;
        } else if (aField < bField) {
            comparison = -1;
        }

        return direction === 'desc' ? comparison * -1 : comparison;
    });
};
