import React, {useEffect, useMemo} from 'react';
import {Logger} from '../services/Logger';
import useStore from '../hooks/useStore';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../enum/Routes';
import useApiService from '../hooks/useApiService';
import LoadingContainer from '../containers/LoadingContainer';

export default function LegitimationPage() {
    const {activeDarlehen, isLegitimated, isAgbAccepted, updateActiveDarlehen} = useStore();
    const {setLegiErgebnisGelesen, isRequesting} = useApiService();
    const navigate = useNavigate();

    const darlehensnehmer = useMemo(() => activeDarlehen?.darlehensnehmer?.[0], [activeDarlehen]);

    useEffect(() => {
        Logger.info('[LegitimationPage]', darlehensnehmer);
    }, [Logger]);

    useEffect(() => {
        if (!activeDarlehen) return;

        if (isLegitimated) {
            navigate(ROUTES.DASHBOARD, {state: {preventRedirect: true}});
            return;
        }
    }, [activeDarlehen, isAgbAccepted, isLegitimated]);

    useEffect(() => {
        if (!activeDarlehen) {
            navigate(ROUTES.DASHBOARD, {state: {preventRedirect: true}});
        }
    }, [activeDarlehen]);

    const acceptLegitimation = async () => {
        await setLegiErgebnisGelesen();
        await updateActiveDarlehen({
            darlehensnehmer: activeDarlehen.darlehensnehmer?.map((darlehensnehmer) => ({
                ...darlehensnehmer,
                letztesLegiErgebnisgelesen: true,
            }) ?? []),
        });

        navigate(ROUTES.DASHBOARD, {state: {preventRedirect: true}});
    };

    if (isRequesting) return (
        <LoadingContainer
            isLoading={true}
            className="loading-container--standalone"
            contentClassName="loading-container__content--text-only"
        />
    );

    return (
        <div>
            {darlehensnehmer?.frontendLegitimationStatus === 'erfolgreich' ? (
                <p>
                    Vielen Dank! Die Legitimationsprüfung wurde erfolgreich abgeschlossen.
                </p>
            ) : null}

            {darlehensnehmer?.frontendLegitimationStatus === 'nichtErfolgreich' ? (
                <p>
                    Die Prüfung konnte nicht erfolgreich abgeschlossen werden.<br />
                    Wir melden uns in Kürze bei Ihnen.
                </p>
            ) : null}

            <button className="button button--primary" onClick={acceptLegitimation}>Weiter</button>
        </div>
    );
}
