import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import useBus, {dispatch} from 'use-bus';

import {
    Baufortschritte, Dokument,
    Gewerk,
    NestedFileQueue,
    ProofOfUseRequest,
} from '../../../@types/data/Darlehensdaten';
import Events from '../../../enum/Events';
import {Gewerke, GewerkeStatusKey} from '../../../enum/Gewerke';
import useApiService from '../../../hooks/useApiService';
import useFileUpload from '../../../hooks/useFileUpload';
import useStore from '../../../hooks/useStore';
import {updateBaufortschritte} from '../../../services/dokumentHelpers';
import {Logger} from '../../../services/Logger';
import {postError, postSuccess} from '../../../services/toastService';
import {useModal} from '../../Modal/Modal';

import '../../../assets/scss/6_components/layout/_table.scss';

import EditButton from './EditButton';
import TableOfProofOfUseTable from './Table';
import useOriginalData from './useOriginalData';

type PromiseQueue = Array<Promise<any>>;
type DokumentIdsMap = {[rowId: string]: Array<string>};

export interface TableOfProofOfUseProps {
    allowEditing: boolean;
    introText: string;
}

const TableOfProofOfUse = (props: TableOfProofOfUseProps) => {
    const {introText, allowEditing} = props;

    const {setModalOpen, setData, setModalType, setIsCloseable} = useModal();
    const {submitProofOfUse, uploadFiles} = useApiService();
    const {activeDarlehenId, activeDarlehen, updateActiveDarlehen} = useStore();
    const {submitFileQueue} = useFileUpload({uploadFiles});

    const [rowData, setRowData] = useState<Array<Gewerk>>([]);
    const [originalData, setOriginalData] = useState<Array<Gewerk>>([]);
    const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dirtyRows, setDirtyRows] = useState<Array<string>>([]);
    const [filesToUpload, setFilesToUpload] = useState<NestedFileQueue>({});
    const {findOriginalRow} = useOriginalData({originalData});

    const formMethods = useForm({
        shouldUnregister: true,
        defaultValues: {},
    });
    const {reset} = formMethods;

    useEffect(() => {
        reset(rowData.reduce(
            (collection: Record<string, Gewerk>, row: Gewerk) => ({...collection, [row.id]: row}), {}),
        );
    }, [rowData]);

    useEffect(() => {
        const data = activeDarlehen.baufortschritte?.[0]?.gewerke ?? [];
        setOriginalData(data);
        setRowData(data);
    }, [activeDarlehen.baufortschritte]);

    const showHintMessage = useMemo<boolean>(() => rowData.some((gewerk: Gewerk) => {
        return [Gewerke.ROHBAU, Gewerke.AUSSENANLAGEN].includes(gewerk.bezeichnung as never)
            && gewerk.baustatus === GewerkeStatusKey.FERTIGGESTELLT;
    }), [rowData]);

    const checkForRowsWithMissingDocuments = useCallback((): boolean => {
        const rowsWithMissingDocuments = rowData.reduce((collection: Array<string>, row: Gewerk) => {
            if (!row.dokumente.length && row.baustatus !== GewerkeStatusKey.OFFEN) {
                return [...collection, row.bezeichnung];
            }

            return collection;
        }, [] as Array<string>);

        if (rowsWithMissingDocuments.length) {
            setIsCloseable(false);
            setModalOpen(true);
            setData(rowsWithMissingDocuments);
            setModalType('missingDocuments');
            return true;
        }

        return false;
    }, [rowData]);

    const uploadFilesFromQueue = useCallback((): Promise<DokumentIdsMap> => {
        return new Promise((resolve, reject) => {
            const uploadAllFileQueues: PromiseQueue = Object
                .keys(filesToUpload)
                .reduce((queue: PromiseQueue, rowId: string) => ([
                    ...queue,
                    submitFileQueue(activeDarlehenId, filesToUpload[rowId], rowId),
                ]), []);

            Promise.all(uploadAllFileQueues)
                .then((results: Array<{documents: Array<string>, rowId: string}>) => {
                    const dokumentIdsByRows: DokumentIdsMap = results
                        .reduce((collection: DokumentIdsMap, result: {documents: Array<string>, rowId: string}) => ({
                            ...collection,
                            [result.rowId]: result.documents,
                        }), {});

                    resolve(dokumentIdsByRows);
                })
                .catch((errors) => reject(errors));
        });
    }, [filesToUpload, activeDarlehenId]);

    const transformRowForSubmit = (dokumentIdsByRows: DokumentIdsMap) => {
        const getDokumentenIds = (rowId: string): Array<string> => {
            if (dokumentIdsByRows.hasOwnProperty(rowId)) return dokumentIdsByRows[rowId];

            const existingRowData: Gewerk | undefined = rowData.find(({id}: Gewerk) => id === rowId);
            if (!existingRowData) return [];

            return existingRowData.dokumente.map(({id}) => id.toString());
        };

        return (row: Gewerk): ProofOfUseRequest => ({
            baustatus: row.baustatus,
            bezeichnung: row.bezeichnung,
            bilderDokumentenIds: getDokumentenIds(row.id),
        });
    };

    const handleFileUploadSuccess = (dokumentIdsByRows: DokumentIdsMap): void => {
        const changedGewerkeRequestData: Array<ProofOfUseRequest> = rowData.map(transformRowForSubmit(dokumentIdsByRows));

        submitProofOfUse(activeDarlehenId, changedGewerkeRequestData)
            .then((response: {baufortschritte: Baufortschritte}) => {
                dispatch(Events.SET_DARLEHENSDATEN);

                if (response.baufortschritte) {
                    updateActiveDarlehen(updateBaufortschritte(activeDarlehen, response.baufortschritte.gewerke));
                    postSuccess('Bautenstand erfolgreich abgeschickt');
                }
            })
            .catch((errorMessage) => postError(errorMessage))
            .finally(() => {
                setIsLoading(false);
                setIsEditingMode(false);
            });
    };

    const saveEdit = (): void => {
        if (dirtyRows.length === 0) {
            setIsEditingMode(false);
            return;
        }

        if (checkForRowsWithMissingDocuments()) return;
        if (!Object.values(filesToUpload).length) return;

        setIsLoading(true);

        uploadFilesFromQueue()
            .then(handleFileUploadSuccess)
            .catch((errorMessage) => {
                postError(errorMessage);
                setIsLoading(false);
            });
    };

    const updateDirtyRows = (rowId: string, isDirty: boolean): void => {
        if (isDirty) {
            setDirtyRows([...dirtyRows, rowId]);
            return;
        }

        setDirtyRows(dirtyRows.filter((id: string) => id !== rowId));
    };

    const updateRow = (rowId: string, newValues: Record<keyof Gewerk, any>): void => {
        setRowData(rowData.map((row: Gewerk): Gewerk => {
            if (row.id.toString() === rowId.toString()) return {...row, ...newValues};
            return row;
        }));
    };

    const onStatusChange = useCallback((rowId: string, baustatus: string): void => {
        let dokumente: Array<Dokument | File> = [];

        const originalRow = findOriginalRow(rowId);
        if (!originalRow) return;

        if (baustatus === originalRow.baustatus) {
            dokumente = originalRow.dokumente;
        }

        updateRow(rowId, {baustatus, dokumente});
        updateDirtyRows(rowId, baustatus !== originalRow.baustatus);
    }, [rowData, rowData]);

    useBus(
        Events.FILES_LIST_CHANGED,
        ({rowId, fileQueue}) => {
            Logger.debug('[Events.FILES_LIST_CHANGED] fileQueue', fileQueue);

            const files: Array<File> = Object.values(fileQueue);

            updateRow(rowId, {dokumente: files});
            setFilesToUpload({...filesToUpload, [rowId]: fileQueue});

            updateDirtyRows(rowId, files.length > 0);
        },
        [rowData, filesToUpload],
    );

    if (!rowData) return null;

    return (
        <FormProvider {...formMethods}>
            <p className="text text--s tabs-content__intro">{introText}</p>

            {allowEditing ? (
                <EditButton
                    isDirty={dirtyRows.length > 0}
                    isLoading={isLoading}
                    isEditingMode={isEditingMode}
                    onSubmit={() => saveEdit()}
                    onEdit={() => setIsEditingMode(true)}
                    onCancel={() => setIsEditingMode(false)}
                />
            ) : null}

            <TableOfProofOfUseTable
                data={rowData}
                findOriginalRow={findOriginalRow}
                showHintMessage={showHintMessage}
                isEditingMode={isEditingMode}
                isLoading={isLoading}
                onStatusChange={onStatusChange}
            />
        </FormProvider>
    );
};

export default TableOfProofOfUse;
