import React from 'react';
import InternalLink from '../components/Routing/InternalLink';
import ROUTES from '../enum/Routes';
import Accordion from '../components/Layout/Accordion';
import {
    documentUploadsConfig,
    miscConfig,
    payoutRequirementsConfig,
    proofOfUseConfig,
    billsConfig,
    payoutsConfig,
} from '../config/faq';

export default function Faq() {
    return (
        <>
            <div className="page-breadcrumb">
                <InternalLink to={`${ROUTES.DASHBOARD}`} className="link link--with-chevron-reverse">
                    Zurück
                </InternalLink>
            </div>

            <h1 className="headline page-content__headline">Fragen und Antworten zum Auszahlungsportal</h1>

            <h2>Dokumente hochladen</h2>
            <Accordion accordionConfig={documentUploadsConfig} />

            <h2>Sonstiges</h2>
            <Accordion accordionConfig={miscConfig} />

            <h2>Auszahlungsvoraussetzungen</h2>
            <Accordion accordionConfig={payoutRequirementsConfig} />

            <h2>Bautenstandsbericht</h2>
            <Accordion accordionConfig={proofOfUseConfig} />

            <h2>Rechnungen</h2>
            <Accordion accordionConfig={billsConfig} />

            <h2>Auszahlungen</h2>
            <Accordion accordionConfig={payoutsConfig} />
        </>
    );
}
