import './assets/scss/app.scss';

import React, {useEffect} from 'react';
import {ReactComponent as XbLogo} from './assets/images/xb-logo.svg';
import {ErrorBoundary} from '@sentry/react';
import {BrowserRouter as Router} from 'react-router-dom';
import {StoreProvider} from './store';
import IndexContainer from './containers/IndexContainer';
import actions from './store/actions';
import reducers from './store/reducers';
import useAuthenticate from './hooks/useAuthenticate';
import ensureBasename from './services/ensureBasename';
import {Modal, ModalContextProvider} from './components/Modal/Modal';
import PageHeader from './components/Layout/PageHeader';
import {SessionContextProvider} from './services/SessionContextProvider';
import {Logger} from './services/Logger';
import useInitialState from './hooks/useInitialState';

import 'react-datepicker/dist/react-datepicker.min.css';

window.React = React;

function App() {
    const {REACT_APP_BASE_PATH, REACT_APP_LOGGER_ACTIVE, REACT_APP_LOGGER_LEVEL} = process.env;
    const {xbLogin, handleAuthCookieChange} = useAuthenticate();
    const initialState = useInitialState();

    useEffect(() => {
        if (REACT_APP_LOGGER_ACTIVE === 'true') {
            Logger.logger = console;
            Logger.level = Logger[(REACT_APP_LOGGER_LEVEL as keyof Logger) || 'NONE'];
        }
    }, []);

    ensureBasename(REACT_APP_BASE_PATH);

    return (
        <ErrorBoundary>
            <StoreProvider actions={actions} reducers={reducers} initialState={initialState}>
                <Router basename={REACT_APP_BASE_PATH}>
                    <SessionContextProvider>
                        <div className="page-wrapper">
                            <PageHeader />

                            <section className="page-inner">
                                <ModalContextProvider>
                                    <div className="page-content">
                                        <Modal />
                                        <IndexContainer onAuthCookieChange={handleAuthCookieChange} />
                                    </div>
                                </ModalContextProvider>

                                {xbLogin ? (
                                    <XbLogo
                                        style={{
                                            position: 'absolute',
                                            bottom: '10px',
                                            right: '10px',
                                            cursor: 'pointer',
                                        }}
                                        width={240}
                                        onClick={handleAuthCookieChange}
                                    />
                                ) : null}
                            </section>
                        </div>
                    </SessionContextProvider>
                </Router>
            </StoreProvider>
        </ErrorBoundary>
    );
}

export default App;
