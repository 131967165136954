import React, {createContext, useContext, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

import '../../assets/scss/6_components/layout/_modal.scss';

import {ModalProps, StackedModalsProps} from '../../@types/dashboard';
import ViewDocumentsList from './ModalContent/ViewDocumentsList';
import EditDocument from './ModalContent/EditDocument';
import UploadDocument from './ModalContent/UploadDocument';
import SuccessfulUpload from './ModalContent/SuccessfulUpload';
import RemoveDocument from './ModalContent/RemoveDocument';
import UploadProofOfUse from './ModalContent/UploadProofOfUse';
import uniqueId from '../../services/uniqueIdService';
import OpenTextarea from './ModalContent/OpenTextarea';
import RemoveTableRow from './ModalContent/RemoveTableRow';
import HintUserLegitimation from './ModalContent/HintUserLegitimation';
import TanVerification from './ModalContent/TanVerification';
import MissingDocuments from './ModalContent/MissingDocuments';

const initialModalContext: ModalProps = {
    data: {},
    isCloseable: true,
    modalOpen: false,
    modalType: '',
    setIsCloseable: () => {},
    setModalOpen: () => {},
    setModalType: () => {},
    setData: () => {},
    stackedModals: {},
    setStackedModals: () => {},
};

export const ModalContext = createContext<ModalProps>(initialModalContext);

const modalContentDictionary: {[key: string]: React.ReactNode} = {
    viewDocumentsList: <ViewDocumentsList key={uniqueId()} />,
    editDocument: <EditDocument key={uniqueId()} />,
    uploadDocument: <UploadDocument key={uniqueId()} />,
    hintUserLegitimation: <HintUserLegitimation />,
    removeDocument: <RemoveDocument key={uniqueId()} />,
    successfulUpload: <SuccessfulUpload key={uniqueId()} />,
    missingDocuments: <MissingDocuments key={uniqueId()} />,
    uploadProofOfUse: <UploadProofOfUse key={uniqueId()} />,
    openTextarea: <OpenTextarea key={uniqueId()} />,
    removeTableRows: <RemoveTableRow key={uniqueId()} />,
    tanVerification: <TanVerification key={uniqueId()} />,
};

export const Modal = () => {
    const modalRoot = document.getElementById('modal');
    const modalContext = useModal();

    const {
        stackedModals,
        modalOpen,
        isCloseable,
        setStackedModals,
        setData,
        setModalType,
        setModalOpen,
        modalType,
    } = modalContext;

    const lockBody = (): void => {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
    };

    const unlockBody = (): void => {
        document.body.style.overflow = 'initial';
        document.documentElement.style.overflow = 'initial';
    };

    /**
     * Fügt aktive Instanz zu Modal Stack hinzu
     */
    useEffect(() => {
        const modalInstance: {[key: string]: any} = {};

        if (!modalType.length || modalType in stackedModals) {
            return;
        }

        modalInstance[modalType] = {
            ...modalContext,
        };

        setStackedModals({...stackedModals, ...modalInstance});
    }, [stackedModals, modalContext]);

    /**
     * Entfernt aktuell geschlossenes Modal von Stack und öffnet, wenn möglich, das zuletzt geöffnete.
     */
    useEffect(() => {
        const stackedModalsKeys = Object.keys(stackedModals);

        if (modalOpen) {
            lockBody();
        } else {
            unlockBody();
        }

        if (!modalOpen && stackedModalsKeys.length && modalType in stackedModals) {
            const stackedModalKey = modalType as keyof typeof stackedModals;
            const stackedModalKeyIndex = stackedModalsKeys.findIndex(
                (modalKey: string) => modalKey === stackedModalKey
            );

            delete stackedModals[stackedModalKey];
            stackedModalsKeys.splice(stackedModalKeyIndex, 1);
            setStackedModals(stackedModals);

            if (!Object.keys(stackedModals).length) {
                return;
            }

            const previousModalType: string = stackedModalsKeys[stackedModalsKeys.length - 1] || '';
            const previousModalContext: ModalProps =
                stackedModals[previousModalType as keyof typeof stackedModals];

            setModalType(previousModalType);
            setData(previousModalContext.data);
            setModalOpen(true);
        }
    }, [modalOpen, stackedModals, modalType]);

    if (!modalOpen) return null;

    return createPortal(
        <div className="modal-wrapper">
            <div
                className="modal-background"
                onClick={() => {
                    if (isCloseable) {
                        setData({});
                        setModalOpen(false);
                    }
                }}
            />
            <div className="modal" id="modal-content">
                {modalContentDictionary[modalType]}
            </div>
        </div>,
        modalRoot as HTMLDivElement
    );
};

interface ModalContextProviderProps {
    children: React.ReactNode;
}

export const ModalContextProvider = ({children}: ModalContextProviderProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalType, setModalType] = useState<string>('');
    const [stackedModals, setStackedModals] = useState<StackedModalsProps | {}>({});
    const [data, setData] = useState<any>();
    const [isCloseable, setIsCloseable] = useState<boolean>(true);

    return (
        <>
            <ModalContext.Provider
                value={{
                    modalOpen,
                    setModalOpen,
                    modalType,
                    setModalType,
                    data,
                    setData,
                    isCloseable,
                    setIsCloseable,
                    stackedModals,
                    setStackedModals,
                }}
            >
                {children}
            </ModalContext.Provider>
        </>
    );
};

export const useModal = (): ModalProps => useContext(ModalContext);
