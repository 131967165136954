import React, {useMemo, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';

import ROUTES from '../enum/Routes';
import AgbPage from '../pages/AgbPage';
import AuthorizePage from '../pages/AuthorizePage';
import RefreshPage from '../pages/RefreshPage';
import DarlehenContainer from './DarlehenContainer';
import DarlehenPage from '../pages/DarlehenPage';
import DarlehenListPage from '../pages/DarlehenListPage';
import DashboardContainer from './DashboardContainer';
import ErrorPage from '../pages/ErrorPage';
import LandingPage from '../pages/LandingPage';
import LogoutPage from '../pages/LogoutPage';
import NotFoundPage from '../pages/NotFoundPage';
import ProtectedRoute from '../components/Routing/ProtectedRoute';
import NotificationsPage from '../pages/NotificationsPage';
import Faq from '../pages/Faq';
import LegitimationPage from "../pages/LegitimationPage";

import useAuthSwitch from '../hooks/useAuthSwitch';
import useCurrentRoute from '../hooks/useCurrentRoute';

import {AuthSwitchProps} from '../@types/authentication';
import {useSessionContext} from '../services/SessionContextProvider';
import AuszahlungsauftragContainer from './AuszahlungsauftragContainer';
import useAuthenticate from '../hooks/useAuthenticate';
import useQuery from '../hooks/useQuery';

export default function IndexContainer(onAuthCookieChange: AuthSwitchProps) {
    const navigate = useNavigate();
    const {isAuthenticated} = useAuthenticate();
    const {prevRoute} = useCurrentRoute();
    const [sessionContext, updateSessionContext] = useSessionContext();
    const query = useQuery();

    const setRedirectPath = (path: string) => {
        updateSessionContext({...sessionContext, redirectPath: path});
    };

    const defaultProtectedRouteProps = useMemo(
        () => ({
            isAuthenticated,
            authenticationPath: ROUTES.AUTHORIZE,
            redirectPath: prevRoute || sessionContext.redirectPath,
            setRedirectPath,
        }),
        [isAuthenticated]
    );

    useEffect(() => {
        const hasError = query.get('error');

        if (hasError) {
            navigate(ROUTES.ERROR);
        }
    }, [query]);

    useAuthSwitch(onAuthCookieChange);

    return (
        <Routes>
            <Route index element={<LandingPage />} />
            <Route path={ROUTES.AUTHORIZE} element={<AuthorizePage />} />
            <Route path={ROUTES.LOGOUT} element={<LogoutPage />} />
            <Route path={ROUTES.REFRESH} element={<RefreshPage />} />
            <Route path={ROUTES.FAQ} element={<Faq />} />
            <Route element={<ProtectedRoute {...defaultProtectedRouteProps} />}>
                <Route path={ROUTES.ACCEPT_AGB} element={<AgbPage />} />
                <Route path={ROUTES.LEGITIMATE} element={<LegitimationPage />} />
                <Route path={ROUTES.DASHBOARD} element={<DashboardContainer />}>
                    <Route path={':darlehenId'} element={<DarlehenContainer />}>
                        <Route path={ROUTES.PAYOUT_ORDER} element={<AuszahlungsauftragContainer />} />
                        <Route path={ROUTES.NOTIFICATIONS} element={<NotificationsPage />} />
                        <Route index element={<DarlehenPage />} />
                    </Route>
                    <Route index element={<DarlehenListPage />} />
                </Route>
            </Route>
            <Route path={ROUTES.ERROR} element={<ErrorPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}
