import React from 'react';
import {format, parseISO} from "date-fns";

import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import UploadDocument from '../ModalContent/UploadDocument';
import {useModal} from '../Modal';

export const EditDocument = () => {
    const {data} = useModal();

    if (data?.upload) return <UploadDocument />;

    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <h2>{data?.requirements} erneut hochladen</h2>

                <p>hochgeladen am {format(parseISO(data?.date), "dd.MM.y")}</p>

                {data?.upload && <img src={data?.upload} alt={data?.requirements} />}

                <UploadDocument />
            </div>

            <ModalFooter buttons={[{type: 'abort'}]} />
        </>
    );
};

export default EditDocument;
