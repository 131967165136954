import React, {ReactElement, useEffect, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import placeholderImage from '../assets/images/error-page-image.png';

interface ErrorLocationState {
    message?: string;
}

const ErrorPage = (): ReactElement => {
    const navigate = useNavigate();
    const currentLocation = useLocation();
    const {state} = currentLocation;

    const errorLocationState = state as ErrorLocationState;

    const errorTitle = useMemo(
        () =>
            errorLocationState?.message ??
            'Hier ist etwas schief gelaufen! Versuchen Sie es zu einem späteren Zeitpunkt erneut.',
        [errorLocationState],
    );

    useEffect(() => {
        navigate(1);
    }, []);

    return (
        <div className="error-page">
            <img className="error-page__image" src={placeholderImage} alt="Platzhalter" />

            <div className="error-page__content-wrapper">
                <h1 className="error-page__headline">{errorTitle}</h1>
                <p className="error-page__content">
                    Sie können das Auszahlungsportal daher leider nicht nutzen. Das Fenster können Sie nun
                    schließen. Um das Kundenportal zu nutzen, klicken Sie bitte{' '}
                    <a href="https://www.wuestenrot.de/kundenportal" className="link">
                        hier
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default ErrorPage;
