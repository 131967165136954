import {dispatch} from 'use-bus';
import Events from '../enum/Events';
import {postError, postSuccess} from '../services/toastService';
import {Auszahlungsvoraussetzung, Dokument, FileQueue} from '../@types/data/Darlehensdaten';
import {UploadFileResponse, UploadFileResult} from '../@types/services/apiService';

type PromiseQueue = Array<Promise<string>>;

interface UseFileUploadProps {
    uploadFiles?: (files: Array<File | Dokument>, darlehenId: string, categoryId: string) => UploadFileResponse;
    onUploadSuccess?: (dokument: Dokument, auszahlungsvoraussetzung?: Auszahlungsvoraussetzung) => void;
}

/**
 * Handling von File uploads
 * @param {function} uploadFiles Callback zum Hochladen eines Dokuments. Muss eine Promise sein.
 * @param {function} onUploadFinish Hook Callback für Funktionen die nach einem Upload ausgeführt werden.
 */
export default function useFileUpload({uploadFiles, onUploadSuccess}: UseFileUploadProps) {
    const submitFiles = (darlehenId: string, categoryId: string, fileQueue: FileQueue) => {
        if (fileQueue && uploadFiles) {
            uploadFiles(Object.values(fileQueue), darlehenId, categoryId)
                .then((response: UploadFileResult) => {
                    const {dokument, auszahlungsvoraussetzung} = response;
                    onUploadSuccess?.(dokument as Dokument, auszahlungsvoraussetzung as Auszahlungsvoraussetzung);
                    postSuccess('Dateien wurden erfolgreich hochgeladen.');
                })
                .catch(() => postError('Beim Upload der Dateien ist ein Fehler aufgetreten'))
                .finally(() => {
                    dispatch({
                        type: Events.UPLOAD_FINISHED,
                        fileQueue,
                    });
                });
        }
    };

    const startFileUpload = (darlehenId: string, file: File | Dokument): Promise<string> => {
        return new Promise((resolve, reject) => {
            if (uploadFiles) {
                uploadFiles([file], darlehenId, '')
                    .then((response: UploadFileResult) => {
                        const {id} = response;
                        resolve(id ?? '');
                    })
                    .catch((errorResponse) => {
                        reject(errorResponse);
                    });
            }
        });
    };

    const submitFileQueue = (darlehenId: string, fileQueue: FileQueue, rowId: string): Promise<{ documents: Array<string>, rowId: string }> => {
        return new Promise((resolve, reject) => {
            const promiseQueue: PromiseQueue = Object.values(fileQueue).reduce(
                (queue: PromiseQueue, file: File | Dokument) => {
                    return [...queue, startFileUpload(darlehenId, file)];
                },
                [],
            );

            Promise.all(promiseQueue)
                .then((allResults) => {
                    postSuccess('Dateien wurden erfolgreich hochgeladen.');
                    resolve({documents: allResults, rowId});
                })
                .catch((errorMessage) => {
                    reject(errorMessage);
                });
        });
    };

    return {
        submitFiles,
        submitFileQueue,
    };
}
