const GewerkeStatusKey = Object.freeze({
	OFFEN: "offen",
	TEILWEISE_FERTIGGESTELLT: "teilweise fertiggestellt",
	FERTIGGESTELLT: "fertiggestellt"
});

const Gewerke = Object.freeze({
	ROHBAU: "Rohbau",
	AUSSENANLAGEN: "Außenanlagen"
});

export {
	Gewerke,
	GewerkeStatusKey
};
