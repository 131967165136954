import React, {useCallback, useEffect} from 'react';
import {Button} from '@crossbuilders/form-library/components';
import classNames from 'classnames';

import '../../assets/scss/6_components/layout/_tabs.scss';

import TabsContent from './TabsContent';
import uniqueId from '../../services/uniqueIdService';
import {Logger} from '../../services/Logger';
import useStore from "../../hooks/useStore";

type TabsProps = {
    onTabChange(tabIndex: number): void;
    isMobile: boolean;
    isMobileDropdownOpen: boolean;
    setMobileDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Tabs: React.FC<TabsProps> = ({
    onTabChange,
    isMobile,
    isMobileDropdownOpen,
    setMobileDropdownOpen,
}) => {
    const { currentTabIndex, tabs, currentTab } = useStore();

    const handleClick = useCallback(
        (event: MouseEvent, tabIndex: number) => {
            event.preventDefault();

            if (isMobile) {
                if (tabIndex === currentTabIndex) {
                    setMobileDropdownOpen(!isMobileDropdownOpen);
                } else {
                    setMobileDropdownOpen(true);
                }
            }

            onTabChange(tabIndex);
        },
        [currentTab, onTabChange, isMobile, setMobileDropdownOpen]
    );

    useEffect(() => {
        Logger.debug('[Tabs] Available Tabs', tabs.length);
    }, [tabs]);

    return (
        <nav className="tabs">
            {tabs.map((tab, index) => {
                const isCurrentTab = index === currentTabIndex;
                const openOnMobile = isMobile && isMobileDropdownOpen && isCurrentTab;

                return (
                    tab.tableComponent && (
                        <div className="tabs__wrapper" key={uniqueId()}>
                            <Button
                                name={tab.tabName}
                                onClick={(event: MouseEvent) => handleClick(event, index)}
                                type="button"
                                className={classNames({
                                    tabs__button: true,
                                    'is-active': isMobile ? openOnMobile : isCurrentTab,
                                })}
                            >
                                <span className="tabs__topic">{tab.tabName}</span>
                            </Button>

                            {openOnMobile ? <TabsContent /> : null}
                        </div>
                    )
                );
            })}
        </nav>
    );
};

export default Tabs;
