import React from 'react';
import {Button} from '@crossbuilders/form-library/components';

import {ReactComponent as IconCheck} from '../../../assets/images/check-regular.svg';
import {ReactComponent as IconCancel} from '../../../assets/images/close-regular.svg';
import {ReactComponent as IconEdit} from '../../../assets/images/icon-edit.svg';

import LoadingWheel from '../../Layout/LoadingWheel';

interface EditButtonProps {
    isDirty: boolean;
    isLoading: boolean;
    isEditingMode: boolean;
    onSubmit: () => void;
    onEdit: () => void;
    onCancel: () => void;
}

export default function EditButton(props: EditButtonProps) {
    const {
        isDirty,
        isLoading,
        isEditingMode,
        onSubmit,
        onEdit,
        onCancel,
    } = props;

    const IconSubmit = () => isLoading ? (
        <LoadingWheel isLoading color="white" />
    ) : (
        <IconCheck width="20" height="20" fill="#fff" />
    );

    return <div className="tabs-content__actions">
        {isEditingMode ? (
            isDirty ? (
                <Button onClick={onSubmit} type="button" className="button button--small button--primary">
                    <IconSubmit />
                    <span style={{marginLeft: '10px'}}>Bautenstandsbericht abschicken</span>
                </Button>
            ) : (
                <Button onClick={onCancel} type="button" className="button button--small button--ghost">
                    <IconCancel width="20" height="20" fill="#f84914" />
                    <span style={{marginLeft: '10px'}}>Abbrechen</span>
                </Button>
            )
        ) : (
            <Button onClick={onEdit} type="button" className="button button--small button--ghost">
                <IconEdit width="20" height="20" fill="#f84914" />
                <span style={{marginLeft: '10px'}}>Bautenstandsbericht bearbeiten</span>
            </Button>
        )}
    </div>;
};
