import holidaysDe from '../config/national-holidays';
import {format} from 'date-fns';

export function formatDate(_date: string | Date, dateFormat = 'yyyy-MM-dd') {
    return format(typeof _date === 'string' ? new Date(_date) : _date, dateFormat);
}

export function isHoliday(_date: string | Date) {
    const strDate = formatDate(_date);
    return holidaysDe.some(({date}) => strDate === date);
}
