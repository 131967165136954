import React, {useState, useMemo, useEffect} from 'react';
import {useSearchParams} from "react-router-dom";

import '../../assets/scss/6_components/layout/_tabs-content.scss';

import Tabs from './Tabs';
import TabsContent from './TabsContent';
import { Tab } from '../../@types/dashboard';
import useViewport from '../../hooks/useViewport';
import useStore from "../../hooks/useStore";

const TabsContainer = ({tabs}: {tabs: Tab[]}) => {
    const {width} = useViewport();
    const isMobile = useMemo(() => width < 768, [width]);
    const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
    const { setCurrentTabIndex, setTabs } = useStore();
    const [searchParams] = useSearchParams();

    const onTabChange = (activeTabIndex: number) => {
        setCurrentTabIndex(activeTabIndex);
    };

    useEffect(() => {
        setTabs(tabs);
    }, [tabs]);

    useEffect(() => {
        if (searchParams.has('tab')) {
            switch (searchParams.get('tab')) {
                case 'rechnungen':
                    setCurrentTabIndex(tabs.findIndex((tab) => tab.tableComponent === 'bills'));
                    break;
                case 'auszahlungen':
                    setCurrentTabIndex(tabs.findIndex((tab) => tab.tableComponent === 'payouts'));
                    break;
                case 'azv':
                    setCurrentTabIndex(tabs.findIndex((tab) => tab.tableComponent === 'requirements'));
                    break;
            }
        }
    }, [searchParams]);


    return (
        <div className="tabs-wrapper">
            <Tabs
                onTabChange={onTabChange}
                isMobile={isMobile}
                setMobileDropdownOpen={setMobileDropdownOpen}
                isMobileDropdownOpen={isMobileDropdownOpen}
            />

            {!isMobile ? <TabsContent /> : null}
        </div>
    );
};

export default TabsContainer;
