import type {MouseEvent} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';

import '../../assets/scss/6_components/dnd-file-list/_dnd-file-list.scss';

import {ReactComponent as IconSort} from '../../assets/images/icon-sort.svg';
import {ReactComponent as IconPdf} from '../../assets/images/icon-adobe-pdf.svg';
import {ReactComponent as IconTrash} from '../../assets/images/icon-trash.svg';
import {fileSizeForHumans} from '../../services/validatorService';

type FileQueue = {[key: string]: File};

type DraggableFileListProps = {
    fileQueue: FileQueue;
    setFileQueue: (queue: FileQueue) => void;
};

interface SortableFile {
    id: string,
    file: any,
    url: string,
    size?: number
}

const DragHandler = (props: any) => <div {...props} className="dnd-file-list-item__handle"><IconSort
    className="dnd-file-list-item__icon" /></div>;

const DraggableFileList = (props: DraggableFileListProps) => {
    const {fileQueue, setFileQueue} = props;
    const [sortableFiles, setSortableFiles] = useState<Array<SortableFile>>([]);

    useEffect(() => {
        if (!fileQueue || Object.keys(fileQueue).length === 0) {
            setSortableFiles([]);
            return;
        }

        setSortableFiles(Object.keys(fileQueue).map((key) => ({
            id: key,
            file: fileQueue[key],
            url: URL.createObjectURL(fileQueue[key]),
        })));
    }, [fileQueue]);


    const deleteFile = ({currentTarget}: MouseEvent<HTMLButtonElement>) => {
        const {id} = currentTarget.dataset;
        const newFiles: Array<SortableFile> = sortableFiles.filter((sortableFile) => sortableFile.id !== id);

        setFileQueue(
            newFiles.reduce((accumulator: FileQueue, sortableFile: any): FileQueue => {
                if (!sortableFile) return accumulator;

                return {...accumulator, [sortableFile.id]: sortableFile.file};
            }, {}),
        );
    };

    const getFileSize = useCallback((file: SortableFile | File): string => {
        if (file?.size) return fileSizeForHumans(file.size);
        return '';
    }, [sortableFiles]);

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        const items: Array<SortableFile> = Array.from(sortableFiles);
        const [removed] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, removed);

        setSortableFiles(items);
    };

    return (
        <div className="dnd-file-list">
            <p className="dnd-file-list__info">Sie können die Seiten mit Drag&Drop sortieren</p>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {sortableFiles.map(({file, id, url}, index) => {
                                return (<Draggable key={id} draggableId={id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="dnd-file-list-item"
                                        >
                                            <DragHandler {...provided.dragHandleProps} />

                                            <span className="dnd-file-list-item__text">
                                                {`${file.path} (${getFileSize(file)})`}
                                            </span>

                                            {/\.pdf$/.test(file.path) ? (
                                                <IconPdf
                                                    className="dnd-file-list-item__image dnd-file-list-item__image--pdf" />
                                            ) : (
                                                <img
                                                    alt="Draggable File List Item - Preview"
                                                    className="dnd-file-list-item__image"
                                                    src={url}
                                                    width={60}
                                                    height={60}
                                                />
                                            )}
                                            <button
                                                type="button"
                                                onClick={deleteFile}
                                                data-id={id}
                                                className="button button--icon button--small"
                                            >
                                                <IconTrash />
                                            </button>
                                        </div>
                                    )}
                                </Draggable>);
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default DraggableFileList;
