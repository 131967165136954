interface PendingAction {
    action: string;
    status: string;
}

interface Tab {
    tableComponent: string;
    tabName: string;
    introText: string;
    showTab?: string;
    pendingAction?: PendingAction;
}

interface TabsConfig {
    tabs: Array<Tab>;
}

const tabsConfig: TabsConfig = {
    tabs: [
        {
            tableComponent: 'requirements',
            tabName: 'Voraussetzungen',
            introText:
                'Folgende Dokumente und Nachweise sind Grundvoraussetzungen für die Dahrlehensauszahlung.',
        },
        {
            tableComponent: 'bills',
            tabName: 'Rechnungen',
            showTab: 'frontendFunktionserlaubnisKundeRechnungsansicht',
            introText: 'Hier finden Sie Ihre über das Auszahlungsportal eingereichten Rechnungen.',
        },
        {
            tableComponent: 'proofOfUse',
            tabName: 'Bautenstandsbericht',
            showTab: 'frontendFunktionserlaubnisKundeBautenstand',
            introText: 'Angaben zum Fortschritt der Bauarbeiten und Lichtbilder bei Neubauvorhaben.',
        },
        {
            tabName: 'Auszahlungen',
            tableComponent: 'payouts',
            introText:
                'Die Prüfung und Ausführung von Zahlungsaufträgen kann bis zu fünf Arbeitstage in Anspruch nehmen.',
        },
    ],
};

export default tabsConfig;
