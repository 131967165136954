import React from "react";

import { ChartValues, PieChartGeometry, PieChartProps } from "../../@types/dashboard";

const PieChart: React.FC<PieChartProps> = ({ chartValues }) => {
	const radius = 50;
	const quadrantEdge = 50;

	const getPieChartValues = (radius: number, chartValues: ChartValues[]) => {
		let offsetAngle = 90;
		const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180);

		return chartValues.map(({ label, value }) => {
			const angle = value * 360;

			const cosFactor = angle < 90 && angle > 270 ? -1 : 1;
			const sinFactor = -1;

			const xCoord = Math.cos(degreesToRadians(angle)) * radius * cosFactor;
			let yCoord = Math.sin(degreesToRadians(angle)) * radius * sinFactor;

			if (yCoord < 0.1 && yCoord > 0) {
				yCoord = 0.1;
			}

			const currentOffsetAngle = offsetAngle;
			offsetAngle += angle;

			return {
				offsetAngle: currentOffsetAngle,
				xCoord,
				yCoord,
				angle,
				label,
				value
			};
		});
	};

	const pieChartValues = getPieChartValues(radius, chartValues);

	const getPathValues = (partial: PieChartGeometry) => {
		let d = `M${quadrantEdge}, ${quadrantEdge} h${radius} a${radius},`;
		d += ` ${radius} 0 ${partial.angle > 180 ? 1 : 0} 0 ${partial.xCoord - radius},`;
		d += ` ${partial.yCoord} z`;

		return d;
	};

	const colorDictionary: { [key: string]: string } = {
		delta: "#E3E3E3",
		available: "#94C23C",
		paid: "#65882A"
	};

	return (
		<div className="pie-chart">
			<svg
				width={radius * 2}
				height={radius * 2}
				data-radius={radius}
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle fill="#E3E3E3" cx={quadrantEdge} cy={quadrantEdge} r={radius} />
				{pieChartValues.map((partial: PieChartGeometry) => (
					<path
						d={getPathValues(partial)}
						key={partial.label}
						fill={colorDictionary[partial.label] || ""}
						transform={`rotate(${partial.offsetAngle * -1}, ${quadrantEdge}, ${quadrantEdge})`}
						strokeLinejoin="round"
					/>
				))}
				<circle fill="#fff" cx={quadrantEdge} cy={quadrantEdge} r="32" />
			</svg>
		</div>
	);
};

export default PieChart;
