import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../enum/Routes';
import useStore from '../hooks/useStore';
import {Logger} from '../services/Logger';
import {transformToDarlehensvertragNumber, transformToNumberFormat} from '../services/numberConverterService';

const DarlehenListPage = () => {
    const {activeDarlehenId, darlehensnehmer} = useStore();
    const navigate = useNavigate();

    const handlePrepareDarlehensdaten = (darlehenId: string): void => {
        navigate(`${ROUTES.DASHBOARD}/${darlehenId}`, {
            state: {preventRedirect: true},
        });
    };

    useEffect(() => {
        if (darlehensnehmer.darlehen && darlehensnehmer.darlehen.length === 1) {
            navigate(`${ROUTES.DASHBOARD}/${darlehensnehmer.darlehen[0].id}`, {
                state: {preventRedirect: true},
            });
        }
    }, [darlehensnehmer]);

    useEffect(() => {
        Logger.info('[DarlehenListPage]');
        Logger.debug('[DarlehenListPage] activeDarlehenId', activeDarlehenId);
    }, [Logger, activeDarlehenId]);

    return (
        <>
            <h1 className="headline headline--level-1 headline--centered">
                Willkommen in Ihrem Auszahlungsportal
            </h1>

            <p className="text--centered">Bitte wählen Sie Ihren Darlehensvertrag aus</p>

            <ul className="link-list link-list--width-medium link-list--centered">
                {darlehensnehmer.darlehen.map((darlehen) => (
                    <li
                        key={darlehen.id}
                        className="link-list__item"
                        onClick={handlePrepareDarlehensdaten.bind(null, darlehen.id)}
                    >
                        <span>
                            {`Darlehensvertrag: ${
                                transformToDarlehensvertragNumber(darlehen.externeId)
                            } - Darlehensbetrag: ${transformToNumberFormat({
                                number: darlehen.darlehensbetrag,
                                digits: 2,
                            })} €`}
                        </span>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default DarlehenListPage;
