import React from 'react';
import classNames from 'classnames';

import '../../assets/scss/6_components/general/_loading-container.scss';
import '../../assets/scss/6_components/general/_spinner.scss';

interface ILoadingWheel {
    text?: string;
    isLoading: boolean;
    color?: string;
}

const LoadingWheel: React.FC<ILoadingWheel> = ({text, isLoading, color = 'red'}) => {
    return (
        <>
            <div
                className={classNames({
                    'loading-container': true,
                    'is-loading': isLoading,
                })}
            >
                <div className={classNames({
                    'loading-container__spinner spinner': true,
                    [`spinner--${color}`]: color.length,
                })}>
                    <div className="spinner__element" />
                </div>

                {text ? (
                    <div className="loading-container__content loading-container__content--text-only">{text}</div>
                ) : null}
            </div>
        </>
    );
};

export default LoadingWheel;
