import React, {useEffect, useMemo, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';

import {Auszahlungsvoraussetzung, Dokument} from '../../../@types/data/Darlehensdaten';
import useApiService from '../../../hooks/useApiService';
import useStore from '../../../hooks/useStore';
import {replaceMultipleDocumentsInDarlehen} from '../../../services/dokumentHelpers';
import {transformToNumberFormat} from '../../../services/numberConverterService';

import EditButton from './EditButton';
import Table from './Table';

export interface TableOfBillsProps {
    allowEditing: boolean;
    introText: string;
}

export default function TableOfBills(props: TableOfBillsProps) {
    const {allowEditing, introText} = props;
    const {updateDokumente} = useApiService();
    const {activeDarlehenId, updateActiveDarlehen, activeDarlehen} = useStore();
    const {rechnungssumme} = activeDarlehen;

    const [isEditingMode, setIsEditingMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [rowData, setRowData] = useState<Array<Dokument>>([]);

    const formMethods = useForm({
        shouldUnregister: true,
        defaultValues: {},
    });
    const {reset} = formMethods;

    useEffect(() => {
        reset(rowData.reduce((collection: Record<string, Dokument>, row: Dokument) => {
            return {...collection, [row.id]: row};
        }, {}));
    }, [rowData]);

    useEffect(() => {
        if (!activeDarlehen) return;

        setRowData(activeDarlehen
            .auszahlungsvoraussetzungen
            .filter((item: Auszahlungsvoraussetzung) => item.kategorieRechnung)
            .reduce((bills: Array<Dokument>, currentRow: Auszahlungsvoraussetzung) => ([
                ...bills,
                ...currentRow.dokumente,
            ]), []));
    }, [activeDarlehen.auszahlungsvoraussetzungen]);

    const saveEdit = async () => {
        if (!isDirty) {
            setIsEditingMode(false);
            return;
        }

        setIsLoading(true);

        const response = await updateDokumente(activeDarlehenId, rowData);

        setIsEditingMode(false);
        setIsLoading(false);

        if (response.dokumente) {
            updateActiveDarlehen({
                ...replaceMultipleDocumentsInDarlehen(activeDarlehen, response.dokumente),
                rechnungssumme: response.rechnungssumme,
            });
        }
    };

    const handleOnChange = (rowId: string, column: string) => {
        return (value: string | number): void => {
            setRowData(rowData.map((row: Dokument) => {
                if (row.id === rowId) {
                    return {
                        ...row,
                        rechnungGesamtBrutto: row.rechnungGesamtBrutto ?? 0,
                        rechnungsstellerName: row.rechnungsstellerName ?? '',
                        [column]: value,
                    };
                }

                return row;
            }));

            setIsDirty(true);
        };
    };

    const rechnungsSummeValue = useMemo<string>(() => {
        return `${transformToNumberFormat({number: rechnungssumme ?? 0, digits: 2})} €`;
    }, [rechnungssumme]);

    if (!rowData) return null;

    return (
        <FormProvider {...formMethods}>
            <p className="text text--s tabs-content__intro">{introText}</p>

            <p className="text text--m tabs-content__intro">
                <strong>
                    <span>Summe eingereichter Rechnungen: </span>
                    {!isLoading ? rechnungsSummeValue : null}
                </strong>
            </p>

            {allowEditing && rowData.length > 0 ? (
                <EditButton
                    isLoading={isLoading}
                    isEditingMode={isEditingMode}
                    onEdit={() => setIsEditingMode(true)}
                    onSubmit={() => saveEdit()}
                />
            ) : null}

            <Table
                data={rowData}
                isEditingMode={isEditingMode}
                isLoading={isLoading}
                onChange={handleOnChange}
            />
        </FormProvider>
    );
}
