import {useState, useMemo, useEffect, useCallback} from 'react';
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {useNavigate} from 'react-router-dom';

import {
    ApiServiceResponse,
    AuthenticateTanForAuszahlungResponse,
    BenachrichtigungResponse,
    DarlehensdatenResponse,
    DarlehensnehmerResponse,
    DokumentResponse,
    FetchOcrByDocumentResponse,
    IbanValidierungsResponse,
    NectResponse,
    SubmitAuszahlungsauftragResponse,
    SubmitProofOfUseResponse,
    UpdateDokumentResponse,
    UploadFileResponse,
    UploadFileToOcrResponse,
} from '../@types/services/apiService';
import {
    Dokument,
    Benachrichtigung,
    ProofOfUseRequest,
    CreateDarlehenType,
    DarlehensdatenPerson,
} from '../@types/data/Darlehensdaten';
import STORAGE from '../enum/Storage';
import EP0 from '../mocks/ep0-response.json';
import EP1 from '../mocks/ep1-response.json';
import EP5 from '../mocks/ep5-response.json';
import EP6 from '../mocks/ep6-response.json';
import EP8 from '../mocks/ep8-response.json';
import EP9 from '../mocks/ep9-response.json';
import EP10 from '../mocks/ep10-response.json';
import EP12 from '../mocks/ep12-response.json';
import EP15 from '../mocks/ep15-response.json';
import EP18 from '../mocks/ep18-response.json';
import EP21 from '../mocks/ep21-response.json';
import EP24 from '../mocks/ep24-response.json';
import EP27 from '../mocks/ep27-response.json';
import EP29 from '../mocks/ep29-response.json';
import {Logger} from '../services/Logger';
import useInitialState from './useInitialState';
import useStore from './useStore';
import ROUTES from '../enum/Routes';
import BvMessages, {BvMessagesEnum} from '../enum/BvMessages';
import Messages, {MessagesEnum} from '../enum/Messages';

const {REACT_APP_TOKENHANDLER_DIK, REACT_APP_DEMO_MODE} = process.env;

const useApiService = (additionalConfig?: AxiosRequestConfig, abortController?: AbortController): ApiServiceResponse => {
    const [isRequesting, setIsRequesting] = useState(false);
    const {updateStore} = useStore();
    const initialState = useInitialState();
    const navigate = useNavigate();

    const demoMode = REACT_APP_DEMO_MODE === 'true';

    const api = useMemo<AxiosInstance>(() => axios.create({
        baseURL: REACT_APP_TOKENHANDLER_DIK,
        withCredentials: true,
        xsrfCookieName: STORAGE.CSRF,
        validateStatus: (status: number) => status >= 200 && status < 400,
        headers: {'Content-Type': 'application/json'},
        signal: abortController?.signal,
        ...additionalConfig,
    }), [additionalConfig]);

    const handleRejection = (error: any): AxiosResponse | Promise<any> => {
        setIsRequesting(false);

        if (error?.code === 'ERR_CANCELED') return Promise.reject({message: 'ERR_CANCELED'});
        if (!error.response) return Promise.reject();

        const response: AxiosResponse = error.response;
        Logger.debug('[API Interceptor] Error', response);

        const id = getErrorIdFromResponse(response?.data ?? response);
        const message = id !== undefined ? Messages[id as keyof MessagesEnum] : 'Unerwarteter Fehler';

        if (response?.status === 413) {
            return Promise.reject({message: 'Die Datei ist zu groß'});
        }

        if (['M5', 'M7', 'M8', 'M9', 'M10', 'M12'].includes(id)) {
            return Promise.reject({message});
        }

        const {
            tanValidierungsergebnis,
            tanValidierungsergebnisBeschreibung,
        } = response.data;

        if (response.status === 400 && tanValidierungsergebnis) {
            let message = BvMessages[tanValidierungsergebnis as keyof BvMessagesEnum];
            if (!message) {
                message = tanValidierungsergebnisBeschreibung;
            }

            if (tanValidierungsergebnis === 'HTAN_TAN_FALSCH') {
                message = BvMessages[tanValidierungsergebnis as keyof BvMessagesEnum];
            }

            return Promise.reject({message});
        }

        if ([500, 400].includes(response.status)) {
            navigate(ROUTES.ERROR, {state: {message}});
        }

        if ([403, 401].includes(response.status)) {
            navigate(ROUTES.LOGOUT);
        }

        return Promise.reject(response);
    };

    useEffect(() => {
        api.interceptors.response.use(
            (response) => {
                setIsRequesting(false);
                Logger.debug('[API Interceptor] Response', response);

                return Promise.resolve(response);
            },
            handleRejection,
        );
    }, [api]);

    const getErrorIdFromResponse = (response: any) => {
        if (response?.id) return response.id;
        if (response?.messages?.id) return response.messages.id;
        if (response?.messages?.length > 1) return response.messages[0].id;
    };

    const getError = (error: AxiosError): string | Record<string, any> => {
        if (error?.message === 'ERR_CANCELED') return '';

        return error?.response?.data || error?.request?.data || error?.message || 'Unerwarteter Fehler';
    };

    /* EP0 */
    const getDarlehensnehmer = (): DarlehensnehmerResponse => {
        Logger.info('[getDarlehensnehmer]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    setIsRequesting(false);
                    Logger.debug('[getDarlehensnehmer] data', EP0);
                    resolve(EP0);
                }, 1000);
                return;
            }

            api.get('/darlehen')
                .then((response) => {
                    Logger.debug('[getKundendaten] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[getKundendaten] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP1 */
    const getDarlehensdaten = (darlehenId: string): DarlehensdatenResponse => {
        Logger.info('[getDarlehensdaten]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(async () => {
                    await updateStore({
                        darlehensdaten: {
                            darlehensnehmer: EP1.darlehensnehmer[0],
                            darlehen: [EP1] || initialState.darlehensdaten.darlehen,
                        },
                    });

                    Logger.info('[useApiService] loadedDarlehensdaten', EP1);

                    setIsRequesting(false);
                    resolve(EP1);
                }, 1000);

                return;
            }

            api.get(`/darlehen/${darlehenId}`)
                .then(async (response) => {
                    Logger.debug('[getDarlehensdaten] Response', response.data);

                    await updateStore({
                        darlehensdaten: {
                            darlehensnehmer: response.data.darlehensnehmer[0],
                            darlehen: [response.data],
                        },
                    });

                    setIsRequesting(false);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[getDarlehensdaten] Error', getError(error));
                    setIsRequesting(false);
                    reject(getError(error));
                });
        });
    };

    /* EP2 */
    const requestNectLegitimation = (darlehenId: string): NectResponse => {
        Logger.info('[requestNectLegitimation]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] validateIban', EP5);

                    resolve({URL: 'https://test.jump.nect.app/?case_uuid=13bba6b8-edc0-47e6-9739-bd58170ff625'});
                    setIsRequesting(false);
                }, 1000);

                return;
            }

            const {userAgent} = navigator;
            const isMobileDevice = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent) || /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent);

            api.post(`/darlehen/${darlehenId}/legitimation`, {
                appRedirect: isMobileDevice
            })
                .then((response) => {
                    Logger.debug('[requestNectLegitimation] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[requestNectLegitimation] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP5 */
    const validateIban = (iban: string): Promise<IbanValidierungsResponse> => {
        Logger.info('[validateIban]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] validateIban', EP5);

                    setIsRequesting(false);
                    resolve(EP5 as IbanValidierungsResponse);
                }, 1000);

                return;
            }

            api.post(`/iban/details`, {iban})
                .then((response) => {
                    Logger.debug('[validateIban] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[validateIban] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP6 */
    const submitAuszahlungsauftrag = async (
        darlehenId: string,
        payload: CreateDarlehenType,
    ): Promise<SubmitAuszahlungsauftragResponse> => {
        Logger.info('[submitAuszahlungsauftrag]');

        return new Promise<SubmitAuszahlungsauftragResponse>((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] submitAuszahlungsauftrag', EP6);

                    setIsRequesting(false);
                    resolve(EP6 as SubmitAuszahlungsauftragResponse);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/auszahlungsauftrag`, payload)
                .then((response) => {
                    Logger.debug('[submitAuszahlungsauftrag] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[submitAuszahlungsauftrag] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP8 */
    const generateTan = async (darlehenId: string, auftragsId: string) => {
        Logger.info('[generateTan]');

        return new Promise<boolean>((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] generateTan', EP8);

                    setIsRequesting(false);
                    resolve(true);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/auszahlungsauftrag/${auftragsId}/tan/generate`)
                .then((response) => {
                    Logger.debug('[generateTan] Response', response.data);
                    resolve(true);
                })
                .catch((error) => {
                    Logger.error('[generateTan] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP9 */
    const authorizeTanForAuszahlung = (
        darlehenId: string,
        auftragsId: string,
        tan: string,
    ) => {
        Logger.info('[authorizeTanForAuszahlung]');

        return new Promise<AuthenticateTanForAuszahlungResponse>((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] authorizeTanForAuszahlung', EP9);

                    setIsRequesting(false);
                    resolve(EP9 as AuthenticateTanForAuszahlungResponse);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/auszahlungsauftrag/${auftragsId}/tan/authorize`, {tan})
                .then((response) => {
                    Logger.debug('[authorizeTanForAuszahlung] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[authorizeTanForAuszahlung] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP10 + EP14 */
    const uploadFiles = (
        files: Array<File | Dokument>,
        darlehenId: string,
        categoryId: string,
    ): UploadFileResponse => {
        Logger.info('[uploadFiles]');

        return new Promise((resolve, reject) => {
            // EP 14
            let requestUrl = `/darlehen/${darlehenId}/dokument`;
            if (categoryId) {
                // EP10
                requestUrl = `/darlehen/${darlehenId}/auszahlungsvorraussetzung/${categoryId}/dokument`;
            }

            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    setIsRequesting(false);
                    resolve(EP10);
                }, 1000 + Math.random() * 1000);
                return;
            }

            const formData: FormData = new FormData();
            files.forEach((file: File | Dokument) => {
                formData.append('file[]', file as Blob);
            });

            api.post(requestUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    Logger.debug('[uploadFiles] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[uploadFiles] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP12 */
    const fetchOcrByDocument = useCallback((darlehenId: string, documentId: string) => {
        Logger.info('[fetchOcrByDocument]');

        return new Promise<FetchOcrByDocumentResponse>((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] fetchOcrByDocument', EP12);

                    setIsRequesting(false);
                    resolve(EP12 as unknown as FetchOcrByDocumentResponse);
                }, 3000);

                return;
            }

            api.get(`/darlehen/${darlehenId}/dokument/${documentId}/ocr`)
                .then((response) => {
                    Logger.debug('[fetchOcrByDocument] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[fetchOcrByDocument] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    }, [api]);

    /* EP13 */
    const getDokument = (
        darlehenId: string,
        dokumentId: string,
    ): DokumentResponse => {
        Logger.info('[getDokument]');

        return new Promise((resolve, reject) => {
            if (demoMode) {
                resolve(new Blob());
            }

            setIsRequesting(true);

            api.get(`/darlehen/${darlehenId}/dokument/${dokumentId}`, {responseType: 'blob'})
                .then((response) => {
                    Logger.debug('[getDokument] Response', response.data);
                    resolve(new Blob([response.data], {type: 'application/pdf'}));
                })
                .catch((error) => {
                    Logger.error('[getDokument] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP15 */
    const submitProofOfUse = (
        darlehenId: string,
        gewerke: Array<ProofOfUseRequest>,
    ): SubmitProofOfUseResponse => {
        Logger.info('[submitProofOfUse]', darlehenId, gewerke);

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(async () => {
                    Logger.debug('[useApiService] submitProofOfUse', EP15);

                    setIsRequesting(false);
                    resolve(EP15);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/bautenstandSpeichern`, {gewerke})
                .then((response) => {
                    Logger.debug('[submitProofOfUse] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[submitProofOfUse] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP18 */
    const uploadFileToOcr = useCallback((
        darlehenId: string,
        files: Array<File>,
    ): Promise<UploadFileToOcrResponse> => {
        Logger.info('[uploadFileToOcr]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] uploadFileToOcr', EP18);

                    setIsRequesting(false);
                    resolve(EP18 as UploadFileToOcrResponse);
                }, 1000);

                return;
            }

            const formData: FormData = new FormData();
            files.forEach((file: File | Dokument) => {
                formData.append('file[]', file as Blob);
            });

            api.post(`/darlehen/${darlehenId}/dokument/ocr`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    Logger.debug('[uploadFileToOcr] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[uploadFileToOcr] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    }, [api]);

    /* EP13 */
    const getZahlungsDokument = (
        darlehenId: string,
        zahlungId: string,
    ): DokumentResponse => {
        Logger.info('[getZahlungsDokument]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                resolve(new Blob());
            }

            api.get(`/darlehen/${darlehenId}/zahlung/${zahlungId}`, {responseType: 'blob'})
                .then((response) => {
                    Logger.debug('[getZahlungsDokument] Response', response.data);
                    resolve(new Blob([response.data], {type: 'application/pdf'}));
                })
                .catch((error) => {
                    Logger.error('[getZahlungsDokument] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP20 */
    const updateDokumente = (
        darlehenId: string,
        dokumente: Array<Dokument>,
    ): UpdateDokumentResponse => {
        Logger.info('[updateDokumente]', darlehenId);

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(async () => {
                    const responseMock = {
                        rechnungssumme: 1200,
                        dokumente,
                    };

                    Logger.debug('[useApiService] updateDokumente', responseMock);

                    setIsRequesting(false);
                    resolve(responseMock);
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/dokument/korrektur`, {dokumente})
                .then((response) => {
                    Logger.debug('[updateDokumente] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[updateDokumente] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP21 */
    const setStatusGelesen = (
        darlehenId: string,
        ids: Array<string>,
    ): BenachrichtigungResponse => {
        Logger.info('[setStatusGelesen]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] setStatusGelesen', EP21);

                    setIsRequesting(false);
                    resolve(EP21 as {benachrichtigungen: Array<Benachrichtigung>});
                }, 1000);

                return;
            }

            api.post(`/darlehen/${darlehenId}/benachrichtigung/statusgelesen`, {ids})
                .then((response) => {
                    Logger.debug('[setStatusGelesen] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[setStatusGelesen] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP24 */
    const getBenachrichtigungen = (
        darlehenId: string,
    ): BenachrichtigungResponse => {
        Logger.info('[getBenachrichtigungen]');

        return new Promise((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] getBenachrichtigungen', EP24);

                    setIsRequesting(false);
                    resolve(EP24 as {benachrichtigungen: Array<Benachrichtigung>});
                }, 1000);

                return;
            }

            api.get(`/darlehen/${darlehenId}/benachrichtigungen`)
                .then((response) => {
                    Logger.debug('[getBenachrichtigungen] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[getBenachrichtigungen] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP27 */
    const setAgbAkzeptiert = () => {
        Logger.info('[setAgbAkzeptiert]');

        return new Promise<DarlehensdatenPerson>((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] setAgbAkzeptiert', EP27);

                    setIsRequesting(false);
                    resolve(EP27 as DarlehensdatenPerson);
                }, 1000);

                return;
            }

            api.post(`/darlehensnehmer/agbakzeptiert`)
                .then((response) => {
                    Logger.debug('[setAgbAkzeptiert] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[setAgbAkzeptiert] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    /* EP29 */
    const setLegiErgebnisGelesen = () => {
        Logger.info('[setLegiErgebnisGelesen]');

        return new Promise<DarlehensdatenPerson>((resolve, reject) => {
            setIsRequesting(true);

            if (demoMode) {
                setTimeout(() => {
                    Logger.debug('[useApiService] setLegiErgebnisGelesen', EP29);

                    setIsRequesting(false);
                    resolve(EP29 as DarlehensdatenPerson);
                }, 1000);

                return;
            }

            api.post(`/darlehensnehmer/legiErgebnisGelesen`)
                .then((response) => {
                    Logger.debug('[setLegiErgebnisGelesen] Response', response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    Logger.error('[setLegiErgebnisGelesen] Error', getError(error));
                    reject(getError(error));
                })
                .finally(() => setIsRequesting(false));
        });
    };

    return {
        isRequesting,
        getDarlehensnehmer,
        getDarlehensdaten,
        requestNectLegitimation,
        getDokument,
        updateDokumente,
        submitProofOfUse,
        uploadFiles,
        getBenachrichtigungen,
        setStatusGelesen,
        validateIban,
        uploadFileToOcr,
        fetchOcrByDocument,
        submitAuszahlungsauftrag,
        generateTan,
        authorizeTanForAuszahlung,
        setAgbAkzeptiert,
        setLegiErgebnisGelesen,
        getZahlungsDokument,
    };
};

export default useApiService;
