import {
    Button,
    Datepicker,
    FileDropUpload,
    FormRow,
    Input,
    NumberInput,
} from '@crossbuilders/form-library/components';
import {addDays, addYears, format, isSunday, isWeekend} from 'date-fns';
import de from 'date-fns/locale/de';
import React, {forwardRef, LegacyRef, useEffect, useState} from 'react';
import {ReactDatePickerProps, registerLocale} from 'react-datepicker';
import {FormProvider} from 'react-hook-form';
import {UseFormReturn} from 'react-hook-form/dist/types';
import useBus from 'use-bus';

import {AuszahlungsauftragType} from '../@types/forms/Auszahlungsauftrag';
import {ReactComponent as IconUpload} from '../assets/images/icon-upload.svg';
import DraggableFileList from '../components/DraggableFileList/DraggableFileList';
import InternalLink from '../components/Routing/InternalLink';
import Events from '../enum/Events';
import ROUTES from '../enum/Routes';
import useFileQueue from '../hooks/useFileQueue';
import {isHoliday} from '../services/date';
import {transformToNumberFormat, tryConvertFloat} from '../services/numberConverterService';
import {DROPZONE_MIME_TYPES, FILE_UPLOAD_SIZE_HINT} from '../services/validatorService';

type AuszahlungsauftragPageProps = {
    darlehenId: string;
    formMethods: UseFormReturn<AuszahlungsauftragType>;
    onSubmit: (data: AuszahlungsauftragType) => void;
    payoutsPurpose?: string;
    payoutsFile?: File[];
    ocrLoading?: boolean;
    cancelOcr: VoidFunction;
    uploadFiles: (files: File[]) => void;
    maximalerAuszahlungsbetrag: number;
};

registerLocale('de', {...de, options: {...de.options, weekStartsOn: 1}});

export default function AuszahlungsauftragPage(props: AuszahlungsauftragPageProps) {
    const {
        formMethods,
        darlehenId,
        onSubmit,
        payoutsPurpose,
        payoutsFile,
        uploadFiles,
        maximalerAuszahlungsbetrag,
    } = props;
    const [withCustomPayoutDate, setWithCustomPayoutDate] = useState(false);

    const {
        control,
        handleSubmit,
        setValue,
        formState: {isSubmitting, isValid, errors},
    } = formMethods;

    const {fileQueue, setFileQueue, handleOnDrop, hasFileSizeError} = useFileQueue();
    const [maximumAmountExceeded, setMaximumAmountExceeded] = useState<boolean>(false);

    const filterDate = (date: Date) => !isWeekend(date) && !isSunday(date) && !isHoliday(date);
    const getMinValidDate = (date: Date): Date => {
        const tomorrow = addDays(date, 1);
        if (!filterDate(tomorrow)) {
            return getMinValidDate(tomorrow);
        }

        return tomorrow;
    };

    const minDate: Date = getMinValidDate(new Date());
    const maxDate: Date = addYears(minDate, 1);

    const CustomDateInput = forwardRef((props: any, ref) => (
        <button
            ref={ref as LegacyRef<HTMLButtonElement>}
            onClick={props.onClick}
            className="button"
            type="button"
        >
            {props?.value ?? 'Datum auswählen'}
        </button>
    ));

    const datePickerOptions: Partial<ReactDatePickerProps> = {
        minDate,
        maxDate,
        filterDate,
        customInput: <CustomDateInput />,
        dateFormat: 'dd.M.yyyy',
    };

    const submitHandler = (data: AuszahlungsauftragType) => {
        onSubmit({
            ...data,
            date: withCustomPayoutDate ? format(data.date as Date, 'yyyy-MM-dd') : '',
        });
    };

    useBus(
        Events.OCR_DONE,
        () => {
            setFileQueue({});
        },
        [fileQueue],
    );

    useEffect(() => {
        if (withCustomPayoutDate) {
            setValue('date', format(getMinValidDate(new Date()), 'yyyy-MM-dd'));
        }
    }, [withCustomPayoutDate]);

    return (
        <>
            <InternalLink to={`${ROUTES.DASHBOARD}/${darlehenId}`} className="link link--with-chevron-reverse">
                Zur Übersicht
            </InternalLink>

            <div className="content content--520-center">
                <h1 className="headline page-content__headline">Auszahlung beauftragen</h1>

                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(submitHandler)} autoComplete="off">
                        <section className="form-section">
                            <FormRow className="column">
                                <Input
                                    type="text"
                                    id="payouts_recipient"
                                    name="payouts_recipient"
                                    placeholder="Empfängername"
                                    maxLength="27"
                                    control={control}
                                    errors={errors}
                                >
                                    Zahlungsempfänger
                                </Input>
                                <Input
                                    type="text"
                                    id="payouts_iban"
                                    name="payouts_iban"
                                    placeholder="IBAN"
                                    errors={errors}
                                    control={control}
                                >
                                    IBAN
                                </Input>

                                <Input
                                    type="text"
                                    id="payouts_financial_institution"
                                    name="payouts_financial_institution"
                                    placeholder="Wird automatisch eingetragen"
                                    control={control}
                                    errors={errors}
                                    readOnly
                                >
                                    Kreditinstitut
                                </Input>

                                <Input
                                    type="text"
                                    id="payouts_bic"
                                    name="payouts_bic"
                                    placeholder="Wird automatisch eingetragen"
                                    control={control}
                                    errors={errors}
                                    readOnly
                                >
                                    BIC
                                </Input>

                                <Input
                                    rowClass="form-row form-row--with-textarea form-row--auto-lines-height"
                                    labelClass="form-label form-label--flex-row"
                                    id="payouts_purpose"
                                    name="payouts_purpose"
                                    maxLength="50"
                                    control={control}
                                    errors={errors}
                                >
                                    Verwendungszweck (optional)
                                    <span className="form-row--info">
                                        {payoutsPurpose ? payoutsPurpose.length : 0} / 50 Zeichen
                                    </span>
                                </Input>

                                <NumberInput
                                    id="payouts_max_amount"
                                    name="payouts_max_amount"
                                    control={control}
                                    unitLabel="€"
                                    inputClass="form-element form-element--align-right"
                                    value={0}
                                    errors={errors}
                                    decimalScale={2}
                                    returnFormat="formatted"
                                    onBlur={(event: Event) => {
                                        const target: HTMLInputElement = event.target as unknown as HTMLInputElement;

                                        if (tryConvertFloat(target.value) > maximalerAuszahlungsbetrag) {
                                            setValue('payouts_max_amount', transformToNumberFormat({
                                                number: maximalerAuszahlungsbetrag,
                                                digits: 2
                                            }) ?? '');

                                            setMaximumAmountExceeded(true);
                                        } else {
                                            setMaximumAmountExceeded(false);
                                        }
                                    }}
                                    warning={maximumAmountExceeded ?  `Der maximale Auszahlungsbetrag beträgt: ${transformToNumberFormat(
                                        {number: maximalerAuszahlungsbetrag, digits: 2},
                                    )} €` : null}
                                >
                                    Gesamtbetrag
                                </NumberInput>

                                <FormRow>
                                    <div className="form-label">Termin der Ausführung</div>
                                    <div className="form-checkbox form-checkbox--with-pointer">
                                        <input
                                            type="checkbox"
                                            checked={!withCustomPayoutDate}
                                            onChange={() => setWithCustomPayoutDate(!withCustomPayoutDate)}
                                            id="withCustomPayoutDate"
                                        />
                                        <label htmlFor="withCustomPayoutDate">
                                            <span className="form-label">sofort</span>
                                        </label>
                                    </div>
                                </FormRow>

                                {withCustomPayoutDate && (
                                    <Datepicker
                                        id="date"
                                        name="date"
                                        locale="de"
                                        placeholder="TT.MM.JJJJ"
                                        outputFormat="yyyy-MM-dd"
                                        inputClass="form-element form-element--align-right"
                                        datepickerOptions={datePickerOptions}
                                        control={control}
                                        errors={errors}
                                    >
                                        Auszahlungsdatum
                                    </Datepicker>
                                )}
                            </FormRow>
                        </section>

                        <section className="form-section">
                            <h2 className="form-section__title">
                                Rechnung importieren und Zahlungsdaten auslesen lassen
                            </h2>

                            <FileDropUpload
                                onDrop={handleOnDrop}
                                name="payouts_file"
                                accept={DROPZONE_MIME_TYPES}
                                className={payoutsFile ? 'hide' : ''}
                                errors={errors}
                                disabled={hasFileSizeError}
                                multiple
                                noButton
                            >
                                <IconUpload width={20} height={20} />
                                <span className="file-drop-upload__label">Dokument hierher ziehen oder</span>
                                <div className="file-drop-upload__button">Datei auswählen</div>

                                <div className="file-drop-upload__reject-warning">
                                    <span>Die von Ihnen ausgewählte Datei kann nicht hochgeladen werden. Bitte laden Sie eine PDF, PNG oder JPEG-Datei hoch.</span>
                                </div>
                            </FileDropUpload>

                            <div className={`flex flex--col document-upload-form__hint form-hint ${
                                hasFileSizeError ? 'form-hint--error' : ''
                            }`}>
                                <b>Hinweis:</b>
                                <span>{FILE_UPLOAD_SIZE_HINT}</span>
                            </div>

                            {Object.keys(fileQueue).length > 0 ? (
                                <div className="document-upload-form__queue">
                                    <DraggableFileList fileQueue={fileQueue} setFileQueue={setFileQueue} />
                                    <button
                                        type="button"
                                        className="button button--ghost button--small"
                                        onClick={() => uploadFiles(Object.values(fileQueue))}
                                        disabled={hasFileSizeError}
                                    >
                                        Dateien auslesen
                                    </button>
                                </div>
                            ) : null}

                            {payoutsFile && (
                                <h3>
                                    Verarbeitete Datei:
                                    <span className="text--success">{payoutsFile[0].name}</span>
                                </h3>
                            )}
                        </section>

                        <Button
                            type="submit"
                            disabled={isSubmitting || !isValid}
                            className="button button--primary button--align-right"
                            onClick={() => true}
                        >
                            Auszahlung beauftragen
                        </Button>
                    </form>
                </FormProvider>
            </div>
        </>
    );
}
