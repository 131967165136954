export default function ensureBasename(basename: string | undefined) {
    if (!basename) {
        return;
    }

    if (!window.location.pathname.includes(basename)) {
        window.history.replaceState(
            '',
            '',
            basename + window.location.pathname
        );
    }
}
