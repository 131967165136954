import {Button} from '@crossbuilders/form-library/components';
import React from 'react';
import {ReactComponent as IconCheck} from '../../../assets/images/check-regular.svg';
import {ReactComponent as IconEdit} from '../../../assets/images/icon-edit.svg';
import LoadingWheel from '../../Layout/LoadingWheel';

interface EditButtonProps {
    isLoading: boolean;
    isEditingMode: boolean;
    onSubmit: () => void;
    onEdit: () => void;
}

export default function EditButton(props: EditButtonProps) {
    const {
        isLoading,
        isEditingMode,
        onSubmit,
        onEdit,
    } = props;

    const IconSubmit = () => isLoading ? (
        <LoadingWheel isLoading color="white" />
    ) : (
        <IconCheck width="20" height="20" fill="#fff" />
    );

    return <div className="tabs-content__actions">
        {isEditingMode ? (
            <Button onClick={onSubmit} type="button" className="button button--small button--primary">
                <IconSubmit />
                <span style={{marginLeft: '10px'}}>Änderungen speichern</span>
            </Button>
        ) : (
            <Button onClick={onEdit} type="button" className="button button--small button--ghost">
                <IconEdit width="20" height="20" fill="#f84914" />
                <span style={{marginLeft: '10px'}}>Rechnungsdaten bearbeiten</span>
            </Button>
        )}
    </div>;
};
