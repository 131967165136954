import React, {useMemo} from 'react';
import {dispatch} from 'use-bus';
import FileUploadForm from '../../Forms/FileUploadForm';

import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import {useModal} from '../Modal';
import Events from '../../../enum/Events';
import {Logger} from '../../../services/Logger';
import {Dokument, FileQueue} from '../../../@types/data/Darlehensdaten';

const UploadProofOfUse = () => {
    const {setModalOpen, data} = useModal();

    const existingFiles = useMemo<Array<Dokument | File>>(() => {
        const hasUploadedDocuments = data?.dokumente.some(
            (document: Dokument) => document.hasOwnProperty('hochgeladenAm'),
        );

        if (data?.dokumente && !hasUploadedDocuments) {
            return data.dokumente;
        }

        return [];
    }, [data.dokumente]);

    return (
        <>
            <ModalHeader />

            <div className="modal__content">
                <h2 className="headline headline--level-2">Bilder hochladen</h2>

                <p className="text">
                    Sie können bis zu 5 Bilder je Gewerk hochladen. Sie müssen mindestens ein Bild je Gewerk hochladen,
                    sofern Sie den Status anpassen.
                </p>

                <FileUploadForm
                    maxFileCount={5}
                    existingFiles={existingFiles}
                    exposeFileQueue={(fileQueue: FileQueue) => {
                        Logger.debug('[UploadProofOfUse] Modal::ChangedFiles', fileQueue);

                        dispatch({
                            type: Events.FILES_LIST_CHANGED,
                            rowId: data.id,
                            fileQueue,
                        });
                    }}
                />
            </div>

            <ModalFooter
                buttons={[{
                    type: 'upload',
                    onClick() {
                        dispatch(Events.SUBMIT_FILE_UPLOAD);
                        setModalOpen(false);
                    },
                }]}
            />
        </>
    );
};

export default UploadProofOfUse;
