import React from "react";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import { useModal } from "../Modal";

const RemoveTableRow = () => {
	const { data } = useModal();

	return (
		<>
			<ModalHeader />

			<div className="modal__content">
				<h2>Möchten Sie die Reihen löschen?</h2>
				<p>{JSON.stringify(data)}</p>
			</div>

			<ModalFooter buttons={[{ type: "abort" }, { type: "confirm" }]} spread />
		</>
	);
};

export default RemoveTableRow;
