import React, {useEffect} from 'react';
import {Logger} from '../services/Logger';
import useStore from '../hooks/useStore';
import {useNavigate} from 'react-router-dom';
import ROUTES from '../enum/Routes';
import useApiService from '../hooks/useApiService';
import LoadingContainer from '../containers/LoadingContainer';

export default function AgbPage() {
    const {isAgbAccepted, darlehensnehmer} = useStore();
    const {setAgbAkzeptiert, isRequesting} = useApiService();
    const navigate = useNavigate();

    useEffect(() => {
        Logger.info('[AgbPage]', darlehensnehmer);
    }, [Logger]);

    useEffect(() => {
        if (!darlehensnehmer || isAgbAccepted) {
            navigate(ROUTES.DASHBOARD, {state: {preventRedirect: true}});
            return;
        }
    }, [darlehensnehmer, isAgbAccepted]);

    const acceptAgb = async () => {
        await setAgbAkzeptiert();

        navigate(ROUTES.DASHBOARD, {state: {preventRedirect: true}});
    };

    if (isRequesting) return (
        <LoadingContainer
            isLoading={true}
            className="loading-container--standalone"
            contentClassName="loading-container__content--text-only"
        />
    );

    return (
        <div>
            <p>
                Herzlich Willkommen im Auszahlungsportal der Wüstenrot Bausparkasse!
            </p>
            <p>
                Hier können Sie schnell und einfach Auszahlungen aus Ihrer Baufinanzierung beauftragen und die
                dazu erforderlichen Dokumente digital hochladen.
            </p>
            <p>
                Zudem haben Sie eine Übersicht zu den bisher getätigten Auszahlungen und können die
                eingereichten Dokumente einsehen sowie downloaden.
            </p>
            <p>
                Zu Ihrer eigenen Sicherheit müssen Sie sich vor der ersten Auszahlungen einmalig identifizieren.
                Die Identifizierung erfolgt durch unseren Partner Nect. Halten Sie hierzu bitte Ihr
                Ausweisdokument und Ihr Smartphone bereit. Bitte beachten Sie, dass Sie die Nutzungsbedingungen
                von Nect einmalig vor der Identifizierung akzeptieren müssen.
            </p>
            <p>
                In unseren FAQ finden Sie die Antworten auf die häufig gestellten Fragen.
            </p>
            <p>
                Vielen Dank!
            </p>

            <button className="button button--primary" onClick={acceptAgb}>Weiter</button>
        </div>
    );
}
